import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DepartmentResponse } from '../models/department';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  baseUrl: string

  constructor(private http: HttpClient) {
  }

  getAll(userId): Promise<DepartmentResponse> {
    let url = "api/Department?userId=" + userId;
    return new Promise<DepartmentResponse>(resolve => {
      if (this.hasCache()) {
        resolve(this.getCache())
      } else {
        this.http.get<DepartmentResponse>(url).subscribe(response => {
          this.setCache(response);
          resolve(response);
        });
      }

    })


  }
  hasCache(): boolean {
   // var hasCache = localStorage.getItem('departmentResponse') != null || localStorage.getItem('departmentResponse') != undefined;
    //return hasCache;
    return false;
  }
  getCache(): DepartmentResponse {
    var problemTypesResponse = <DepartmentResponse>Object.assign(new DepartmentResponse, JSON.parse(localStorage.getItem('departmentResponse')));
    return problemTypesResponse;
  }
  setCache(problemTypesResponse: DepartmentResponse): void {
    localStorage.setItem('departmentResponse', JSON.stringify(problemTypesResponse));
  }
}
