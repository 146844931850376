import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
@Component({
  selector: 'app-inner-map',
  templateUrl: './inner-map.component.html',
  styleUrls: ['./inner-map.component.scss']
})
export class InnerMapComponent implements OnInit {

  mapId: number = 3584;
  appKey: string = "5698ed6e51754a7f9e1c39ec90a1ce24"
  control: any;
  lastLayer: any = null;
  lastColor: any = null;
  floorId:any;
  featureId:any;

  @Input() location:any;

  constructor(private route: ActivatedRoute, private preLoader: MDBSpinningPreloader) {
   }
  
  async ngOnInit() {
    this.preLoader.stop();
    this.featureId = this.route.snapshot.paramMap.get('featureId');
    this.floorId = this.route.snapshot.paramMap.get('floorId');

    window.map = new window.NGR.View('map', {
      appKey: this.appKey,
    });
    window.res = new window.NGR.DataSource({
      appKey: this.appKey,
    });

    window.layers = {};
    window.lastLayer = null;
    this.control = window.NGR.control.floor();
    await this.buildMap();

  }


  private buildMap() {
    return new Promise(resolve => {
      var control = this.control;
      var loadPlan = this.loadPlanarGraph;
      var floorId = this.floorId
      var featureId = this.featureId;

      window.map._core_map.dragging.disable();
      window.map._core_map.doubleClickZoom.disable();
      window.map._core_map.touchZoom.disable();
      window.map._core_map.scrollWheelZoom.disable();

      window.res.requestMap(this.mapId).then(function (mapInfo: any) {
        window.res.requestPOIChildren(mapInfo.poi).then(function (floors: any) {

          window.map.addControl(control);

          window.floors = floors;
          control.setFloorList(floors.reverse());
          control.setCurrentFloor(floorId, loadPlan);
          loadPlan(floorId, featureId);
        }).fail(onerror);
      }).fail(onerror);

      resolve();
    })
  }
  private loadPlanarGraph(planarGraphId: any, _featureId: any): void {
    var res = window.res;
    var NGR = window.NGR;
    var layers = window.layers;
    var map = window.map;
    var featureId = _featureId;

    res.requestPlanarGraph(planarGraphId).then(function (layerInfo: any) {
      NGR.IO.fetch({
        url: './assets/map.style.json',
        onsuccess: JSON.parse
      }).then(function (style: any) {
        window.map.clear();

        window.layers.frame = NGR.featureLayer(layerInfo, {
          layerType: 'Frame',
          styleConfig: style
        });
        layers.area = window.NGR.featureLayer(layerInfo, {
          layerType: 'Area',
          styleConfig: style
        });
        layers.annotation = NGR.featureLayer(layerInfo.Area, {
          layerType: 'LogoLabel',
          styleConfig: style
        });
        layers.facility = NGR.featureLayer(layerInfo.Facility, {
          layerType: 'Facility',
          styleConfig: style
        });

        layers.collision = NGR.layerGroup.collision({
          margin: 3
        });
        layers.collision.addLayer(layers.annotation);
        layers.collision.addLayer(layers.facility);

        map.addLayer(layers.frame);
        map.addLayer(layers.area);
        map.addLayer(layers.collision);

        map.render();
       

        setTimeout(() => {
          var feature = window.layers.area._featureLayers[featureId];
          feature.setStyle({ fillColor: '#ff0000' });
          window.map.panTo(feature._latlngs[0]);
          window.map.setZoom(19);
        }, (2000));

     
      })
    })
  }

}
