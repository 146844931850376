import { Component, OnInit, EventEmitter, ViewChild, HostListener, } from '@angular/core';
import { IMyOptions, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { ProblemLogService } from 'src/app/services/problem-log.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadFile, UploadInput, UploadOutput, ToastService } from 'ng-uikit-pro-standard';
import { ActivatedRoute, Router } from '@angular/router';

import { StaffService } from 'src/app/services/staff.service';
import { AssignTaskService } from 'src/app/services/assign-task.service';
import { AuthService } from 'src/app/services/auth-service.service';
import { CancelTaskService } from 'src/app/services/cancel-task.service';
import { PickTaskService } from 'src/app/services/pick-Task.service';

import { CompleteTaskService } from 'src/app/services/complete-task.service';
import { LocalDateService } from 'src/app/services/local-date.service';


import { WeChatDepartmentUser } from 'src/app/models/wechatDepartmentUser';
import { User } from 'src/app/models/User';
import { AssignTaskForm } from 'src/app/models/assignTaskForm';
import { CancelTaskForm } from 'src/app/models/cancelTaskForm';
import { PickTaskForm } from 'src/app/models/pickTaskForm';
import { ProblemLogDetail } from 'src/app/models/ProblemLogDetail';

import { ProblemPhoto } from 'src/app/models/problemPhoto';
import { CompleteTaskForm } from 'src/app/models/completeTaskForm';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-problem-log-detail',
  templateUrl: './problem-log-detail.component.html',
  styleUrls: ['./problem-log-detail.component.scss']
})
export class ProblemLogDetailComponent implements OnInit {
  public myDatePickerOptions: IMyOptions;
  files: UploadFile[] = [];
  problemLogDetail: ProblemLogDetail;
  staffs: WeChatDepartmentUser[];
  filteredStaffs: Observable<WeChatDepartmentUser[]>;
  currentUser: User;
  assignTaskFormGroup: FormGroup;
  cancelTaskFormGroup: FormGroup;
  pickTaskFormGroup: FormGroup;
  completeTaskFormGroup: FormGroup;
  completePhotos: ProblemPhoto[] = [];
  mapsrc: string = "/blank";
  @ViewChild('showMapModal') showMapModal: any;
  @ViewChild('assignModal') assignModal: any;
  @ViewChild('cancelModal') cancelModal: any;
  @ViewChild('pickModal') pickModal: any;
  @ViewChild('completeModal') completeModal: any
  uploadInput: EventEmitter<UploadInput>;
  toastOption: any = { positionClass: 'toast-center' };
  startDate: string;
  constructor(
    private problemLogService: ProblemLogService,
    private route: ActivatedRoute,
    private staffService: StaffService,
    private authService: AuthService,
    private fb: FormBuilder,
    private preLoader: MDBSpinningPreloader,
    private localDateService: LocalDateService,
    private assignTaskService: AssignTaskService,
    private pickTaskService: PickTaskService,
    private cancelTaskService: CancelTaskService,
    private completeTaskService: CompleteTaskService,
    private router: Router,
    private toastService: ToastService,
  ) {

    this.problemLogDetail = new ProblemLogDetail();
    var today = new Date();
    var month = today.getMonth() + 1 < 9 ? "0" + (today.getMonth() + 1).toString() : today.getMonth().toString();
    var day = today.getDate() < 9 ? "0" + today.getDate().toString() : today.getDate().toString();
    var year = today.getFullYear();
    this.startDate = day + "-" + month + "-" + year
    this.myDatePickerOptions = {
      dateFormat: "dd-mm-yyyy",
      startDate: day + "-" + month + "-" + year,
      disableUntil: { year: year, month: today.getMonth() + 1, day: today.getDate() - 1 },
      showClearDateBtn: false
    };


  }

  hoursLater(h:number):string {    
    var d = new Date();
   
    d.setTime(d.getTime() + (h*60*60*1000)); 
    var newDate = d.getHours() +":"+d.getMinutes(); 
    return newDate;   
  }

  async ngOnInit() {
    this.preLoader.start();
    window.scrollTo(0, 0);
    this.createFormGroups()
    const code = this.route.snapshot.queryParams['code'];
    var hasCode = code != null && code != undefined
    if (hasCode) {
      await this.authService.authenticate(code);
    }
    this.authService.currentUser.subscribe(async user => {
      this.currentUser = user;

        if ((user == null || !user.hasCorpMap || !user.isAllowedDepartment) && !hasCode) {
          this.router.navigateByUrl("unauthorized");
        }
        await this.getProblemLogDetail();
        this.preLoader.stop();
    })
  }
  createFormGroups() {
    this.completeTaskFormGroup = this.fb.group({
      remark: ['']
    });
    this.assignTaskFormGroup = this.fb.group({
      worker: ['', Validators.required],
      targetCompleteDate: ['', Validators.required],
      targetCompleteTime: [this.hoursLater(3), Validators.required],
    });
    this.cancelTaskFormGroup = this.fb.group({
      reason: ['']
    });
    this.pickTaskFormGroup = this.fb.group({

    });
  }
  getProblemLogDetail() {
     return new Promise(resolve => {
      const id = +this.route.snapshot.paramMap.get('id');
      this.problemLogService.GetDetailById(id).subscribe(response => {
        this.problemLogDetail = response;
        this.getStaffs();
        resolve("");
      });
    });

  }
  getLocalDate(date: string): string {
    return this.localDateService.ToLocalDateString(date);
  }
  private getStaffs() {
    this.staffService.getByDepartment(this.problemLogDetail.departmentId).subscribe(response => {
      this.staffs = response.departmentUsers;
    })
  }
  getProgressStyle(num: number) {
    return { width: num + '%' };
  }


  makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
  }

  onNewImage(newImg: Blob) {
    this.addImgUrl(newImg, this.makeid());
  }

  
  public addImgUrl(file: Blob, filename: string) {

    if (file == undefined)
      return;
    //var reader = new FileReader();
    //reader.readAsDataURL(file);
    //reader.onload = (_event) => {
      var completePhoto = new ProblemPhoto();
      //completePhoto.base64 = reader.result;
      completePhoto.base64 = file;
      completePhoto.name = filename;
      this.completePhotos.push(completePhoto);
    //}
  }

  public removeImagUrl(name: string) {
    this.completePhotos = this.completePhotos.filter((i: any) => i.name !== name);
  }

  showFiles() {
    //return `總共 ${this.files.length} 檔案`;
    return `总共 ${this.completePhotos.length} 档案`;
  }

  onUploadOutput(output: UploadOutput | any): void {

    if (output.type === 'allAddedToQueue') {
    } else if (output.type === 'addedToQueue') {
      this.files.push(output.file); // add file to array when added
      this.addImgUrl(output.file.nativeFile, output.file.name);
    } else if (output.type === 'uploading') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      //this.dragOver = true;
    } else if (output.type === 'dragOut') {
    } else if (output.type === 'drop') {
      // this.dragOver = false;
    }

  }

  showMapClicked() {
    this.mapsrc = this.problemLogDetail.mapIframeSrc;
    this.showMapModal.show();
  }
  //assignClicked() {
  //  this.assignTaskFormGroup.reset();
  //  this.assignTaskFormGroup.patchValue({ targetCompleteDate: this.startDate });
  //  this.assignTaskFormGroup.patchValue({ targetCompleteTime: this.hoursLater(3) });
  //  this.assignModal.show();
  //}
  async assignClicked() {
    this.filteredStaffs = new Observable<WeChatDepartmentUser[]>();
    this.assignTaskFormGroup.reset();
    this.assignTaskFormGroup.patchValue({ targetCompleteDate: this.startDate });
    this.assignTaskFormGroup.patchValue({ targetCompleteTime: this.hoursLater(3) });

    var workerCtrl = this.assignTaskFormGroup.get('worker');
    this.filteredStaffs = workerCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value == undefined ? '' : value.name),
        map(name => name ? this._filter(name) : this.staffs.slice())
      );
    this.assignModal.show();

  }
  displayFn(user?: User): string | undefined {
    return user ? user.name : undefined;
  }

  private _filter(name: string): WeChatDepartmentUser[] {
    const filterValue = name.toLowerCase();

    return this.staffs.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  cancelClicked() {
    this.cancelTaskFormGroup.reset();
    this.cancelModal.show();
  }
  pickClicked() {
    this.pickModal.show();
  }

  cancelSubmit() {

    var cancelTaskForm = new CancelTaskForm();

    cancelTaskForm.createdBy = this.currentUser.userId;
    cancelTaskForm.reason = this.cancelTaskFormGroup.value.reason;
    cancelTaskForm.problemLogId = this.problemLogDetail.problemLogId;

    this.cancelTaskService.Save(cancelTaskForm).subscribe(response => {
      if (response.errcode == 0) {
        this.problemLogService.GetDetailById(this.problemLogDetail.problemLogId).subscribe(pl => this.problemLogDetail = pl);
        this.toastService.success("储存成功", '', this.toastOption);
      }
      if (response.errcode > 0) {
        this.toastService.error("储存失败 " + response.errmsg, '', this.toastOption);
      }
    });
    this.cancelModal.hide();
  }

  pickSubmit() {
    var pickTaskForm = new PickTaskForm();

    pickTaskForm.createdBy = this.currentUser.userId;
    pickTaskForm.workerId = this.currentUser.userId;
    pickTaskForm.problemLogId = this.problemLogDetail.problemLogId;

    this.pickTaskService.Save(pickTaskForm).subscribe(response => {
      if (response.errcode == 0) {
        this.problemLogService.GetDetailById(this.problemLogDetail.problemLogId).subscribe(pl => this.problemLogDetail = pl);
        this.toastService.success("储存成功", '', this.toastOption);
      }
      if (response.errcode > 0) {
        this.toastService.error("储存失败 " + response.errmsg, '', this.toastOption);
      }
    });
    this.pickModal.hide();
  }
  assignSubmit() {
    if (!this.assignTaskFormGroup.valid)
      return;
    var assignTaskForm = new AssignTaskForm();

    assignTaskForm.createdBy = this.currentUser.userId;
    assignTaskForm.targetCompleteDate = this.assignTaskFormGroup.value.targetCompleteDate;
    assignTaskForm.targetCompleteTime = this.assignTaskFormGroup.value.targetCompleteTime;
    assignTaskForm.workerId = this.assignTaskFormGroup.value.worker.userid;
    assignTaskForm.problemLogId = this.problemLogDetail.problemLogId;

    this.assignTaskService.Save(assignTaskForm).subscribe(response => {
      if (response.errcode == 0) {
        this.problemLogService.GetDetailById(this.problemLogDetail.problemLogId).subscribe(pl => this.problemLogDetail = pl);
        this.toastService.success("储存成功", '', this.toastOption);
      }
      if (response.errcode > 0) {
        this.toastService.error("储存失败 " + response.errmsg, '', this.toastOption);
      }
    });
    this.assignModal.hide();
  }
  completeClicked() {
    this.completeTaskFormGroup.reset();
    this.completeModal.show();
  }

  completeSubmit() {
    var completeTaskForm = new CompleteTaskForm();

    completeTaskForm.createdBy = this.currentUser.userId;
    completeTaskForm.problemLogId = this.problemLogDetail.problemLogId;
    completeTaskForm.remark = this.completeTaskFormGroup.value.remark;
    completeTaskForm.completePhotos = this.completePhotos;

    this.completeTaskService.Save(completeTaskForm).subscribe(response => {
      if (response.errcode == 0) {
        this.problemLogService.GetDetailById(this.problemLogDetail.problemLogId).subscribe(pl => this.problemLogDetail = pl);
        this.toastService.success("储存成功", '', this.toastOption);
      }
      if (response.errcode > 0) {
        this.toastService.error("储存失败 " + response.errmsg, '', this.toastOption);
      }

    });

    this.completeModal.hide();
  }
  get ifarmHeight(): number {
    var minHehgit = 230;
    var height = window.innerWidth / 3;
    return height < minHehgit ? minHehgit : height;
  }
  currentUserCanCancel() {
    return this.currentUser.isWorkingGroupUser;
  }
  currentUserCanAssign() {
    return this.currentUser.isWorkingGroupUser;
  }

  touchStarting: boolean;
  touchStartY: number;
  touchMoveY: number;
  touchEnding: boolean;
  get showRefresherHeader() {
    var showRefresher = this.touchStarting && this.touchStartY < 500 && (this.touchMoveY - this.touchStartY > 100);

    return showRefresher
  }
  get refresherState() {
    if (this.showRefresherHeader && !this.touchEnding)
      return '松开立即刷新...';

    if (this.showRefresherHeader && this.touchEnding)
      return '刷新中...';
  }
  @HostListener('touchstart', ['$event'])
  touchStart(e) {
    this.touchStarting = true;
    this.touchStartY = e.touches[0].screenY;
  }
  @HostListener('touchmove', ['$event'])
  touchMove(e) {
    this.touchMoveY = e.touches[0].screenY;
  }
  @HostListener('touchend', ['$event'])
  touchEnd(e) {
    this.touchEnding = true;
    if (this.showRefresherHeader) {
      this.getProblemLogDetail()

      setTimeout(() => {
        this.touchStarting = false;
        this.touchEnding = false;
        this.touchStartY = 0;
        this.touchMoveY = 0;
      }, 2000)
    } else {
      this.touchStartY = 0;
      this.touchMoveY = 0;
    }

  }
}

