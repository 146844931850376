<div class="iphonex-height">
  <mat-vertical-stepper [linear]="isLinear" (next)="onNext" (selectionChange)="onNext($event)"  #stepper>
    <mat-step [stepControl]="stepOneForm">
      <form [formGroup]="stepOneForm">
        <ng-template matStepLabel>选择地点</ng-template>
        <div class="row">
          <div class="col-xs-3">
            <span class="item-title">店铺: </span>
          </div>
          <div class="col-xs-9">
            <span>{{featureName}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-3">
            <span class="item-title">楼层: </span>
          </div>
          <div class="col-xs-9">
            <span>{{floorName}}</span>
          </div>
        </div>


        <div style="margin-top:10px;">
          <mat-divider></mat-divider>
          <button mat-button matStepperPrevious style="color:mediumorchid" routerLink="/workOrderMap">返回地图</button>
          <button mat-button matStepperNext (click)="openDepartment()" style="color:lightseagreen"><strong>下一步</strong></button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="stepTwoForm">
      <form [formGroup]="stepTwoForm">
        <ng-template matStepLabel>任务资料</ng-template>

        <div class="row">
          <div class="col-xs-12">
            <mat-form-field>
              <mat-label>部门</mat-label>
              <mat-select placehorder="部门" formControlName="department" #departmentSelect required>
                <mat-option *ngFor="let d of departments" (click)="openProblemType()" [value]="d">
                  {{d.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <mat-form-field>
              <mat-label>类型</mat-label>
              <mat-select #problemTypeSelect placehorder="类型" formControlName="problemType" required>
                <mat-option *ngFor="let pt of problemTypes" (click)="selectedProblemType(pt);" [value]="pt">
                  {{pt.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sx-12">
            <mat-form-field>
              <mat-label>常见问题</mat-label>
              <mat-select #problemSelect matNativeControl placehorder="常见问题" formControlName="problem" required>
                <mat-option *ngFor="let p of problems" (click)="focusToProblemDescription()" [value]="p">
                  {{p.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sx-12">
            <mat-form-field>
              <mat-label>问题描述</mat-label>
              <textarea #problemDescriptionTextArea matInput placeholder="问题描述" formControlName="problemDescription"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="row">

          <div class="col-xs-12 mat-form-field-flex">
            <mat-button-toggle-group name="problemAreaGroup" #group="matButtonToggleGroup" formControlName="problemArea">
              <mat-button-toggle *ngFor="let pa of problemAreas" [value]="pa">
                <i [ngClass]="pa.iconCss">{{pa.name}}</i>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-xs-12 mat-form-field-flex">
            <span class="item-title">区域 :</span>
            <span style="font-weight:bolder;">{{problemArea?problemArea:'请选择'}}</span>
          </div>
        </div>
        <div style="margin-top:10px;">
          <mat-divider></mat-divider>
          <button mat-button matStepperPrevious style="color:mediumorchid">上一步</button>
          <button (click)="openFileUpload()" mat-button matStepperNext style="color:lightseagreen"><strong>下一步</strong></button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="stepThreeForm">
      <form [formGroup]="stepThreeForm">
        <ng-template matStepLabel>照片</ng-template>
        <div class="row">
          <div class="file-field col-sx-12">
            <add-image-dialog (addImage)="onNewImage($event)"></add-image-dialog>
            <div class="file-path-wrapper mt-3">
              <input class="file-path" type="text" placeholder="Upload your file" [value]="showFiles()">
            </div>
          </div>
        </div>
        <div class="row">
          <figure *ngFor="let photo of problemPhotos" style="vertical-align:top;text-align:center;display: inline-block;">
            <div class="imageContainer">
              <button class="x" (click)="removeImagUrl(photo.name)">
                X
              </button>
              <img [src]="photo.base64" width="200px" style="margin: 5px;" caption>
            </div>
          </figure>
        </div>

        <div style="margin-top:10px;">
          <mat-divider></mat-divider>
          <button mat-button matStepperPrevious style="color:mediumorchid">上一步</button>
          <button mat-button matStepperNext style="color:lightseagreen"><strong>保存</strong></button>
        </div>
      </form>
    </mat-step>
    <mat-step disabled>
      <ng-template matStepLabel>完成</ng-template>
      <div style="margin-bottom:10px;">
        <mdb-icon fas icon="check-circle" class="primary-color"></mdb-icon>
      </div>
      <div class="m-t-5">
        任务将发送给相关部门跟进。
      </div>


      <div style="margin-top:10px;">
        <mat-divider></mat-divider>
        <button mat-button matStepperPrevious style="color:lightseagreen" routerLink="/workOrderMap">建立另一个任务</button>
        <button mat-button matStepperPrevious style="color:lightseagreen" routerLink="/inbox">任务清单</button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>

<app-navigation></app-navigation>
