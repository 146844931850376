import { Injectable, Inject } from '@angular/core';
import { ProblemAreaResponse } from '../models/problemArea';
import { Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProblemAreaService {

  baseUrl: string

  constructor(private http: HttpClient) {
  }

  getAll(): Promise<ProblemAreaResponse> {
    let url = "api/ProblemArea";
    return new Promise<ProblemAreaResponse>(resolve => {
      if (this.hasCache()) {
        resolve(this.getCache())
      } else {
        this.http.get<ProblemAreaResponse>(url).subscribe(response => {
          this.setCache(response);
          resolve(response);
        });
      }

    })


  }
  hasCache(): boolean {
    var hasCache = localStorage.getItem('problemAreaResponse') != null || localStorage.getItem('problemAreaResponse') != undefined;

    return hasCache;
  }
  getCache(): ProblemAreaResponse {
    var problemAreaResponse = <ProblemAreaResponse>Object.assign(new ProblemAreaResponse, JSON.parse(localStorage.getItem('problemAreaResponse')));
    return problemAreaResponse;
  }
  setCache(problemAreaResponse: ProblemAreaResponse): void {
    localStorage.setItem('problemAreaResponse', JSON.stringify(problemAreaResponse));
  }
}


