
<div class="row wow fadeIn ">
  <div *ngIf="showRefresherHeader" class="col-12">
    <div style="text-align:center">{{refresherState}}</div>
  </div>
  <div class="col-lg-6 col-md-6 mb-4">
    <div class="card">
      <div class="card-header">待接任务 - {{departmentName}}</div>
      <div class="card-body">
        <div style="display: block">
          <canvas mdbChart [chartType]="'doughnut'" [data]="pendingTaskQtys" [labels]="pendingTaskLabels" (chartClick)="onPendingTaskChartClick($event)"
                  [options]="pendingTaskOptions" [legend]="true"></canvas>
        </div>
      </div>

    </div>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 mb-4">
    <div class="card">
      <div class="card-header">今日任务 - {{departmentName}}</div>

      <div class="card-body">

        <div style="display: block">
          <canvas mdbChart [chartType]="'bar'" [data]="todayTaskQtys" [labels]="todayTaskLabels" [colors]="todayTaskColors" (chartClick)="onTodayChartClick($event)"
                  [options]="todayTasksOptions"></canvas>
        </div>
      </div>

    </div>

  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 mb-4">

    <div class="card">

      <div class="card-header">进行中 - {{departmentName}}</div>

      <div class="card-body">

        <div style="display: block">
          <canvas mdbChart [chartType]="'pie'" [datasets]="workingDatasets" [labels]="workingTaskLabels" (chartClick)="onWorkingChartClick($event)"
              [colors]="workingColors"    [options]="workingTasksOptions" [legend]="true"></canvas>
        </div>
      </div>

    </div>

  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 mb-4">

    <div class="card">

      <div class="card-header">逾期任务 - {{departmentName}}</div>

      <div class="card-body">

        <div style="display: block">
          <canvas mdbChart [chartType]="'polarArea'" [data]="overdueQtys" [labels]="overDueLabels" (chartClick)="onOverdueChartClick($event)"
                  [options]="overDueTasksOptions" [legend]="true"></canvas>
        </div>
      </div>

    </div>

  </div>



</div>

<app-navigation></app-navigation>
