import { ProblemLogListItem } from  './problemLogListItem';
export class AssignTaskForm {
  workerId: string;
  targetCompleteDate: string;
  targetCompleteTime: string;
  createdBy: string;
  problemLogId: number;
}
export class AssignTaskFormResponse {
  errcode: number;
  errmsg: string;
  problemLogListItem: ProblemLogListItem;
}
