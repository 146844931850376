import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ProblemTypesResponse} from '../models/problemType';
import { ProblemResponse } from '../models/problem';


@Injectable({
  providedIn: 'root'
})
export class ProblemTypeService {

  baseUrl: string

  constructor(private http: HttpClient) {
  }

  getAll(): Promise<ProblemTypesResponse>{
    let url = "api/ProblemType";
    return new Promise<ProblemTypesResponse>(resolve => {
      if (this.hasCache()) {
        resolve(this.getCache())
      } else {
        this.http.get<ProblemTypesResponse>(url).subscribe(response => {
          this.setCache(response);
          resolve(response);
        });
      }

    })


  }
  hasCache():boolean {
    var hasCache = localStorage.getItem('problemTypesResponse') != null || localStorage.getItem('problemTypesResponse') != undefined;

    //return hasCache;
    return false;
  }
  getCache(): ProblemTypesResponse {
    var problemTypesResponse = <ProblemTypesResponse>Object.assign(new ProblemTypesResponse, JSON.parse(localStorage.getItem('problemTypesResponse')));
    return problemTypesResponse;
  }
  setCache(problemTypesResponse: ProblemTypesResponse): void {
    localStorage.setItem('problemTypesResponse', JSON.stringify(problemTypesResponse));
    }
}
