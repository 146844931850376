import { Problem } from "./problem";

export class ProblemType{
    problemTypeId: number;
    name: string;
    problems: Problem[];
}

export class ProblemTypesResponse {
  errcode: number;
  errmsg: string;
  problemTypes: ProblemType[];
}
