import { HttpClient } from '@angular/common/http';
import { ProblemTypesResponse } from '../models/problemType';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProblemTypeService = /** @class */ (function () {
    function ProblemTypeService(http) {
        this.http = http;
    }
    ProblemTypeService.prototype.getAll = function () {
        var _this = this;
        var url = "api/ProblemType";
        return new Promise(function (resolve) {
            if (_this.hasCache()) {
                resolve(_this.getCache());
            }
            else {
                _this.http.get(url).subscribe(function (response) {
                    _this.setCache(response);
                    resolve(response);
                });
            }
        });
    };
    ProblemTypeService.prototype.hasCache = function () {
        var hasCache = localStorage.getItem('problemTypesResponse') != null || localStorage.getItem('problemTypesResponse') != undefined;
        //return hasCache;
        return false;
    };
    ProblemTypeService.prototype.getCache = function () {
        var problemTypesResponse = Object.assign(new ProblemTypesResponse, JSON.parse(localStorage.getItem('problemTypesResponse')));
        return problemTypesResponse;
    };
    ProblemTypeService.prototype.setCache = function (problemTypesResponse) {
        localStorage.setItem('problemTypesResponse', JSON.stringify(problemTypesResponse));
    };
    ProblemTypeService.ngInjectableDef = i0.defineInjectable({ factory: function ProblemTypeService_Factory() { return new ProblemTypeService(i0.inject(i1.HttpClient)); }, token: ProblemTypeService, providedIn: "root" });
    return ProblemTypeService;
}());
export { ProblemTypeService };
