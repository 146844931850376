/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ng-uikit-pro-standard";
import * as i2 from "./add-image-dialog.component";
import * as i3 from "@alyle/ui/dialog";
import * as i4 from "@alyle/ui";
import * as i5 from "@alyle/ui/typography";
import * as i6 from "../cropimage/cropimage.component.ngfactory";
import * as i7 from "../cropimage/cropimage.component";
import * as i8 from "@angular/forms";
import * as i9 from "@alyle/ui/grid";
import * as i10 from "../../../../node_modules/@alyle/ui/button/alyle-ui-button.ngfactory";
import * as i11 from "@alyle/ui/button";
var styles_AddImageDialogComponent = [];
var RenderType_AddImageDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddImageDialogComponent, data: {} });
export { RenderType_AddImageDialogComponent as RenderType_AddImageDialogComponent };
export function View_AddImageDialogComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "deep-purple darken-1"], ["floating", "true"], ["mdbBtn", ""], ["mdbWavesEffect", ""], ["size", "md"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).click($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.open() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MdbBtnDirective, [i0.ElementRef, i0.Renderer2], { size: [0, "size"], floating: [1, "floating"] }, null), i0.ɵdid(2, 16384, null, 0, i1.WavesDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-camera-retro fa-2x"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "i", [["class", "fas fa-map-marked-alt fa-1x"], ["mdbWavesEffect", ""], ["placement", "top"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).click($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i1.WavesDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵted(-1, null, [" upload image "]))], function (_ck, _v) { var currVal_0 = "md"; var currVal_1 = "true"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AddImageDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "add-image-dialog", [], null, null, null, View_AddImageDialogComponent_0, RenderType_AddImageDialogComponent)), i0.ɵdid(1, 49152, null, 0, i2.AddImageDialogComponent, [i3.LyDialog, i4.LyTheme2], null, null)], null, null); }
var AddImageDialogComponentNgFactory = i0.ɵccf("add-image-dialog", i2.AddImageDialogComponent, View_AddImageDialogComponent_Host_0, {}, { addImage: "addImage" }, []);
export { AddImageDialogComponentNgFactory as AddImageDialogComponentNgFactory };
var styles_CropImageDialog = [];
var RenderType_CropImageDialog = i0.ɵcrt({ encapsulation: 2, styles: styles_CropImageDialog, data: {} });
export { RenderType_CropImageDialog as RenderType_CropImageDialog };
export function View_CropImageDialog_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { cropimage: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 2, "ly-toolbar", [["bg", "primary"], ["dense", ""], ["position", "static"]], [[8, "elevation", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h6", [["lyTyp", "title"]], null, null, null, null, null)), i0.ɵdid(3, 606208, null, 0, i5.LyTypography, [i4.LyTheme2, i0.ElementRef, i0.Renderer2], { lyTyp: [0, "lyTyp"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "h4", [["gutterBottom", ""], ["ly-dialog-title", ""]], null, null, null, null, null)), i0.ɵdid(5, 81920, null, 0, i3.ɵb, [i0.Renderer2, i0.ElementRef, i4.LyTheme2], null, null), (_l()(), i0.ɵted(-1, null, ["\u9009\u62E9\u7167\u7247"])), (_l()(), i0.ɵeld(7, 0, null, null, 11, "div", [["ly-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(8, 81920, null, 0, i3.ɵc, [i0.Renderer2, i0.ElementRef, i4.LyTheme2], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 9, "p", [["color", "text:secondary"], ["lyTyp", "body2"]], null, null, null, null, null)), i0.ɵdid(10, 606208, null, 0, i5.LyTypography, [i4.LyTheme2, i0.ElementRef, i0.Renderer2], { color: [0, "color"], lyTyp: [1, "lyTyp"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 1, "app-cropimage", [], null, [[null, "addImage"], [null, "isCropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addImage" === en)) {
        var pd_0 = (_co.onAddImage($event) !== false);
        ad = (pd_0 && ad);
    } if (("isCropped" === en)) {
        var pd_1 = (_co.onIsCropped($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_CropimageComponent_0, i6.RenderType_CropimageComponent)), i0.ɵdid(12, 4243456, [[1, 4]], 0, i7.CropimageComponent, [], null, { addImage: "addImage", isCropped: "isCropped" }), (_l()(), i0.ɵeld(13, 0, null, null, 5, "input", [["type", "hidden"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 14).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 14)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 14)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.data.addedImage = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 16384, null, 0, i8.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i8.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.DefaultValueAccessor]), i0.ɵdid(16, 671744, null, 0, i8.NgModel, [[8, null], [8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i8.NgControl, null, [i8.NgModel]), i0.ɵdid(18, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null), (_l()(), i0.ɵeld(19, 0, null, null, 8, "ly-grid", [["container", ""], ["justify", "end"], ["ly-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(20, 81920, null, 0, i3.ɵd, [i0.Renderer2, i0.ElementRef, i4.LyTheme2], null, null), i0.ɵdid(21, 16384, null, 0, i9.LyGrid, [i4.LyTheme2, i0.ElementRef], { justify: [0, "justify"] }, null), (_l()(), i0.ɵeld(22, 0, null, null, 2, "button", [["color", "primary"], ["ly-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_LyButton_0, i10.RenderType_LyButton)), i0.ɵdid(23, 4964352, null, 0, i11.LyButton, [i0.ElementRef, i0.Renderer2, i4.LyTheme2, i0.NgZone, i4.LyRippleService, i4.LyFocusState], { color: [0, "color"] }, null), (_l()(), i0.ɵted(-1, 0, ["\u53D6\u6D88"])), (_l()(), i0.ɵeld(25, 0, null, null, 2, "button", [["color", "primary"], ["ly-button", ""]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getImage(_co.data.addedImage) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_LyButton_0, i10.RenderType_LyButton)), i0.ɵdid(26, 4964352, null, 0, i11.LyButton, [i0.ElementRef, i0.Renderer2, i4.LyTheme2, i0.NgZone, i4.LyRippleService, i4.LyFocusState], { color: [0, "color"] }, null), (_l()(), i0.ɵted(-1, 0, ["\u65B0\u589E"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "title"; _ck(_v, 3, 0, currVal_1); _ck(_v, 5, 0); _ck(_v, 8, 0); var currVal_2 = "text:secondary"; var currVal_3 = "body2"; _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_11 = _co.data.addedImage; _ck(_v, 16, 0, currVal_11); _ck(_v, 20, 0); var currVal_12 = "end"; _ck(_v, 21, 0, currVal_12); var currVal_13 = "primary"; _ck(_v, 23, 0, currVal_13); var currVal_15 = "primary"; _ck(_v, 26, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = 3; _ck(_v, 1, 0, currVal_0); var currVal_4 = i0.ɵnov(_v, 18).ngClassUntouched; var currVal_5 = i0.ɵnov(_v, 18).ngClassTouched; var currVal_6 = i0.ɵnov(_v, 18).ngClassPristine; var currVal_7 = i0.ɵnov(_v, 18).ngClassDirty; var currVal_8 = i0.ɵnov(_v, 18).ngClassValid; var currVal_9 = i0.ɵnov(_v, 18).ngClassInvalid; var currVal_10 = i0.ɵnov(_v, 18).ngClassPending; _ck(_v, 13, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_14 = !_co.data.isCropped; _ck(_v, 25, 0, currVal_14); }); }
export function View_CropImageDialog_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CropImageDialog_0, RenderType_CropImageDialog)), i0.ɵdid(1, 49152, null, 0, i2.CropImageDialog, [i3.LyDialogRef, i3.LY_DIALOG_DATA], null, null)], null, null); }
var CropImageDialogNgFactory = i0.ɵccf("ng-component", i2.CropImageDialog, View_CropImageDialog_Host_0, {}, {}, []);
export { CropImageDialogNgFactory as CropImageDialogNgFactory };
