import {
  Component, Input, ElementRef, AfterViewInit, ViewChild,HostListener
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators'
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
  selector: 'app-draw-canvas',
  templateUrl: './draw-canvas.component.html',
  styleUrls: ['./draw-canvas.component.css']
})


export class DrawCanvasComponent implements AfterViewInit {

  @ViewChild('canvas') public canvas: ElementRef;

  @Input() public width = 500;
  @Input() public height = 281.25;
  @HostListener('window:resize', ['$event'])
  private cx: CanvasRenderingContext2D;
  private loadedImage:string;

  public ngAfterViewInit() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');

    canvasEl.width = this.width;
    canvasEl.height = this.height;
    canvasEl.style.width = "90vw";
    canvasEl.style.maxWidth = "60vh";
    


    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#FF0000';

    this.captureEvents(canvasEl);
  }
  
  private captureEvents(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
          e.preventDefault();
          e.stopPropagation();
          // after a mouse down, we'll record all mouse moves
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event    
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point    
              pairwise()
            )
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();
        const scaleX = canvasEl.width / rect.width;    // relationship bitmap vs. element for X
        const scaleY = canvasEl.height / rect.height;  // relationship bitmap vs. element for Y
      
        // previous and current position with the offset
        const prevPos = {
          x: (res[0].clientX - rect.left)*scaleX,
          y: (res[0].clientY - rect.top)*scaleY
        };
  
        const currentPos = {
          x: (res[1].clientX - rect.left)*scaleX,
          y: (res[1].clientY - rect.top)*scaleY
        };
        // this method we'll implement soon to do the actual drawing
        this.drawOnCanvas(prevPos, currentPos);
      });

      fromEvent(canvasEl, 'touchstart')
      .pipe(
        switchMap((e) => {
          e.preventDefault();
          e.stopPropagation();
          return fromEvent(canvasEl, 'touchmove')
            .pipe(
              takeUntil(fromEvent(canvasEl, 'touchend')),
              pairwise()
            )
        })
      )
      .subscribe((res: [TouchEvent,TouchEvent]) => {
        const rect = canvasEl.getBoundingClientRect();
        const scaleX = canvasEl.width / rect.width;    // relationship bitmap vs. element for X
        const scaleY = canvasEl.height / rect.height;  // relationship bitmap vs. element for Y
  
        // previous and current position with the offset
        const prevPos = {
          x: (res[0].touches[0].clientX - rect.left) * scaleX,
          y: (res[0].touches[0].clientY - rect.top) * scaleY
        };
  
        const currentPos = {
          x: (res[1].touches[0].clientX - rect.left) * scaleX,
          y: (res[1].touches[0].clientY - rect.top) * scaleY
        };
  
        // this method we'll implement soon to do the actual drawing
        this.drawOnCanvas(prevPos, currentPos);
      });
  }
  public clearCanvas(){
    if (!this.cx) { return; }
    this.cx.clearRect(0, 0, this.canvas.nativeElement.width,this.canvas.nativeElement.height);
    this.loadImage(this.loadedImage);
  }
  private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
    if (!this.cx) { return; }

    this.cx.beginPath();

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
    }
  }

  public loadImage(imageURL: string){
      if (!this.cx) { return; }
      this.loadedImage = imageURL;
      var ctx = this.cx;
      var img = new Image();
      img.onload = function (){
        ctx.drawImage(img, 0, 0);
      }
      img.src = imageURL;
  }

  public getImage():any{
    return this.canvas.nativeElement.toDataURL();
  }
}
