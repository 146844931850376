import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service.service';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Dashboard } from 'src/app/models/dashboard';
import { User } from 'src/app/models/User';
import { ProblemLogSearchForm } from 'src/app/models/problemLogSearchForm';
import { ProblemLogService } from 'src/app/services/problem-log.service';
import { Department } from 'src/app/models/department';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss']
})
export class DashboardsComponent implements OnInit {
  pendingTaskQtys: Array<any> = [];
  pendingTaskLabels: Array<any> = [];
  pendingTaskOptions: any = {
    responsive: true,
    legend: {
      labels: {
        boxWidth: 20
      }
    }
  }

  todayTaskLabels: Array<any> = ["已建立", "指派中", "接收中", "工作中", "已完成"];
  todayTaskQtys: Array<any> = [{ data: [0,0,0,0,0], label:'' }];
  todayTasksOptions: any = {
    responsive: true,
    legend: {
      labels: {
        boxWidth: 20
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          stepSize: 1
        }
      }]
    }
  };
  todayTaskColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
      ],
      borderColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
      ],
      borderWidth: 2,
    }
  ];
  workingDatasets: Array<any> = [
    { data: [0, 0, 0, 0, 0], label: '' }
  ];
  workingTaskLabels: Array<any> = ["清洁", "维修", "投诉", "服务", "市场活动"];
  workingColors: Array<any> = [
    {
      backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'],
      hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5', '#616774'],
      borderWidth: 2,
    }
  ];
  workingTasksOptions: any = {
    responsive: true,
    legend: {
      labels: {
        boxWidth: 20
      }
    }
  };

  public overdueQtys: Array<any> = [0,0,0,0];
  public overDueLabels: Array<any> = ["2d", "3-7d", "8-14d", ">14d"];
  overDueTasksOptions: any = {
    responsive: true,
    legend: {
      labels: {
        boxWidth: 20
      }
    }
  };
  dashBoard: Dashboard;
  currentUser: User;
  departments: Department[];
  constructor(
    private problemLogService: ProblemLogService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router:Router,
    private preLoader: MDBSpinningPreloader,
    private dashBoardService: DashboardService,

  ) {
    this.departments = [];
  }

  async ngOnInit() {
    this.preLoader.stop();
    window.scrollTo(0, 0);
    var code = this.route.snapshot.queryParams['code'];
    var hasCode = code != null && code != undefined

    if (hasCode) {
      await this.authService.authenticate(code);
      console.log('222222')
      this.authService.currentUser.subscribe(user => {
        this.currentUser = user;
        console.log('user', user)
        if ((user == null || !user.hasCorpMap || !user.isAllowedDepartment) && !hasCode) {
          this.preLoader.stop();
          this.router.navigate(["unauthorized"]);
        }
        this.queryDashboard(user);
      })
    }
    else {
      this.authService.currentUser.subscribe(user => {
        this.currentUser = user;
        if ((user == null || !user.hasCorpMap || !user.isAllowedDepartment) && !hasCode) {
          this.preLoader.stop();
          this.router.navigate(["unauthorized"]);
        }
        this.queryDashboard(user);
      })
    }

  }
  pullToRefresh() {
    this.preLoader.start();
      this.queryDashboard(this.currentUser);

  }
  queryDashboard(user: User) {
    this.dashBoardService.getDashboard(user.departments[0]).subscribe(response => {
      this.dashBoard = response.dashboard;

      this.pendingTaskQtys = this.dashBoard.pendingTaskQtys;
      this.pendingTaskLabels = this.dashBoard.pendingTaskLabels;

      this.todayTaskQtys = [{ data: this.dashBoard.todayTaskQtys, label: '' }];

      this.workingDatasets = [{ data: this.dashBoard.workingTaskQtys, label: '' }];

      this.overdueQtys = [{ data: this.dashBoard.overdueTaskQtys, label: '' }];

    });
  }

  onPendingTaskChartClick(event: any) {
    var searchForm = new ProblemLogSearchForm();

    window.scrollTo(0, 0);
    searchForm.departmentId = this.currentUser.departmentId;
    searchForm.wfStatus = ["PendingAssign", "PendingPick"];
    searchForm.lastItemId = 0;
    searchForm.take = 5;
    searchForm.problemTypeId = event.active[0]._index + 1;
    searchForm.fromDashboards = true;
    this.problemLogService.SetSearchForm(searchForm);

    this.router.navigateByUrl('inbox');

  }

  onTodayChartClick(event: any) {
    var searchForm = new ProblemLogSearchForm();
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth();
    var day = today.getDate();

    window.scrollTo(0, 0);
    searchForm.departmentId = this.currentUser.departmentId;
    searchForm.lastItemId = 0;
    searchForm.take = 5;
    searchForm.byDate = new Date(year, month, day);
    searchForm.fromDashboards = true;
    this.problemLogService.SetSearchForm(searchForm);

    window.scrollTo(0, 0);
    this.router.navigateByUrl('inbox');

  }
  onWorkingChartClick(event: any) {
    var searchForm = new ProblemLogSearchForm();

    window.scrollTo(0, 0);
    searchForm.departmentId = this.currentUser.departmentId;
    searchForm.lastItemId = 0;
    searchForm.take = 5;
    searchForm.fromDashboards = true;
    searchForm.problemTypeId = event.active[0]._index + 1;
    searchForm.wfStatus = ['Working'];
    this.problemLogService.SetSearchForm(searchForm);

    this.router.navigateByUrl('inbox');
  }
  onOverdueChartClick(event: any) {
    var index = event.active[0]._index;
    var startDay = 0;
    var endDay = 0;
    var searchForm = new ProblemLogSearchForm();
    switch (index) {
      case 0:
        startDay = 0;
        endDay = 2;
        break;
      case 1:
        startDay = 3;
        endDay = 7;
        break;
      case 2:
        startDay = 8;
        endDay = 14;
        break;
      case 3:
        startDay = 15;
        endDay = 999;
        break;
      default:
        break
    }
    window.scroll(0, 0);
    searchForm.departmentId = this.currentUser.departmentId;
    searchForm.lastItemId = 0;
    searchForm.take = 5;
    searchForm.fromDashboards = true;
    searchForm.overDueStartDay = startDay;
    searchForm.overDueEndDay = endDay;
    searchForm.wfStatus = ['PendingAssign', 'PendingPick', 'Working']

    this.problemLogService.SetSearchForm(searchForm);

    this.router.navigateByUrl('inbox');
  }
  get departmentName(): string {
    if (this.currentUser == null || this.currentUser.departmentName == null)
      return '';
    return this.currentUser.departmentName;
  }

  touchStarting: boolean;
  touchStartY: number;
  touchMoveY: number;
  touchEnding: boolean;
  get showRefresherHeader() {
    var showRefresher = this.touchStarting && this.touchStartY < 500 && (this.touchMoveY - this.touchStartY > 100);

    return showRefresher
  }
  get refresherState() {
    if (this.showRefresherHeader && !this.touchEnding)
      return '松开立即刷新...';

    if (this.showRefresherHeader && this.touchEnding)
      return '刷新中...';
  }
  @HostListener('touchstart', ['$event'])
  touchStart(e) {
    this.touchStarting = true;
    this.touchStartY = e.touches[0].screenY;
  }
  @HostListener('touchmove', ['$event'])
  touchMove(e) {
    this.touchMoveY = e.touches[0].screenY;
  }
  @HostListener('touchend', ['$event'])
  touchEnd(e) {
    this.touchEnding = true;
    if (this.showRefresherHeader) {
      this.queryDashboard(this.currentUser)

      setTimeout(() => {
        this.touchStarting = false;
        this.touchEnding = false;
        this.touchStartY = 0;
        this.touchMoveY = 0;
      }, 2000)
    } else {
      this.touchStartY = 0;
      this.touchMoveY = 0;
    }

  }
}
