export class ProblemLogListItem {
  problemLogId:number;
  featureId: number;
  featureName: string;
  floorId: number;
  floorName: string;
  departmentId: number;
  problemId: number;
  problemAreaId: number;
  problemTypeId: number;
  description: string;
  problemPhotos: ProblemLogPhotoListItem[];
  status: string;
  statusText: string;
  displayDate: Date;
  progress: number
  displayDateText: string; //create date or completedate, it depends on status
  displayProgressText: string;
  displayTotalDaysText: string;
  displayHeader: string;
  mapIframeSrc: string="/blank";
  problemTypeIconCss: string;
  departmentName:string;
  workerName: string;
  targetCompleteDate: string;
  completeDate: string;
  progressStatusCss: string
}

export class ProblemLogPhotoListItem{
  problemPhototId: number;
  name: string;
  imageSrc: string;
}
