/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboards.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ng-uikit-pro-standard";
import * as i4 from "../../main-layout/navigation/navigation.component.ngfactory";
import * as i5 from "../../main-layout/navigation/navigation.component";
import * as i6 from "../../services/auth-service.service";
import * as i7 from "./dashboards.component";
import * as i8 from "../../services/problem-log.service";
import * as i9 from "@angular/router";
import * as i10 from "../../services/dashboard.service";
var styles_DashboardsComponent = [i0.styles];
var RenderType_DashboardsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardsComponent, data: {} });
export { RenderType_DashboardsComponent as RenderType_DashboardsComponent };
function View_DashboardsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["style", "text-align:center"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.refresherState; _ck(_v, 2, 0, currVal_0); }); }
export function View_DashboardsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 34, "div", [["class", "row wow fadeIn "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardsComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "col-lg-6 col-md-6 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["\u5F85\u63A5\u4EFB\u52A1 - ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["style", "display: block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "canvas", [["mdbChart", ""]], null, [[null, "chartClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("chartClick" === en)) {
        var pd_0 = (_co.onPendingTaskChartClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 737280, null, 0, i3.BaseChartDirective, [i1.ElementRef, i1.PLATFORM_ID], { data: [0, "data"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], legend: [4, "legend"] }, { chartClick: "chartClick" }), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "col-xl-6 col-lg-6 col-md-6 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["\u4ECA\u65E5\u4EFB\u52A1 - ", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["style", "display: block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "canvas", [["mdbChart", ""]], null, [[null, "chartClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("chartClick" === en)) {
        var pd_0 = (_co.onTodayChartClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 737280, null, 0, i3.BaseChartDirective, [i1.ElementRef, i1.PLATFORM_ID], { data: [0, "data"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], colors: [4, "colors"] }, { chartClick: "chartClick" }), (_l()(), i1.ɵeld(19, 0, null, null, 7, "div", [["class", "col-xl-6 col-lg-6 col-md-6 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 6, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, ["\u8FDB\u884C\u4E2D - ", ""])), (_l()(), i1.ɵeld(23, 0, null, null, 3, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 2, "div", [["style", "display: block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "canvas", [["mdbChart", ""]], null, [[null, "chartClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("chartClick" === en)) {
        var pd_0 = (_co.onWorkingChartClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 737280, null, 0, i3.BaseChartDirective, [i1.ElementRef, i1.PLATFORM_ID], { datasets: [0, "datasets"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], colors: [4, "colors"], legend: [5, "legend"] }, { chartClick: "chartClick" }), (_l()(), i1.ɵeld(27, 0, null, null, 7, "div", [["class", "col-xl-6 col-lg-6 col-md-6 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 6, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(30, null, ["\u903E\u671F\u4EFB\u52A1 - ", ""])), (_l()(), i1.ɵeld(31, 0, null, null, 3, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 2, "div", [["style", "display: block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 1, "canvas", [["mdbChart", ""]], null, [[null, "chartClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("chartClick" === en)) {
        var pd_0 = (_co.onOverdueChartClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(34, 737280, null, 0, i3.BaseChartDirective, [i1.ElementRef, i1.PLATFORM_ID], { data: [0, "data"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], legend: [4, "legend"] }, { chartClick: "chartClick" }), (_l()(), i1.ɵeld(35, 0, null, null, 1, "app-navigation", [], null, null, null, i4.View_NavigationComponent_0, i4.RenderType_NavigationComponent)), i1.ɵdid(36, 114688, null, 0, i5.NavigationComponent, [i6.AuthService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showRefresherHeader; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.pendingTaskQtys; var currVal_3 = _co.pendingTaskLabels; var currVal_4 = _co.pendingTaskOptions; var currVal_5 = "doughnut"; var currVal_6 = true; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.todayTaskQtys; var currVal_9 = _co.todayTaskLabels; var currVal_10 = _co.todayTasksOptions; var currVal_11 = "bar"; var currVal_12 = _co.todayTaskColors; _ck(_v, 18, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_14 = _co.workingDatasets; var currVal_15 = _co.workingTaskLabels; var currVal_16 = _co.workingTasksOptions; var currVal_17 = "pie"; var currVal_18 = _co.workingColors; var currVal_19 = true; _ck(_v, 26, 0, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19); var currVal_21 = _co.overdueQtys; var currVal_22 = _co.overDueLabels; var currVal_23 = _co.overDueTasksOptions; var currVal_24 = "polarArea"; var currVal_25 = true; _ck(_v, 34, 0, currVal_21, currVal_22, currVal_23, currVal_24, currVal_25); _ck(_v, 36, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.departmentName; _ck(_v, 6, 0, currVal_1); var currVal_7 = _co.departmentName; _ck(_v, 14, 0, currVal_7); var currVal_13 = _co.departmentName; _ck(_v, 22, 0, currVal_13); var currVal_20 = _co.departmentName; _ck(_v, 30, 0, currVal_20); }); }
export function View_DashboardsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboards", [], null, [[null, "touchstart"], [null, "touchmove"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("touchstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).touchStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchmove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).touchMove($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).touchEnd($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_DashboardsComponent_0, RenderType_DashboardsComponent)), i1.ɵdid(1, 114688, null, 0, i7.DashboardsComponent, [i8.ProblemLogService, i9.ActivatedRoute, i6.AuthService, i9.Router, i3.MDBSpinningPreloader, i10.DashboardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardsComponentNgFactory = i1.ɵccf("app-dashboards", i7.DashboardsComponent, View_DashboardsComponent_Host_0, {}, {}, []);
export { DashboardsComponentNgFactory as DashboardsComponentNgFactory };
