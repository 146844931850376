import {Inject, ChangeDetectionStrategy, Component, ViewChild, Output,EventEmitter } from '@angular/core';
import { ThemeVariables, LyTheme2 } from '@alyle/ui';
import { LyDialogRef, LyDialog , LY_DIALOG_DATA} from '@alyle/ui/dialog';
import { CropimageComponent } from '../cropimage/cropimage.component';
import { filter } from 'rxjs/operators';


export interface DialogData {
  addedImage: Blob;
  isCropped:Boolean;
}

const STYLES_DIALOG = ({
  width: '100vw',
  borderRadius: 0
});

@Component({
  selector: 'add-image-dialog',
  templateUrl: './add-image-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddImageDialogComponent {
  @Output() addImage: EventEmitter<Blob> = new EventEmitter<Blob>();
  addedImage:Blob;
  isCropped:Boolean;

  

  constructor(
    private _dialog: LyDialog,
    private _theme: LyTheme2
  ) { }

  
  open() {
    //var addImageToList = ;
    this.isCropped = false;
    const dialogRef = this._dialog.open<CropImageDialog>(CropImageDialog, {
      maxWidth: null, // current style overrides
      maxHeight: null, // current style overrides
      containerClass: this._theme.style(STYLES_DIALOG),
      data:{
          addedImage:this.addedImage,
          isCropped:this.isCropped
      }
    });

    dialogRef.afterClosed.pipe(filter(result => !!result))
    .subscribe(result => {
      this.addedImage = result;
      this.addImage.emit(this.addedImage);
    });
  }
}

@Component({
  templateUrl: './full-screen-dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CropImageDialog {
  @ViewChild(CropimageComponent)
  cropimage: CropimageComponent;

  constructor(
    public dialogRef: LyDialogRef
    ,@Inject(LY_DIALOG_DATA) public data: DialogData
  ) { }

  onAddImage(image: Blob) {
    this.data.addedImage = image;
    this.dialogRef.close(this.data.addedImage);
  }

  onIsCropped(isCropped:Boolean){
    this.data.isCropped = isCropped;
  }

  public getImage(addedImage:Blob){
    this.cropimage.getImage();
  }
}

