import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WeChatDepartmentUserResponse} from '../models/wechatDepartmentUser';
import { AuthService  } from 'src/app/services/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  baseUrl: string

  constructor(private http: HttpClient, private authService:AuthService) {
  }

  getByDepartment(departmentId: number): Observable<WeChatDepartmentUserResponse>{
   
    let url = "api/Staff?departmentId=" + departmentId;
    return this.http.get<WeChatDepartmentUserResponse>(url);
  }
}
