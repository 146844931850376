import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AssignTaskForm, AssignTaskFormResponse } from '../models/assignTaskForm';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssignTaskService {
  baseUrl: string

  constructor(private http: HttpClient) {
  }

  public Save(assignTaskForm: AssignTaskForm): Observable<AssignTaskFormResponse> {
    let url = "/api/ProblemLog/AssignTask";

    return this.http.post<AssignTaskFormResponse>(url, assignTaskForm);
  }
}
