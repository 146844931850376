<ly-toolbar dense
  position="static"
  bg="primary"
  [elevation]="3"
>
  <h6 lyTyp="title"></h6>
</ly-toolbar>

<h4 ly-dialog-title gutterBottom>选择照片</h4>
<div ly-dialog-content>
  <p lyTyp="body2" color="text:secondary">
    <app-cropimage (addImage)="onAddImage($event)" (isCropped)="onIsCropped($event)"></app-cropimage>
    <input type="hidden"
      [(ngModel)]="data.addedImage">
  </p>
</div>
<ly-grid container justify="end" ly-dialog-actions>
  <button ly-button color="primary" (click)="dialogRef.close()">取消</button>
  <button [hidden]="!data.isCropped" ly-button color="primary" (click)="getImage(data.addedImage)">新增</button>
</ly-grid>
