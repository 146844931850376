import { Injectable } from '@angular/core';
import { Dashboard, DashboardResponse } from '../models/dashboard';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {


  constructor(private http: HttpClient) {
  }

  public getDashboard(departmentId: number): Observable<DashboardResponse> {
    let url = "/api/Dashboard/GetDashboard?departmentId=" + departmentId

    return this.http.get<DashboardResponse>(url);
  }
}
