/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blank-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./blank-view.component";
var styles_BlankViewComponent = [i0.styles];
var RenderType_BlankViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlankViewComponent, data: {} });
export { RenderType_BlankViewComponent as RenderType_BlankViewComponent };
export function View_BlankViewComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_BlankViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-blank-view", [], null, null, null, View_BlankViewComponent_0, RenderType_BlankViewComponent)), i1.ɵdid(1, 114688, null, 0, i2.BlankViewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlankViewComponentNgFactory = i1.ɵccf("app-blank-view", i2.BlankViewComponent, View_BlankViewComponent_Host_0, {}, {}, []);
export { BlankViewComponentNgFactory as BlankViewComponentNgFactory };
