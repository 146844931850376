import { HttpClient } from '@angular/common/http';
import { DepartmentResponse } from '../models/department';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DepartmentService = /** @class */ (function () {
    function DepartmentService(http) {
        this.http = http;
    }
    DepartmentService.prototype.getAll = function (userId) {
        var _this = this;
        var url = "api/Department?userId=" + userId;
        return new Promise(function (resolve) {
            if (_this.hasCache()) {
                resolve(_this.getCache());
            }
            else {
                _this.http.get(url).subscribe(function (response) {
                    _this.setCache(response);
                    resolve(response);
                });
            }
        });
    };
    DepartmentService.prototype.hasCache = function () {
        // var hasCache = localStorage.getItem('departmentResponse') != null || localStorage.getItem('departmentResponse') != undefined;
        //return hasCache;
        return false;
    };
    DepartmentService.prototype.getCache = function () {
        var problemTypesResponse = Object.assign(new DepartmentResponse, JSON.parse(localStorage.getItem('departmentResponse')));
        return problemTypesResponse;
    };
    DepartmentService.prototype.setCache = function (problemTypesResponse) {
        localStorage.setItem('departmentResponse', JSON.stringify(problemTypesResponse));
    };
    DepartmentService.ngInjectableDef = i0.defineInjectable({ factory: function DepartmentService_Factory() { return new DepartmentService(i0.inject(i1.HttpClient)); }, token: DepartmentService, providedIn: "root" });
    return DepartmentService;
}());
export { DepartmentService };
