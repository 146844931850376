import { ChangeDetectionStrategy, Component, ViewChild, EventEmitter, ElementRef, Input, Output } from '@angular/core';
import { styles } from './cropimage.styles';
import { DrawCanvasComponent } from '../draw-canvas/draw-canvas.component'
import { ImageCroppedEvent,ImageCropperComponent } from 'ngx-image-cropper';


export class Cropping {
  isCropped: boolean;
  isLoaded: boolean;
}


@Component({
  selector: 'app-cropimage',
  templateUrl: './cropimage.component.html',
  styleUrls: ['./cropimage.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CropimageComponent {
  @ViewChild('_fileInput') public canvas: ElementRef;
  @Output() addImage: EventEmitter<Blob> = new EventEmitter<Blob>();
  @Output() isCropped: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @ViewChild(DrawCanvasComponent) drawCanvas: DrawCanvasComponent;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  cropping: Cropping = {
    isCropped: false,
    isLoaded: false
  };
  
  imageChangedEvent: any = '';
  croppedImage: any = '';
  
  fileChangeEvent(event: any): void {
      this.cropping.isLoaded = true;
      this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
      //this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  loadImageFailed() {
      // show message
  }

  rotateRight(){
    this.imageCropper.rotateRight();

  }

  rotateLeft(){
    this.imageCropper.rotateRight();
  }


  crop() {
    this.cropping.isCropped = true;
    this.croppedImage = (<ImageCroppedEvent>this.imageCropper.crop()).base64;
    this.drawCanvas.loadImage(this.croppedImage);
    this.isCropped.emit(true);
  }

  public getImage(){
    this.addImage.emit(this.drawCanvas.getImage());
  }

  ngAfterViewInit(){
    
    const _fileInput: HTMLCanvasElement = this.canvas.nativeElement;
    _fileInput.click();
  }
  constructor() { 
   
  }


}
