import { Injectable } from '@angular/core';
import { CorpMap, CorpMapResponse } from '../models/corpMap';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CorpMapService {


  constructor(private http: HttpClient) {
  }

  public getMap(wechatCropId: number): Observable<CorpMapResponse> {
    let url = "/api/CorpMap/GetMap?wechatCropId=" + wechatCropId

    return this.http.get<CorpMapResponse>(url);
  }
}
