import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service.service';
import { User } from 'src/app/models/User';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {
  currentUser: User;
  constructor(
    private authService: AuthService,
    private preloader: MDBSpinningPreloader
  ) { }

  ngOnInit() {
    this.preloader.stop();
    this.currentUser = this.authService.currentUserValue;
  }

}
