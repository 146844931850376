import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { ToastService } from 'ng-uikit-pro-standard';
import { humanizeBytes } from 'ng-uikit-pro-standard';
import { NewProblemLog } from 'src/app/models/newProblemLog';
import { ProblemPhoto } from 'src/app/models/problemPhoto';
import { ProblemTypeService } from 'src/app/services/problem-type.service';
import { DepartmentService } from 'src/app/services/department.service';
import { ProblemAreaService } from 'src/app/services/problem-area.service';
import { NewProblemLogService } from 'src/app/services/new-problem-log.service';
import { AuthService } from 'src/app/services/auth-service.service';
import '../../interfaces/window';
import '../../interfaces/floor';
var CreateWorkOrderComponent = /** @class */ (function () {
    function CreateWorkOrderComponent(fb, problemTypeService, departmentService, route, problemAreaService, newProblemLogService, authService, preLoader, toastService, router) {
        this.fb = fb;
        this.problemTypeService = problemTypeService;
        this.departmentService = departmentService;
        this.route = route;
        this.problemAreaService = problemAreaService;
        this.newProblemLogService = newProblemLogService;
        this.authService = authService;
        this.preLoader = preLoader;
        this.toastService = toastService;
        this.router = router;
        this.problemPhotos = [];
        this.myDatePickerOptions = {};
        this.isLinear = true;
        this.toastOption = { positionClass: 'toast-center' };
        this.preLoader.stop();
        if (window.currentFloorId == undefined || window.currentFeatureId == undefined) {
            this.router.navigateByUrl('workOrderMap');
        }
        this.featureId = window.currentFeatureId;
        this.featureName = window.currentFeatureName;
        this.floorId = window.currentFloorId;
        this.floorName = window.currentFloorName;
        this.files = [];
        this.uploadInput = new EventEmitter();
        this.humanizeBytes = humanizeBytes;
        this.stepOneForm = fb.group({
            floorId: ['', Validators.required],
            floorName: ['', Validators.required],
            featureId: ['', Validators.required],
            featureName: ['', Validators.required],
        });
        this.stepTwoForm = fb.group({
            problemArea: [''],
            problemType: ['', Validators.required],
            problem: ['', Validators.required],
            problemDescription: [''],
            department: ['', Validators.required]
        });
        this.stepThreeForm = fb.group({});
    }
    CreateWorkOrderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.currentUser.subscribe(function (u) { return _this.currentUser = u; });
        this.getProblemTypes();
        this.getDepartments();
        this.getProblemAreas();
        this.fillStepOneForm();
    };
    CreateWorkOrderComponent.prototype.onNext = function (step) {
        if (step.selectedIndex == 3) {
            this.submit();
        }
    };
    Object.defineProperty(CreateWorkOrderComponent.prototype, "problemArea", {
        get: function () {
            if (this.stepTwoForm.get('problemArea').value == null)
                return '';
            return this.stepTwoForm.value.problemArea.name;
        },
        enumerable: true,
        configurable: true
    });
    CreateWorkOrderComponent.prototype.fillStepOneForm = function () {
        this.stepOneForm.patchValue({
            floorId: this.floorId,
            floorName: this.floorName,
            featureId: this.featureId,
            featureName: this.featureName
        });
    };
    CreateWorkOrderComponent.prototype.getProblemAreas = function () {
        var _this = this;
        this.problemAreaService.getAll().then(function (response) { return _this.problemAreas = response.problemAreas; });
    };
    CreateWorkOrderComponent.prototype.getProblemTypes = function () {
        var _this = this;
        this.problemTypeService.getAll().then(function (response) { return _this.problemTypes = response.problemTypes; });
    };
    CreateWorkOrderComponent.prototype.getDepartments = function () {
        var _this = this;
        this.departmentService.getAll(this.currentUser.userId).then(function (response) { return _this.departments = response.departments; });
    };
    CreateWorkOrderComponent.prototype.focusToProblemDescription = function () {
        var _this = this;
        setTimeout(function () {
            _this.problemDescriptionTextArea.nativeElement.focus();
        }, 500);
    };
    CreateWorkOrderComponent.prototype.openFileUpload = function () {
        /*setTimeout(() => {
          this.uploadFileInput.nativeElement.click();
        }, 500);*/
    };
    CreateWorkOrderComponent.prototype.openDepartment = function () {
        var _this = this;
        setTimeout(function () {
            _this.departmentSelect.open();
        }, 500);
    };
    CreateWorkOrderComponent.prototype.openProblemType = function () {
        var _this = this;
        setTimeout(function () {
            _this.problemTypeSelect.open();
        }, 500);
    };
    CreateWorkOrderComponent.prototype.selectedProblemType = function (pt) {
        this.problems = pt.problems;
        this.openProblemSelect();
    };
    CreateWorkOrderComponent.prototype.openProblemSelect = function () {
        var _this = this;
        setTimeout(function () {
            _this.problemSelect.open();
        }, 500);
    };
    CreateWorkOrderComponent.prototype.submit = function () {
        var _this = this;
        var newProblemLog = new NewProblemLog();
        newProblemLog.createdBy = this.currentUser.userId;
        newProblemLog.floorId = this.stepOneForm.value.floorId;
        newProblemLog.floorName = this.stepOneForm.value.floorName;
        newProblemLog.featureId = this.stepOneForm.value.featureId;
        newProblemLog.featureName = this.stepOneForm.value.featureName;
        newProblemLog.departmentId = this.stepTwoForm.value.department.id;
        newProblemLog.problemId = this.stepTwoForm.value.problem.problemId;
        newProblemLog.problemTypeId = this.stepTwoForm.value.problemType.problemTypeId;
        newProblemLog.problemAreaId = this.stepTwoForm.value.problemArea.problemAreaId;
        newProblemLog.description = this.stepTwoForm.value.problemDescription;
        newProblemLog.problemPhotos = this.problemPhotos;
        this.newProblemLogService.Save(newProblemLog).subscribe(function (response) {
            if (response.errcode == 0) {
                _this.toastService.success("任务保存成功！", "", _this.toastOption);
                window.currentFeatureId = undefined;
                window.currentFeatureName = undefined;
                window.currentFloorId = undefined;
                window.currentFloorName = undefined;
                _this.stepOneForm.reset();
                _this.stepTwoForm.reset();
            }
            if (response.errcode > 0) {
                _this.toastService.error("任务保存失敗: " + response.errmsg, "", _this.toastOption);
            }
        });
    };
    CreateWorkOrderComponent.prototype.showFiles = function () {
        //return `總共 ${this.files.length} 檔案`;
        return "\u603B\u5171 " + this.problemPhotos.length + " \u6863\u6848";
    };
    CreateWorkOrderComponent.prototype.startUpload = function () {
        var event = {
            type: 'uploadAll',
            url: 'your-path-to-backend-endpoint',
            method: 'POST',
            data: { foo: 'bar' },
        };
        this.files = [];
        this.problemPhotos = [];
        this.uploadInput.emit(event);
    };
    CreateWorkOrderComponent.prototype.cancelUpload = function (id) {
        this.uploadInput.emit({ type: 'cancel', id: id });
    };
    CreateWorkOrderComponent.prototype.onUploadOutput = function (output) {
        if (output.type === 'allAddedToQueue') {
        }
        else if (output.type === 'addedToQueue') {
            this.files.push(output.file); // add file to array when added
            this.addImgUrl(output.file.nativeFile, output.file.name);
        }
        else if (output.type === 'uploading') {
            // update current data in files array for uploading file
            var index = this.files.findIndex(function (file) { return file.id === output.file.id; });
            this.files[index] = output.file;
        }
        else if (output.type === 'removed') {
            // remove file from array when removed
            this.files = this.files.filter(function (file) { return file !== output.file; });
        }
        else if (output.type === 'dragOver') {
            //this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
        }
        else if (output.type === 'drop') {
            // this.dragOver = false;
        }
    };
    CreateWorkOrderComponent.prototype.makeid = function () {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 5; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    };
    CreateWorkOrderComponent.prototype.onNewImage = function (newImg) {
        this.addImgUrl(newImg, this.makeid());
    };
    CreateWorkOrderComponent.prototype.addImgUrl = function (file, filename) {
        if (file == undefined)
            return;
        /*
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          var problemPhoto = new ProblemPhoto();
          problemPhoto.base64 = reader.result;
          problemPhoto.name = filename;
          this.problemPhotos.push(problemPhoto);
        }
        */
        var problemPhoto = new ProblemPhoto();
        problemPhoto.base64 = file;
        problemPhoto.name = filename;
        this.problemPhotos.push(problemPhoto);
    };
    CreateWorkOrderComponent.prototype.removeImagUrl = function (name) {
        this.problemPhotos = this.problemPhotos.filter(function (i) { return i.name !== name; });
    };
    return CreateWorkOrderComponent;
}());
export { CreateWorkOrderComponent };
