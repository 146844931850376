import { Component, OnInit, EventEmitter, ViewChild, ElementRef, HostListener, Input } from '@angular/core';
import { ProblemLogService } from 'src/app/services/problem-log.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { IMyOptions, MDBSpinningPreloader, UploadFile, UploadInput, UploadOutput, ToastService, MDBDatePickerComponent } from 'ng-uikit-pro-standard';

import { StaffService } from 'src/app/services/staff.service';
import { AssignTaskService } from 'src/app/services/assign-task.service';
import { AuthService } from 'src/app/services/auth-service.service';
import { CancelTaskService } from 'src/app/services/cancel-task.service';
import { PickTaskService } from 'src/app/services/pick-Task.service';
import { ProblemTypeService } from 'src/app/services/problem-type.service';
import { DepartmentService } from 'src/app/services/department.service';
import { ProblemAreaService } from 'src/app/services/problem-area.service';
import { CompleteTaskService } from 'src/app/services/complete-task.service';
import { LocalDateService } from 'src/app/services/local-date.service';

import { WeChatDepartmentUser } from 'src/app/models/wechatDepartmentUser';
import { User } from 'src/app/models/User';
import { AssignTaskForm } from 'src/app/models/assignTaskForm';
import { CancelTaskForm } from 'src/app/models/cancelTaskForm';
import { ProblemLogListItem } from 'src/app/models/problemLogListItem';
import { PickTaskForm } from 'src/app/models/pickTaskForm';

import { ProblemType } from 'src/app/models/problemType';
import { Department } from 'src/app/models/department';
import { Problem } from 'src/app/models/problem';
import { ProblemArea } from 'src/app/models/problemArea';
import { ProblemPhoto } from 'src/app/models/problemPhoto';
import { CompleteTaskForm } from 'src/app/models/completeTaskForm';
import { ProblemLogSearchForm } from 'src/app/models/problemLogSearchForm';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss']
})

export class InboxComponent implements OnInit {
  @ViewChild('problemLogElement') problemLogElements: any;
  @ViewChild('showMapModal') showMapModal: any;
  @ViewChild('assignModal') assignModal: any;
  @ViewChild('cancelModal') cancelModal: any;
  @ViewChild('pickModal') pickModal: any;
  @ViewChild('completeModal') completeModal: any;
  @ViewChild("completeDatepicker") datePicker: any;
  @ViewChild('sidenav') sidenav: any;
  @ViewChild('assignDatePicker') assignDatePicker: MDBDatePickerComponent;

  myDatePickerOptions: IMyOptions;
  isLoadingMore: boolean = false;
  files: UploadFile[] = [];
  completePhotos: ProblemPhoto[] = [];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  @Input() currentUser: User;
  @Input() problemLogs: ProblemLogListItem[] = [];
  selectedProblemLogItem: ProblemLogListItem = new ProblemLogListItem();
  staffs: WeChatDepartmentUser[] = [];
  filteredStaffs: Observable<WeChatDepartmentUser[]>;
  assignTaskFormGroup: FormGroup;
  cancelTaskFormGroup: FormGroup;
  pickTaskFormGroup: FormGroup;
  completeTaskFormGroup: FormGroup;
  searchFormGroup: FormGroup;
  problemTypes: ProblemType[];
  departments: Department[];
  problems: Problem[];
  problemAreas: ProblemArea[];
  isSidNavFirstOpen: boolean = false;
  queryMoreLastId: number
  showUserOnly: boolean;
  infiniteScrollDisable: boolean = true;
  toastOption: any = { positionClass: 'toast-center' };
  statuses: any[] = [
    { label: '分派中', value: 'PendingAssign' },
    { label: '接收中', value: 'PendingPick' },
    { label: '工作中', value: 'Working' },
    { label: '已完成', value: 'Completed' },
    { label: '已取消', value: 'Cancelled' }
  ];
  overudes: any[] = [
    { name: '>2d', start: 0, end: 2 },
    { name: '3-7d', start: 3, end: 7 },
    { name: '8-14d', start: 8, end: 14 },
    { name: '>14d', start: 15, end: 999 }
  ];
  mapIframeSrc: string = "blank";
  startDate: string;

  constructor(
    private problemLogService: ProblemLogService,
    private authService: AuthService,
    private fb: FormBuilder,
    private staffService: StaffService,
    private assignTaskService: AssignTaskService,
    private pickTaskService: PickTaskService,
    private cancelTaskService: CancelTaskService,
    private problemTypeService: ProblemTypeService,
    private departmentService: DepartmentService,
    private problemAreaService: ProblemAreaService,
    private completeTaskService: CompleteTaskService,
    private localDateSerivce: LocalDateService,
    private preLoader: MDBSpinningPreloader,
    private toastService: ToastService

  ) {
    var today = new Date();
    var month = today.getMonth() + 1 < 9 ? "0" + (today.getMonth() + 1).toString() : today.getMonth().toString();
    var day = today.getDate() < 9 ? "0" + today.getDate().toString() : today.getDate().toString();
    var year = today.getFullYear();
    this.startDate = day + "-" + month + "-" + year
    this.myDatePickerOptions = {
      dateFormat: "dd-mm-yyyy",
      startDate: day + "-" + month + "-" + year,
      disableUntil: { year: year, month: today.getMonth() + 1, day: today.getDate() - 1 },
      showClearDateBtn: false
    };
  }



  hoursLater(h:number):string {    
    var d = new Date();
   
    d.setTime(d.getTime() + (h*60*60*1000)); 
    var newDate = d.getHours() +":"+d.getMinutes(); 
    return newDate;   
  }
  async ngOnInit() {
    this.preLoader.start();
    this.infiniteScrollDisable = true;
    this.sidenav.hide();
    this.createFormGroups();
    await this.loadFormData();
    this.firstQuerySubmit();
  }

  createFormGroups() {
    this.completeTaskFormGroup = this.fb.group({
      remark: ['']
    })
    this.searchFormGroup = this.fb.group({
      department: [''],
      problemType: [''],
      problem: [''],
      freeText: [''],
      overdue: [''],
      status: [''],
      workerId: ['']
    });

    
    
    this.assignTaskFormGroup = this.fb.group({
      worker: ['', Validators.required],
      targetCompleteDate: ['', Validators.required],
      targetCompleteTime: [ this.hoursLater(3) , Validators.required],
    });
    this.cancelTaskFormGroup = this.fb.group({
      reason: ['']
    });
    this.pickTaskFormGroup = this.fb.group({

    });
  }
  async loadFormData() {
    await this.getCurrentUser();
    await this.getProblemTypes();
    await this.getDepartments();
    this.getProblemAreas();
  }
  get pullToRefreshStyle() {
    return {height: window.innerHeight - 100 + 'px'};
  }
  getCurrentUser() {
    return new Promise(resolve => {
      this.currentUser = this.authService.currentUserValue;
      resolve();
    })
  }
  getDepartments() {
    return new Promise(resolve => {
      this.departmentService.getAll(this.currentUser.userId).then(x => {
        this.departments = x.departments
        resolve();
      })
    })
  }
  getProblemTypes() {
    return new Promise(resolve => {
      this.problemTypeService.getAll().then(response => {
        this.problemTypes = response.problemTypes;
        resolve();
      });
    })

  }

  currentUserCanCancel() {
    return this.currentUser.isWorkingGroupUser;
  }
  currentUserCanAssign() {
    return this.currentUser.isWorkingGroupUser;
  }
  getLocalDate(date: string): string {
    return this.localDateSerivce.ToLocalDateString(date);
  }
  sideNavToggle() {
    this.sidenav.toggle();
  }
  selectedProblemType(pt: ProblemType) {
    this.problems = pt.problems;
  }
  getProblemAreas(): void {
    this.problemAreaService.getAll().then(response => this.problemAreas = response.problemAreas)
  }
  showMapClicked(pl: ProblemLogListItem) {
    this.selectedProblemLogItem = pl;
    this.mapIframeSrc = pl.mapIframeSrc;
    this.showMapModal.show();
  }
  onCloseMap() {
    this.showMapModal.hide();
    this.mapIframeSrc = "blank";
  }

  async assignClicked(pl: ProblemLogListItem) {
    this.filteredStaffs = new Observable<WeChatDepartmentUser[]>();
    this.assignTaskFormGroup.reset();
    this.assignTaskFormGroup.patchValue({ targetCompleteDate: this.startDate });
    this.assignTaskFormGroup.patchValue({ targetCompleteTime: this.hoursLater(3) });

    this.selectedProblemLogItem = pl;
    await this.getStaffs();
    var workerCtrl = this.assignTaskFormGroup.get('worker');
    this.filteredStaffs = workerCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value == undefined ? '': value.name),
        map(name => name ? this._filter(name) : this.staffs.slice())
      );
    this.assignModal.show();

  }
  displayFn(user?: User): string | undefined {
    return user ? user.name : undefined;
  }

  private _filter(name: string): WeChatDepartmentUser[] {
    const filterValue = name.toLowerCase();

    return this.staffs.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  cancelClicked(pl: ProblemLogListItem) {
    this.cancelTaskFormGroup.reset();
    this.selectedProblemLogItem = pl;
    this.cancelModal.show();
  }
  completeClicked(pl: ProblemLogListItem) {
    this.completeTaskFormGroup.reset();
    this.selectedProblemLogItem = pl
    this.completeModal.show();
  }
  pickClicked(pl: ProblemLogListItem) {
    this.selectedProblemLogItem = pl;
    this.pickModal.show();
  }
  cancelSubmit() {

    var cancelTaskForm = new CancelTaskForm();

    cancelTaskForm.createdBy = this.currentUser.userId;
    cancelTaskForm.reason = this.cancelTaskFormGroup.value.reason;
    cancelTaskForm.problemLogId = this.selectedProblemLogItem.problemLogId;

    this.cancelTaskService.Save(cancelTaskForm).subscribe(response => {
      if (response.errcode == 0) {
        var index = this.problemLogs.findIndex(x => x.problemLogId == cancelTaskForm.problemLogId);
        this.problemLogs[index] = response.problemLogListItem;
        this.toastService.success("储存成功", '', this.toastOption);
      }
      if (response.errcode > 0) {
        this.toastService.error("储存失败 " + response.errmsg, '', this.toastOption);
      }
    });
    this.cancelModal.hide();
  }
  pickSubmit() {
    var pickTaskForm = new PickTaskForm();

    pickTaskForm.createdBy = this.currentUser.userId;
    pickTaskForm.workerId = this.currentUser.userId;
    pickTaskForm.problemLogId = this.selectedProblemLogItem.problemLogId;

    this.pickTaskService.Save(pickTaskForm).subscribe(response => {
      if (response.errcode == 0) {
        var index = this.problemLogs.findIndex(x => x.problemLogId == pickTaskForm.problemLogId);
        this.problemLogs[index] = response.problemLogListItem;
        this.toastService.success("储存成功", '', this.toastOption);
      }
      if (response.errcode > 0) {
        this.toastService.error("储存失败 " + response.errmsg, '', this.toastOption);
      }
    });
    this.pickModal.hide();
  }
  assignSubmit() {
    for (const i in this.assignTaskFormGroup.controls) {
      this.assignTaskFormGroup.controls[i].markAsDirty();
      this.assignTaskFormGroup.controls[i].updateValueAndValidity();
    }
    if (!this.assignTaskFormGroup.valid)
      return;
    var assignTaskForm = new AssignTaskForm();

    assignTaskForm.createdBy = this.currentUser.userId;
    assignTaskForm.targetCompleteDate = this.assignTaskFormGroup.value.targetCompleteDate;
    assignTaskForm.targetCompleteTime = this.assignTaskFormGroup.value.targetCompleteTime;
    assignTaskForm.workerId = this.assignTaskFormGroup.value.worker.userid;
    assignTaskForm.problemLogId = this.selectedProblemLogItem.problemLogId;

    this.assignTaskService.Save(assignTaskForm).subscribe(response => {
      if (response.errcode == 0) {
        var index = this.problemLogs.findIndex(x => x.problemLogId == assignTaskForm.problemLogId);
        this.problemLogs[index] = response.problemLogListItem;
        this.toastService.success("储存成功", '', this.toastOption);
      }
      if (response.errcode > 0) {
        this.toastService.error("储存失败 " + response.errmsg, '', this.toastOption);
      }

    });

    this.assignModal.hide();
  }
  completeSubmit() {
    var completeTaskForm = new CompleteTaskForm();

    completeTaskForm.createdBy = this.currentUser.userId;
    completeTaskForm.problemLogId = this.selectedProblemLogItem.problemLogId;
    completeTaskForm.remark = this.completeTaskFormGroup.value.remark;
    completeTaskForm.completePhotos = this.completePhotos;

    this.completeTaskService.Save(completeTaskForm).subscribe(response => {
      if (response.errcode == 0) {
        var index = this.problemLogs.findIndex(x => x.problemLogId == completeTaskForm.problemLogId);
        this.problemLogs[index] = response.problemLogListItem;
        this.toastService.success("储存成功", '', this.toastOption);
      }
      if (response.errcode > 0) {
        this.toastService.error("储存失败 " + response.errmsg, '', this.toastOption);
      }

    });

    this.completeModal.hide();
  }

  private getStaffs() {
    return new Promise(resolve => {
      this.staffService.getByDepartment(this.selectedProblemLogItem.departmentId).subscribe(response => {
        this.staffs = response.departmentUsers;
        resolve();
      })
    })

  }

  getProgressStyle(num: number) {
    return { width: num + '%' };
  }
  get ifarmHeight(): number {
    var minHehgit = 230;
    var height = window.innerWidth / 3;
    return height < minHehgit ? minHehgit : height;
  }
  restoreSearchForm(searchForm: ProblemLogSearchForm) {

    if (searchForm.departmentId > 0) {
      var d = this.departments.find(x => x.id == searchForm.departmentId);

      this.searchFormGroup.patchValue({ department: d });
    }

    if (searchForm.workerId != undefined && searchForm.workerId.length > 0) {
      this.searchFormGroup.patchValue({ workerId: searchForm.workerId });
    }

    if (searchForm.problemTypeId > 0) {
      var pt = this.problemTypes.find(x => x.problemTypeId == searchForm.problemTypeId);
      this.searchFormGroup.patchValue({ problemType: pt });
    }

    if (searchForm.wfStatus != undefined && searchForm.wfStatus.length > 0) {
      this.searchFormGroup.patchValue({ status: searchForm.wfStatus });
    }

    if (searchForm.overDueEndDay == 2) {
      this.searchFormGroup.patchValue({ overdue: this.overudes[0]}); 
    }

    if (searchForm.overDueEndDay == 7) {
      this.searchFormGroup.patchValue({ overdue: this.overudes[1] }); 
    }

    if (searchForm.overDueStartDay == 14) {
      this.searchFormGroup.patchValue({ overdue: this.overudes[2] }); 
    }
    if (searchForm.overDueEndDay == 999) {
      this.searchFormGroup.patchValue({ overdue: this.overudes[3] }); 

    }
  }
  firstQuerySubmit() {
    var searchForm = this.problemLogService.GetSearchForm();

    if (searchForm.fromDashboards) {
      this.restoreSearchForm(searchForm);
      searchForm.fromDashboards = false;
      this.sendQuery(searchForm);
    }
    else {
      if (this.currentUser.isWorkingGroupUser) {
        this.showUserOnly = false;
      } else {
        this.showUserOnly = true;
      }
      this.QueryByShowUserOnly();
    }


  }

  createUserOnlySearchForm() {
    var searchForm = new ProblemLogSearchForm();
    searchForm.workerId = this.currentUser.userId;
    searchForm.wfStatus = ['PendingAssign', 'PendingPick', 'Working'];
    searchForm.lastItemId = 0;
    searchForm.take = 5;
    searchForm.departmentId = 0;

    return searchForm;
  }
  createDepartmentOnlySearchForm() {
    var searchForm = new ProblemLogSearchForm();
    searchForm.departmentId = this.currentUser.departmentId;
    searchForm.workerId = "";
    searchForm.lastItemId = 0;
    searchForm.take = 5;
    return searchForm
  }
  showUserOnlyToggle(value) {
    this.showUserOnly = !this.showUserOnly;
    this.QueryByShowUserOnly();
  }
  QueryByShowUserOnly() {
    this.searchFormGroup.reset();
    var searchForm = this.showUserOnly ? this.createUserOnlySearchForm() : this.createDepartmentOnlySearchForm();
    this.restoreSearchForm(searchForm);
    this.sendQuery(searchForm);
  }
  sendQuery(searchForm: ProblemLogSearchForm) {
    this.preLoader.start();
    this.problemLogService.SetSearchForm(searchForm);

    this.problemLogService.Search().subscribe(response => {
      if (response.errcode == 0) {
        this.queryMoreLastId = response.problemLogListItems.length == 0 ? this.queryMoreLastId = 0
          : this.queryMoreLastId = response.problemLogListItems[response.problemLogListItems.length - 1].problemLogId;

        this.problemLogs = response.problemLogListItems;
      }
      this.infiniteScrollDisable = false;
      window.scroll(0, 0);
      this.preLoader.stop();
    })
  }
  resetSearch() {
    this.searchFormGroup.reset();
    var searchForm = new ProblemLogSearchForm();
    this.problemLogService.SetSearchForm(searchForm);
    this.querySubmit();
  }

  querySubmit() {
    var searchForm = new ProblemLogSearchForm();
    searchForm.freeText = this.searchFormGroup.get('freeText').value == '' || this.searchFormGroup.get('freeText').value == undefined ? ''
      : this.searchFormGroup.get('freeText').value;
    searchForm.departmentId = this.searchFormGroup.get('department').value == '' || this.searchFormGroup.get('department').value == undefined ?
      0 : this.searchFormGroup.get('department').value.id;
    searchForm.overDueStartDay = this.searchFormGroup.get('overdue').value == '' || this.searchFormGroup.get('overdue').value == undefined ?
      0 : this.searchFormGroup.get('overdue').value.start;
    searchForm.overDueEndDay = this.searchFormGroup.get('overdue').value == '' || this.searchFormGroup.get('overdue').value == undefined ?
      0 : this.searchFormGroup.get('overdue').value.end;
    searchForm.problemId = this.searchFormGroup.get('problem').value == '' || this.searchFormGroup.get('problem').value == undefined ?
      0 : this.searchFormGroup.get('problem').value.problemId;
    searchForm.problemTypeId = this.searchFormGroup.get('problemType').value == '' || this.searchFormGroup.get('problemType').value == undefined ?
      0 : this.searchFormGroup.get('problemType').value.problemTypeId;
    searchForm.wfStatus = this.searchFormGroup.get('status').value == '' || this.searchFormGroup.get('status').value == undefined ?
      [] : this.searchFormGroup.get('status').value;
    searchForm.workerId = this.searchFormGroup.get('workerId').value == '' || this.searchFormGroup.get('workerId').value == undefined ?
      '' : this.searchFormGroup.get('workerId').value;
    searchForm.lastItemId = 0;
    searchForm.take = 5;

    this.sendQuery(searchForm);
    this.sidenav.hide();
  }

  queryMore() {
    var searchForm = this.problemLogService.GetSearchForm();
    searchForm.lastItemId = this.queryMoreLastId;
    searchForm.take = 5;
    this.problemLogService.SetSearchForm(searchForm);
    this.problemLogService.Search().subscribe(response => {
      if (response.errcode == 0) {
        if (response.problemLogListItems.length > 0) {
          this.problemLogs = [...this.problemLogs, ...response.problemLogListItems];
          this.queryMoreLastId = response.problemLogListItems[response.problemLogListItems.length - 1].problemLogId;
        }
      }
    })
    this.sidenav.hide();
  }
  touchStarting: boolean;
  touchStartY: number;
  touchMoveY: number;
  touchEnding: boolean;
  get showRefresherHeader() {
    var showRefresher = this.touchStarting && this.touchStartY < 500 && (this.touchMoveY - this.touchStartY > 100);

    return showRefresher
  }
  get refresherState() {
    if (this.showRefresherHeader && !this.touchEnding)
      return '松开立即刷新...';

    if (this.showRefresherHeader && this.touchEnding)
      return '刷新中...';
  }
  @HostListener('touchstart', ['$event'])
  touchStart(e) {
    this.touchStarting = true;
    this.touchStartY = e.touches[0].screenY;
  }
  @HostListener('touchmove', ['$event'])
  touchMove(e) {
    this.touchMoveY = e.touches[0].screenY;
  }
  @HostListener('touchend', ['$event'])
  touchEnd(e) {
    this.touchEnding = true;
    if (this.showRefresherHeader) {

      this.querySubmit();
      setTimeout(() => {
        this.touchStarting = false;
        this.touchEnding = false;
        this.touchStartY = 0;
        this.touchMoveY = 0;
      }, 2000)
    } else {
      this.touchStartY = 0;
      this.touchMoveY = 0;
    }

  }

  onUploadOutput(output: UploadOutput | any): void {

    if (output.type === 'allAddedToQueue') {
    } else if (output.type === 'addedToQueue') {
      this.files.push(output.file); // add file to array when added
      this.addImgUrl(output.file.nativeFile, output.file.name);
    } else if (output.type === 'uploading') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      //this.dragOver = true;
    } else if (output.type === 'dragOut') {
    } else if (output.type === 'drop') {
      // this.dragOver = false;
    }

  }

  makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  onNewImage(newImg: Blob) {
    this.addImgUrl(newImg, this.makeid());
  }


  public addImgUrl(file: Blob, filename: string) {

    if (file == undefined)
      return;
    //var reader = new FileReader();
    //reader.readAsDataURL(file);
    //reader.onload = (_event) => {
    var completePhoto = new ProblemPhoto();
    //completePhoto.base64 = reader.result;
    completePhoto.base64 = file;
    completePhoto.name = filename;
    this.completePhotos.push(completePhoto);
    //}
  }

  public removeImagUrl(name: string) {
    this.completePhotos = this.completePhotos.filter((i: any) => i.name !== name);
  }

  showFiles() {
    //return `總共 ${this.files.length} 檔案`;
    return `总共 ${this.completePhotos.length} 档案`;
  }

  /*
    public addImgUrl(file: Blob, filename: string) {
  
      if (file == undefined)
        return;
  
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        var completePhoto = new ProblemPhoto();
        completePhoto.base64 = reader.result;
        completePhoto.name = filename;
        this.completePhotos.push(completePhoto);
      }
    }
    showFiles() {
      return `总共 ${this.files.length} 档案`;
    }
    */
}
