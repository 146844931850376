import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth-service.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth-service.service";
var StaffService = /** @class */ (function () {
    function StaffService(http, authService) {
        this.http = http;
        this.authService = authService;
    }
    StaffService.prototype.getByDepartment = function (departmentId) {
        var url = "api/Staff?departmentId=" + departmentId;
        return this.http.get(url);
    };
    StaffService.ngInjectableDef = i0.defineInjectable({ factory: function StaffService_Factory() { return new StaffService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: StaffService, providedIn: "root" });
    return StaffService;
}());
export { StaffService };
