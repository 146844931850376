
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { CustomHammerConfig } from './hammerConfig';
import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule, SidenavModule, WavesModule, ChartsModule, ChartSimpleModule } from 'ng-uikit-pro-standard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule} from '@angular/material/stepper';
import { MatDividerModule} from '@angular/material/divider';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AuthInterceptor } from 'src/app/services/auth.interceptor';
import { AppComponent, SafePipe } from './app.component';
import { AppConfig } from './app.config';
import { InboxComponent } from './views/inbox/inbox.component';
import { FooterComponent } from './main-layout/footer/footer.component';
import { DashboardsComponent } from './views/dashboards/dashboards.component';
import { WorkOrderMapComponent } from './views/work-order-map/work-order-map.component';
import { NavigationComponent } from './main-layout/navigation/navigation.component';
import { CreateWorkOrderComponent } from './views/create-work-order/create-work-order.component';
import { InnerMapComponent } from './views/inner-map/inner-map.component';
import { BlankViewComponent } from './views/blank-view/blank-view.component';
import { ProblemLogDetailComponent } from './views/problem-log-detail/problem-log-detail.component';
import { AddImageDialogComponent, CropImageDialog } from './views/add-image-dialog/add-image-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';

import { LyThemeModule, LY_THEME } from '@alyle/ui';
import { MinimaLight } from '@alyle/ui/themes/minima';
import { CropimageComponent } from './views/cropimage/cropimage.component';
import { LyResizingCroppingImageModule } from '@alyle/ui/resizing-cropping-images';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyDialogModule } from '@alyle/ui/dialog';
import { LyGridModule } from '@alyle/ui/grid';
import { LyTypographyModule } from '@alyle/ui/typography';
import { DrawCanvasComponent } from './views/draw-canvas/draw-canvas.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';





//export function initializeApp(appConfig: AppConfig) {
//  return () => appConfig.load();
//}
export function initializeApp() {
  return () => { }
}

@NgModule({
  declarations: [
    AppComponent,
    SafePipe,
    FooterComponent,
    DashboardsComponent,
    WorkOrderMapComponent,
    NavigationComponent,
    InboxComponent,
    CreateWorkOrderComponent,
    InnerMapComponent,
    BlankViewComponent,
    ProblemLogDetailComponent,
    CropimageComponent,
    DrawCanvasComponent,
    AddImageDialogComponent,
    CropImageDialog,
    UnauthorizedComponent,
  ],
  entryComponents: [CropImageDialog],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    FormsModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    MatIconModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatStepperModule,
    MatDividerModule,
    SidenavModule, 
    WavesModule, 
    InfiniteScrollModule,
    LyThemeModule.setTheme('minima-light'),
    LyResizingCroppingImageModule,
    LyButtonModule,
    LyIconModule,
    LyDialogModule,
    LyGridModule,
    LyTypographyModule,
    ImageCropperModule,
    MatButtonToggleModule,
    ChartsModule,
    ChartSimpleModule,
    MatAutocompleteModule,
    MatSlideToggleModule
  ],
  exports: [ CreateWorkOrderComponent, CropimageComponent, DrawCanvasComponent, AddImageDialogComponent],
  providers: [
    MDBSpinningPreloader, 
    AppConfig, 
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig},
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi:true}
  ],
  bootstrap: [AppComponent],
  schemas:      [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
