<div *ngIf="problemLogDetail" class="iphonex-height">
  <section class="mt-lg-5 mt-4">
    <div *ngIf="showRefresherHeader" class="col-12">
      <div style="text-align:center">{{refresherState}}</div>
    </div>
    <div class="card card-cascade cascading-admin-card" style="margin-bottom:30px;">
      <div class="admin-up">
        <div class="row">
          <div class="col-xs-3">
            <mdb-icon fas icon="{{problemLogDetail.problemTypeIconCss}}" class="primary-color" style="margin-bottom:10px"></mdb-icon>
          </div>
          <div class="col" style="padding-top:25px;">
            <span>{{problemLogDetail.displayHeader}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mdb-carousel [animation]="'slide'" [isControls]="true" #carousel [interval]="'0'">
              <mdb-carousel-item *ngFor="let i of problemLogDetail.problemLogPhotos">
                <img class="d-block w-100" [src]="i.imageSrc">
              </mdb-carousel-item>
            </mdb-carousel>
          </div>
        </div>

        <div class="row justify-content-start mx-1">
          <div *ngIf="problemLogDetail.status == 'PendingPick' && currentUser.userId == problemLogDetail.workerId" class="col-3 p-1">
            <button (click)="pickClicked()" type="button" class="btn btn-success btn-block">
              <i placement="top" mdbWavesEffect class="far fa-check-circle fa-1x"></i>接收
            </button>
          </div>

          <div *ngIf="problemLogDetail.status == 'Working' && currentUser.userId == problemLogDetail.workerId" class="col-3 p-1">
            <button (click)="completeClicked()" type="button" class="btn btn-success btn-block">
              <i placement="top" mdbWavesEffect class="far fa-check-circle fa-1x"></i>完成
            </button>
          </div>

          <div *ngIf="currentUserCanCancel() && problemLogDetail.status !='Completed' && problemLogDetail.status !='Cancelled'" class="col-3 p-1">
            <button type="button" (click)="cancelClicked()" class="btn btn-danger btn-block">
              <i placement="top" mdbWavesEffect class="fas fa-ban fa-1x"></i> 取消
            </button>
          </div>
          <div *ngIf="currentUserCanAssign() && problemLogDetail.status != 'Completed' && problemLogDetail.status !='Cancelled'" class="col-3 p-1">
            <button type="button" (click)="assignClicked()" class="btn btn-primary btn-block">
              <i placement="top" mdbWavesEffect class="fas fa-child fa-1x"></i> 指派
            </button>
          </div>
          <div class="col-3 p-1">
            <button type="button" (click)="showMapClicked()" class="btn btn-info btn-block">
              <i placement="top" mdbWavesEffect class="fas fa-map-marked-alt fa-1x"></i> 地图
            </button>
          </div>
        </div>
        <div class="data">
          <p *ngIf="!problemLogDetail.targetCompleteDate || problemLogDetail.targetCompleteDate == null"> 创建日期: {{getLocalDate(problemLogDetail.createdDate)}}</p>
          <p *ngIf="problemLogDetail.targetCompleteDate && problemLogDetail.status != 'Completed'"> 预计完成日期: {{getLocalDate(problemLogDetail.targetCompleteDate)}}</p>
          <P *ngIf="problemLogDetail.status== 'Completed'">完成日期: {{getLocalDate(problemLogDetail.completeDate)}}</P>
          <h4><strong>{{problemLogDetail.displayProgressText}}</strong></h4>
        </div>
        <div class="problem-detail-description">
          {{problemLogDetail.description}}
        </div>
      </div>
      <div class="card-body">
        <div class="progress">
          <div [ngClass]="problemLogDetail.progressStatusCss" role="progressbar" [ngStyle]="getProgressStyle(problemLogDetail.progress)"
               aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <p class="card-text">{{problemLogDetail.displayTotalDaysText}}</p>

        <p *ngIf="problemLogDetail.workerName" style="text-align:right" class="card-text">{{problemLogDetail.departmentName}}/{{problemLogDetail.workerName}}</p>
        <p *ngIf="!problemLogDetail.workerName" style="text-align:right" class="card-text">{{problemLogDetail.departmentName}}</p>
      </div>
    </div>
  </section>


<section>
  <div class="card card-cascade narrower">
    <section>
      <div class="row mb-4">
        <div class="col-md-12 mr-0">
          <div class="view view-cascade gradient-card-header blue-gradient">
            <h2 class="h2-responsive mb-0">任务流程</h2>
          </div>
          <div class="card-body pb-0">
            <div class="card-body card-body-cascade pt-2" style="padding-bottom:0.5rem" *ngFor="let wf of problemLogDetail.workflowItems">
              <div class="row">
                <div class="col-8 card-text" style="font-size:0.8rem;">
                  <img style="width:40px;height:40px;margin-right:3px" [src]="wf.avatar" class="rounded-circle z-depth-0">
                  {{wf.actionUserName}}
                </div>
                <div class="col align-self-end" style="text-align:right;font-weight:400;">
                  {{wf.statusText}}
                </div>
              </div>
              <div class="row">
                <div class="col" style="text-align:center;padding-bottom:0.2rem;padding-top:0.5rem">{{wf.remark}}</div>
              </div>
              <div class="row">
                <div style="text-align:right; margin-bottom:0px!important" class="col align-self-end card-text">
                  开始: {{getLocalDate(wf.createdDate)}}
                </div>

                </div>
                <div class="row">
                  <div style="text-align:right; margin-bottom:0px!important" class="col align-self-end card-text">
                    结束: {{getLocalDate(wf.completedDate)}}
                  </div>

                </div>
                <hr style="margin-top:5px;" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>

<!--show map-->
<div mdbModal #showMapModal="mdbModal" [config]="{ignoreBackdropClick:true}" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal" role="document">
    <div class="modal-content">
      <div class="modal-header light-blue darken-3 white-text">
        <h4 class="title">
          <i class="fas fa-users"></i>任务位置
        </h4>
        <span class="title">{{problemLogDetail.displayHeader}}</span>
      </div>
      <div class="modal-body">
        <iframe [src]="mapsrc | safe" [attr.height]="ifarmHeight" frameborder="0"
                style="width:100%;overflow:hidden"></iframe>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="primary" class="waves-light" (click)="showMapModal.hide()" mdbWavesEffect>
          离开
          <i class="far fa-check-circle"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<!--show map-->
<!--assign task-->
<div mdbModal #assignModal="mdbModal" [config]="{ignoreBackdropClick:true}" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal" role="document">
    <div class="modal-content">
      <div class="modal-header light-blue darken-3 white-text">
        <h4 class="title">
          <i class="fas fa-users"></i>指派任务
        </h4>
        <span class="title">{{problemLogDetail.displayHeader}}</span>
      </div>
      <form [formGroup]="assignTaskFormGroup">
        <div class="modal-body mx-3">
          <div class="md-form md-5" style="margin-bottom:0px;">
            <i class="fas fa-pencil-alt prefix grey-text"></i>
            <mat-form-field style="margin-left:40px;width:80%;">
              <mat-label>负责员工</mat-label>
              <input type="text" placeholder="负责员工" aria-label="负责员工" matInput [matAutocomplete]="auto" formControlName="worker" required>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let s of filteredStaffs | async" [value]="s">
                  {{s.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="md-form md-5" style="width:80%">
            <i class="far fa-calendar-check prefix grey-text"></i>
            <mdb-date-picker #assignDatePicker mdbValidate style="margin-left:40px" name="mydate" [options]="myDatePickerOptions"
                             formControlName="targetCompleteDate" [placeholder]="'预计完成日期'" required>
            </mdb-date-picker>
            <mdb-error *ngIf="assignDatePicker.invalid && (assignDatePicker.dirty || assignDatePicker.touched)">Input invalid</mdb-error>
            <mdb-success *ngIf="assignDatePicker.valid && (assignDatePicker.dirty || assignDatePicker.touched)">Input valid</mdb-success>
          </div>
          <div class="md-form md-5" style="width:80%">
            <i class="far fa-clock prefix grey-text"></i>
            <mdb-time-picker #assignTimePicker mdbValidate [buttonLabel]="'Done'" [twelvehour]="false" [darktheme]="false" [placeholder]="'完成时间'"
                             formControlName="targetCompleteTime" required></mdb-time-picker>
            <mdb-error *ngIf="assignTimePicker.invalid && (assignTimePicker.dirty || assignTimePicker.touched)">Input invalid</mdb-error>
            <mdb-success *ngIf="assignTimePicker.valid && (assignTimePicker.dirty || assignTimePicker.touched)">Input valid</mdb-success>
          </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button mdbBtn color="grey" data-dismiss="mdbModal" (click)="assignModal.hide()" class="waves-light"
                    mdbWavesEffect>
              取消
              <i class="fas fa-times ml-1"></i>
            </button>
            <button type="submit" mdbBtn color="green" class="waves-light" (click)="assignSubmit()" mdbWavesEffect>
              完成
              <i class="far fa-paper-plane ml-1"></i>
            </button>
          </div>
        </form>

    </div>
  </div>
</div>
<!--assign task-->
<!--Cancel Task-->
<div mdbModal #cancelModal="mdbModal" [config]="{ignoreBackdropClick:true}" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content text-center">
      <!--Header-->
      <div class="modal-header d-flex justify-content-center">
        <p class="heading">确定取消?</p>
      </div>

        <!--Body-->
        <form [formGroup]="cancelTaskFormGroup">
          <div class="modal-body">
            <i class="fas fa-times fa-4x animated rotateIn"></i>
            <div class="md-form mb-5">
              <mat-form-field>
                <textarea matInput rows="3" formControlName="reason" placeholder="原因"></textarea>
              </mat-form-field>
            </div>
          </div>
        </form>


        <!--Footer-->
        <div class="modal-footer justify-content-center">
          <a style="width:70px" type="button" mdbBtn color="danger" class="waves-effect" mdbWavesEffect
             data-dismiss="modal" (click)="cancelModal.hide()">否</a>
          <a style="width:70px" type="button" mdbBtn color="danger" outline="true" class="waves-effect" mdbWavesEffect
             (click)="cancelSubmit()">是</a>

      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Cancel Task-->
<!--Pick Task-->
<div mdbModal #pickModal="mdbModal" [config]="{ignoreBackdropClick:true}" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-notify modal-success" role="document">
    <!--Content-->
    <div class="modal-content text-center">
      <!--Header-->
      <div class="modal-header d-flex justify-content-center">
        <p class="heading">确定接收任务?</p>
      </div>

      <form>
        <div class="modal-body">
          <i class="fas fa-check fa-4x"></i>
          <p>{{problemLogDetail.displayHeader}}</p>
          <p>预计完成日期: {{getLocalDate(problemLogDetail.targetCompleteDate)}}</p>
        </div>
      </form>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a style="width:70px" type="button" mdbBtn color="success" class="waves-effect" mdbWavesEffect
           data-dismiss="modal" (click)="pickModal.hide()">否</a>
        <a style="width:70px" type="button" mdbBtn color="success" outline="true" class="waves-effect"
           (click)="pickSubmit()" mdbWavesEffect>是</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Pick Task-->
<!--Complete Task-->
<div mdbModal #completeModal="mdbModal" [config]="{ignoreBackdropClick:true}" style="overflow:scroll" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal" role="document">
    <div class="modal-content">
      <div class="modal-header light-blue darken-3 white-text">
        <h4 class="title">
          <i class="fas fa-users"></i>完成任務
        </h4>
        <span class="title">{{problemLogDetail.displayHeader}}</span>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="completeModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="completeTaskFormGroup">
        <div class="modal-body mx-3">
          <div class="md-form mb-5">
            <i style="margin-left:15px;margin-top:24px;" class="fas fa-pencil-alt prefix grey-text"></i>
            <mat-form-field style="margin-left:75px;">
              <textarea matInput rows="3" formControlName="remark" placeholder="备注"></textarea>
            </mat-form-field>
          </div>
          <div class="md-form mb-5">
            <div class="file-field md-form">
              <add-image-dialog (addImage)="onNewImage($event)"></add-image-dialog>
              <div class="file-path-wrapper mt-3">
                <input class="file-path" type="text" placeholder="Upload your file" [value]="showFiles()">
              </div>
            </div>
          </div>
          <div class="md-form mb-5">
            <figure *ngFor="let photo of completePhotos" style="vertical-align:top;text-align:center;display: inline-block;">
                <div class="imageContainer">
                    <button class = "x" (click)="removeImagUrl(photo.name)">
                        X
                    </button>
                    <img [src]="photo.base64" style="margin: 0px;height:120px" caption>
                </div>
            </figure>
          </div>

          </div>
        </form>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="grey" data-dismiss="mdbModal" (click)="completeModal.hide()" class="waves-light"
                mdbWavesEffect>
          取消
          <i class="far fa-paper-plane ml-1"></i>
        </button>
        <button mdbBtn color="green" class="waves-light" (click)="completeSubmit()" mdbWavesEffect>
          完成
          <i class="far fa-paper-plane ml-1"></i>
        </button>

      </div>
      </div>
    </div>
  </div>
  <!--Complete Task-->
</div>


<app-navigation style="height:10%"></app-navigation>
