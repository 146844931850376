var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { LyHammerGestureConfig } from '@alyle/ui';
var CustomHammerConfig = /** @class */ (function (_super) {
    __extends(CustomHammerConfig, _super);
    function CustomHammerConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.overrides = {
            'pan': { direction: Hammer.DIRECTION_All },
            'swipe': { direction: Hammer.DIRECTION_VERTICAL },
        };
        return _this;
    }
    CustomHammerConfig.prototype.buildHammer = function (element) {
        var mc = new Hammer(element, {
            touchAction: 'auto',
            inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
            recognizers: [
                [Hammer.Swipe, {
                        direction: Hammer.DIRECTION_HORIZONTAL
                    }]
            ]
        });
        var pan = new Hammer.Pan();
        var swipe = new Hammer.Swipe();
        var slide = this.createRecognizer(pan, { event: 'slide', threshold: 0 });
        pan.recognizeWith(swipe);
        mc.add([swipe, pan, slide]);
        return mc;
    };
    CustomHammerConfig.prototype.createRecognizer = function (base, options) {
        var inheritances = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            inheritances[_i - 2] = arguments[_i];
        }
        var recognizer = new (base.constructor)(options);
        inheritances.push(base);
        inheritances.forEach(function (item) { return recognizer.recognizeWith(item); });
        return recognizer;
    };
    ;
    return CustomHammerConfig;
}(LyHammerGestureConfig));
export { CustomHammerConfig };
