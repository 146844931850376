
export class ProblemLogDetail {
  problemLogId: number;
  featureId: number;
  featureName: string;
  floorId: number;
  floorName: string;
  departmentId: number;
  description: string;
  problemId: number;
  problemAreaId: number;
  problemTypeId: number;
  problemLogPhotos: ProblemLogDetailPhoto[];
  status: string;
  statusText: string;
  displayDate: Date;
  progress: number
  displayDateText: string; //create date or completedate, it depends on status
  displayProgressText: string;
  displayTotalDaysText: string;
  displayHeader: string;
  mapIframeSrc: string = "/blank";
  problemTypeIconCss: string = "";
  departmentName: string;
  workerName: string;
  workflowItems: ProblemLogDetailWorkflowItem[];
  progressStatusCss:string;
  targetCompleteDate: Date;
  workerId:string;
  constructor() {
    this.problemLogPhotos = [];
  }
}
export class ProblemLogDetailWorkflowItem {
  createdBy: string;
  createdByName: string;
  createdDate: Date;
  status: string;
  statusText: string;
  problemLogWorkflowId: number;
  problemLogId: number;
  remark: string;
  avatar: string;
  completedDate: Date;
  actionUser: string;
  actionUserName: string;
  constructor() {
  }

}
export class ProblemLogDetailPhoto {
  problemPhototId: number;
  name: string;
  imageSrc: string;
}
