import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CancelTaskService = /** @class */ (function () {
    function CancelTaskService(http) {
        this.http = http;
    }
    CancelTaskService.prototype.Save = function (cancelTaskForm) {
        var url = "/api/ProblemLog/CancelTask";
        return this.http.post(url, cancelTaskForm);
    };
    CancelTaskService.ngInjectableDef = i0.defineInjectable({ factory: function CancelTaskService_Factory() { return new CancelTaskService(i0.inject(i1.HttpClient)); }, token: CancelTaskService, providedIn: "root" });
    return CancelTaskService;
}());
export { CancelTaskService };
