var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter, } from '@angular/core';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { ProblemLogService } from 'src/app/services/problem-log.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastService } from 'ng-uikit-pro-standard';
import { ActivatedRoute, Router } from '@angular/router';
import { StaffService } from 'src/app/services/staff.service';
import { AssignTaskService } from 'src/app/services/assign-task.service';
import { AuthService } from 'src/app/services/auth-service.service';
import { CancelTaskService } from 'src/app/services/cancel-task.service';
import { PickTaskService } from 'src/app/services/pick-Task.service';
import { CompleteTaskService } from 'src/app/services/complete-task.service';
import { LocalDateService } from 'src/app/services/local-date.service';
import { AssignTaskForm } from 'src/app/models/assignTaskForm';
import { CancelTaskForm } from 'src/app/models/cancelTaskForm';
import { PickTaskForm } from 'src/app/models/pickTaskForm';
import { ProblemLogDetail } from 'src/app/models/ProblemLogDetail';
import { ProblemPhoto } from 'src/app/models/problemPhoto';
import { CompleteTaskForm } from 'src/app/models/completeTaskForm';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
var ProblemLogDetailComponent = /** @class */ (function () {
    function ProblemLogDetailComponent(problemLogService, route, staffService, authService, fb, preLoader, localDateService, assignTaskService, pickTaskService, cancelTaskService, completeTaskService, router, toastService) {
        this.problemLogService = problemLogService;
        this.route = route;
        this.staffService = staffService;
        this.authService = authService;
        this.fb = fb;
        this.preLoader = preLoader;
        this.localDateService = localDateService;
        this.assignTaskService = assignTaskService;
        this.pickTaskService = pickTaskService;
        this.cancelTaskService = cancelTaskService;
        this.completeTaskService = completeTaskService;
        this.router = router;
        this.toastService = toastService;
        this.files = [];
        this.completePhotos = [];
        this.mapsrc = "/blank";
        this.toastOption = { positionClass: 'toast-center' };
        this.problemLogDetail = new ProblemLogDetail();
        var today = new Date();
        var month = today.getMonth() + 1 < 9 ? "0" + (today.getMonth() + 1).toString() : today.getMonth().toString();
        var day = today.getDate() < 9 ? "0" + today.getDate().toString() : today.getDate().toString();
        var year = today.getFullYear();
        this.startDate = day + "-" + month + "-" + year;
        this.myDatePickerOptions = {
            dateFormat: "dd-mm-yyyy",
            startDate: day + "-" + month + "-" + year,
            disableUntil: { year: year, month: today.getMonth() + 1, day: today.getDate() - 1 },
            showClearDateBtn: false
        };
    }
    ProblemLogDetailComponent.prototype.hoursLater = function (h) {
        var d = new Date();
        d.setTime(d.getTime() + (h * 60 * 60 * 1000));
        var newDate = d.getHours() + ":" + d.getMinutes();
        return newDate;
    };
    ProblemLogDetailComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var code, hasCode;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.preLoader.start();
                        window.scrollTo(0, 0);
                        this.createFormGroups();
                        code = this.route.snapshot.queryParams['code'];
                        hasCode = code != null && code != undefined;
                        if (!hasCode) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.authService.authenticate(code)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.authService.currentUser.subscribe(function (user) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.currentUser = user;
                                        if ((user == null || !user.hasCorpMap || !user.isAllowedDepartment) && !hasCode) {
                                            this.router.navigateByUrl("unauthorized");
                                        }
                                        return [4 /*yield*/, this.getProblemLogDetail()];
                                    case 1:
                                        _a.sent();
                                        this.preLoader.stop();
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    ProblemLogDetailComponent.prototype.createFormGroups = function () {
        this.completeTaskFormGroup = this.fb.group({
            remark: ['']
        });
        this.assignTaskFormGroup = this.fb.group({
            worker: ['', Validators.required],
            targetCompleteDate: ['', Validators.required],
            targetCompleteTime: [this.hoursLater(3), Validators.required],
        });
        this.cancelTaskFormGroup = this.fb.group({
            reason: ['']
        });
        this.pickTaskFormGroup = this.fb.group({});
    };
    ProblemLogDetailComponent.prototype.getProblemLogDetail = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var id = +_this.route.snapshot.paramMap.get('id');
            _this.problemLogService.GetDetailById(id).subscribe(function (response) {
                _this.problemLogDetail = response;
                _this.getStaffs();
                resolve("");
            });
        });
    };
    ProblemLogDetailComponent.prototype.getLocalDate = function (date) {
        return this.localDateService.ToLocalDateString(date);
    };
    ProblemLogDetailComponent.prototype.getStaffs = function () {
        var _this = this;
        this.staffService.getByDepartment(this.problemLogDetail.departmentId).subscribe(function (response) {
            _this.staffs = response.departmentUsers;
        });
    };
    ProblemLogDetailComponent.prototype.getProgressStyle = function (num) {
        return { width: num + '%' };
    };
    ProblemLogDetailComponent.prototype.makeid = function () {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 5; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    };
    ProblemLogDetailComponent.prototype.onNewImage = function (newImg) {
        this.addImgUrl(newImg, this.makeid());
    };
    ProblemLogDetailComponent.prototype.addImgUrl = function (file, filename) {
        if (file == undefined)
            return;
        //var reader = new FileReader();
        //reader.readAsDataURL(file);
        //reader.onload = (_event) => {
        var completePhoto = new ProblemPhoto();
        //completePhoto.base64 = reader.result;
        completePhoto.base64 = file;
        completePhoto.name = filename;
        this.completePhotos.push(completePhoto);
        //}
    };
    ProblemLogDetailComponent.prototype.removeImagUrl = function (name) {
        this.completePhotos = this.completePhotos.filter(function (i) { return i.name !== name; });
    };
    ProblemLogDetailComponent.prototype.showFiles = function () {
        //return `總共 ${this.files.length} 檔案`;
        return "\u603B\u5171 " + this.completePhotos.length + " \u6863\u6848";
    };
    ProblemLogDetailComponent.prototype.onUploadOutput = function (output) {
        if (output.type === 'allAddedToQueue') {
        }
        else if (output.type === 'addedToQueue') {
            this.files.push(output.file); // add file to array when added
            this.addImgUrl(output.file.nativeFile, output.file.name);
        }
        else if (output.type === 'uploading') {
            // update current data in files array for uploading file
            var index = this.files.findIndex(function (file) { return file.id === output.file.id; });
            this.files[index] = output.file;
        }
        else if (output.type === 'removed') {
            // remove file from array when removed
            this.files = this.files.filter(function (file) { return file !== output.file; });
        }
        else if (output.type === 'dragOver') {
            //this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
        }
        else if (output.type === 'drop') {
            // this.dragOver = false;
        }
    };
    ProblemLogDetailComponent.prototype.showMapClicked = function () {
        this.mapsrc = this.problemLogDetail.mapIframeSrc;
        this.showMapModal.show();
    };
    //assignClicked() {
    //  this.assignTaskFormGroup.reset();
    //  this.assignTaskFormGroup.patchValue({ targetCompleteDate: this.startDate });
    //  this.assignTaskFormGroup.patchValue({ targetCompleteTime: this.hoursLater(3) });
    //  this.assignModal.show();
    //}
    ProblemLogDetailComponent.prototype.assignClicked = function () {
        return __awaiter(this, void 0, void 0, function () {
            var workerCtrl;
            var _this = this;
            return __generator(this, function (_a) {
                this.filteredStaffs = new Observable();
                this.assignTaskFormGroup.reset();
                this.assignTaskFormGroup.patchValue({ targetCompleteDate: this.startDate });
                this.assignTaskFormGroup.patchValue({ targetCompleteTime: this.hoursLater(3) });
                workerCtrl = this.assignTaskFormGroup.get('worker');
                this.filteredStaffs = workerCtrl.valueChanges
                    .pipe(startWith(''), map(function (value) { return typeof value === 'string' ? value : value == undefined ? '' : value.name; }), map(function (name) { return name ? _this._filter(name) : _this.staffs.slice(); }));
                this.assignModal.show();
                return [2 /*return*/];
            });
        });
    };
    ProblemLogDetailComponent.prototype.displayFn = function (user) {
        return user ? user.name : undefined;
    };
    ProblemLogDetailComponent.prototype._filter = function (name) {
        var filterValue = name.toLowerCase();
        return this.staffs.filter(function (option) { return option.name.toLowerCase().indexOf(filterValue) === 0; });
    };
    ProblemLogDetailComponent.prototype.cancelClicked = function () {
        this.cancelTaskFormGroup.reset();
        this.cancelModal.show();
    };
    ProblemLogDetailComponent.prototype.pickClicked = function () {
        this.pickModal.show();
    };
    ProblemLogDetailComponent.prototype.cancelSubmit = function () {
        var _this = this;
        var cancelTaskForm = new CancelTaskForm();
        cancelTaskForm.createdBy = this.currentUser.userId;
        cancelTaskForm.reason = this.cancelTaskFormGroup.value.reason;
        cancelTaskForm.problemLogId = this.problemLogDetail.problemLogId;
        this.cancelTaskService.Save(cancelTaskForm).subscribe(function (response) {
            if (response.errcode == 0) {
                _this.problemLogService.GetDetailById(_this.problemLogDetail.problemLogId).subscribe(function (pl) { return _this.problemLogDetail = pl; });
                _this.toastService.success("储存成功", '', _this.toastOption);
            }
            if (response.errcode > 0) {
                _this.toastService.error("储存失败 " + response.errmsg, '', _this.toastOption);
            }
        });
        this.cancelModal.hide();
    };
    ProblemLogDetailComponent.prototype.pickSubmit = function () {
        var _this = this;
        var pickTaskForm = new PickTaskForm();
        pickTaskForm.createdBy = this.currentUser.userId;
        pickTaskForm.workerId = this.currentUser.userId;
        pickTaskForm.problemLogId = this.problemLogDetail.problemLogId;
        this.pickTaskService.Save(pickTaskForm).subscribe(function (response) {
            if (response.errcode == 0) {
                _this.problemLogService.GetDetailById(_this.problemLogDetail.problemLogId).subscribe(function (pl) { return _this.problemLogDetail = pl; });
                _this.toastService.success("储存成功", '', _this.toastOption);
            }
            if (response.errcode > 0) {
                _this.toastService.error("储存失败 " + response.errmsg, '', _this.toastOption);
            }
        });
        this.pickModal.hide();
    };
    ProblemLogDetailComponent.prototype.assignSubmit = function () {
        var _this = this;
        if (!this.assignTaskFormGroup.valid)
            return;
        var assignTaskForm = new AssignTaskForm();
        assignTaskForm.createdBy = this.currentUser.userId;
        assignTaskForm.targetCompleteDate = this.assignTaskFormGroup.value.targetCompleteDate;
        assignTaskForm.targetCompleteTime = this.assignTaskFormGroup.value.targetCompleteTime;
        assignTaskForm.workerId = this.assignTaskFormGroup.value.worker.userid;
        assignTaskForm.problemLogId = this.problemLogDetail.problemLogId;
        this.assignTaskService.Save(assignTaskForm).subscribe(function (response) {
            if (response.errcode == 0) {
                _this.problemLogService.GetDetailById(_this.problemLogDetail.problemLogId).subscribe(function (pl) { return _this.problemLogDetail = pl; });
                _this.toastService.success("储存成功", '', _this.toastOption);
            }
            if (response.errcode > 0) {
                _this.toastService.error("储存失败 " + response.errmsg, '', _this.toastOption);
            }
        });
        this.assignModal.hide();
    };
    ProblemLogDetailComponent.prototype.completeClicked = function () {
        this.completeTaskFormGroup.reset();
        this.completeModal.show();
    };
    ProblemLogDetailComponent.prototype.completeSubmit = function () {
        var _this = this;
        var completeTaskForm = new CompleteTaskForm();
        completeTaskForm.createdBy = this.currentUser.userId;
        completeTaskForm.problemLogId = this.problemLogDetail.problemLogId;
        completeTaskForm.remark = this.completeTaskFormGroup.value.remark;
        completeTaskForm.completePhotos = this.completePhotos;
        this.completeTaskService.Save(completeTaskForm).subscribe(function (response) {
            if (response.errcode == 0) {
                _this.problemLogService.GetDetailById(_this.problemLogDetail.problemLogId).subscribe(function (pl) { return _this.problemLogDetail = pl; });
                _this.toastService.success("储存成功", '', _this.toastOption);
            }
            if (response.errcode > 0) {
                _this.toastService.error("储存失败 " + response.errmsg, '', _this.toastOption);
            }
        });
        this.completeModal.hide();
    };
    Object.defineProperty(ProblemLogDetailComponent.prototype, "ifarmHeight", {
        get: function () {
            var minHehgit = 230;
            var height = window.innerWidth / 3;
            return height < minHehgit ? minHehgit : height;
        },
        enumerable: true,
        configurable: true
    });
    ProblemLogDetailComponent.prototype.currentUserCanCancel = function () {
        return this.currentUser.isWorkingGroupUser;
    };
    ProblemLogDetailComponent.prototype.currentUserCanAssign = function () {
        return this.currentUser.isWorkingGroupUser;
    };
    Object.defineProperty(ProblemLogDetailComponent.prototype, "showRefresherHeader", {
        get: function () {
            var showRefresher = this.touchStarting && this.touchStartY < 500 && (this.touchMoveY - this.touchStartY > 100);
            return showRefresher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProblemLogDetailComponent.prototype, "refresherState", {
        get: function () {
            if (this.showRefresherHeader && !this.touchEnding)
                return '松开立即刷新...';
            if (this.showRefresherHeader && this.touchEnding)
                return '刷新中...';
        },
        enumerable: true,
        configurable: true
    });
    ProblemLogDetailComponent.prototype.touchStart = function (e) {
        this.touchStarting = true;
        this.touchStartY = e.touches[0].screenY;
    };
    ProblemLogDetailComponent.prototype.touchMove = function (e) {
        this.touchMoveY = e.touches[0].screenY;
    };
    ProblemLogDetailComponent.prototype.touchEnd = function (e) {
        var _this = this;
        this.touchEnding = true;
        if (this.showRefresherHeader) {
            this.getProblemLogDetail();
            setTimeout(function () {
                _this.touchStarting = false;
                _this.touchEnding = false;
                _this.touchStartY = 0;
                _this.touchMoveY = 0;
            }, 2000);
        }
        else {
            this.touchStartY = 0;
            this.touchMoveY = 0;
        }
    };
    return ProblemLogDetailComponent;
}());
export { ProblemLogDetailComponent };
