import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NewProblemLogService = /** @class */ (function () {
    function NewProblemLogService(http) {
        this.http = http;
    }
    NewProblemLogService.prototype.Save = function (newProblemLog) {
        var url = "/api/ProblemLog/CreateProblemLog";
        return this.http.post(url, newProblemLog);
    };
    NewProblemLogService.ngInjectableDef = i0.defineInjectable({ factory: function NewProblemLogService_Factory() { return new NewProblemLogService(i0.inject(i1.HttpClient)); }, token: NewProblemLogService, providedIn: "root" });
    return NewProblemLogService;
}());
export { NewProblemLogService };
