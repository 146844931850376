import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import '../../interfaces/window';
import '../../interfaces/floor';
import { CorpMapService } from 'src/app/services/corp-map.service';
import { AuthService } from 'src/app/services/auth-service.service';
import { User } from 'src/app/models/User';



@Component({
  selector: 'app-work-order-map',
  templateUrl: './work-order-map.component.html',
  styleUrls: ['./work-order-map.component.scss']
})

export class WorkOrderMapComponent implements OnInit {
  mapId: number;
  appKey: string;
  control: any;
  lastLayer: any = null;
  lastColor: any = null;
  currentUser: User;

  constructor(
    private router: Router,
    private corpMapService: CorpMapService,
    private authService: AuthService
  ) { }

  async ngOnInit() {
    window.currentFeatureId = undefined;
    window.currentFeatureName = undefined;
    window.currentFloorId = undefined;
    window.currentFloorName = undefined;
    await this.loadMap();
    await this.buildMap();
    setTimeout(() => {
      window.map.setZoom(19);
    }, (2000));
  }
  loadMap() {
    return new Promise(resolve => {
      this.currentUser = this.authService.currentUserValue;
      this.corpMapService.getMap(this.currentUser.wechatCorpId).subscribe(response => {
        this.appKey = response.corpMap.appKey;
        this.mapId = response.corpMap.mapId;
        resolve();
      })
    })
  }

  public gotoCreateForm() {
    var url = 'createProblemLog';
    //var param = {
    //  floorId: window.currentFloorId,
    //  floorName: this.selectedFloorName,
    //  featureId: window.currentFeatureId,
    //  featureName: this.selectedShopName
    //}
    window.currentFloorName = this.selectedFloorName;
    window.currentFeatureName = this.selectedShopName;
    this.router.navigate([url]);
  }
  get selectedShopName(): any {
    return window.currentFeatureName;
  }
  get selectedFloorName(): any {

    var floors = window.floors as Floor[];
    var selectedFloorId = window.currentFloorId;
    var selectedFloor = floors.filter(function (floor: Floor) {
      return floor.id == selectedFloorId
    })

    if (selectedFloor.length == 0)
      return "unknow floor (" + selectedFloorId + ")";

    return selectedFloor[0].name;

  }
  async buildMap() {
    return new Promise(resolve => {
      var control = this.control;
      var loadPlan = this.loadPlanarGraph;
      window.map = new window.NGR.View('map', {
        appKey: this.appKey,
      });
      window.res = new window.NGR.DataSource({
        appKey: this.appKey
      });

      window.layers = {};
      window.lastLayer = null;
      control = window.NGR.control.floor();
      window.res.requestMap(this.mapId).then(function (mapInfo: any) {
        window.res.requestPOIChildren(mapInfo.poi).then(function (floors: any) {

          window.map.addControl(control);

          control.on('change', function (e: any) {

            control.setCurrentFloor(e.to, loadPlan);
            window.currentFloorId = e.to;
            window.currentFeatureName = undefined;
            window.currentFeatureId = undefined;
            setTimeout(() => {
              window.map.setZoom(19);
            }, (2000));


          });
          floors = floors.reverse();
          window.floors = floors;
          window.currentFloorId = floors.find(x => x.english_name == "F1").id;
          control.setFloorList(floors);
          control.setCurrentFloor(window.currentFloorId, loadPlan);
          window.map.setZoom(18);
        }).fail(onerror);
      }).fail(onerror);

      resolve();
    })
  }

  loadPlanarGraph(planarGraphId: any) {
    var res = window.res;
    var NGR = window.NGR;
    var layers = window.layers;
    var map = window.map;

    res.requestPlanarGraph(planarGraphId).then(function (layerInfo: any) {
      NGR.IO.fetch({
        url: './assets/map.style.json',
        onsuccess: JSON.parse
      }).then(function (style: any) {
        window.map.clear();

        window.layers.frame = NGR.featureLayer(layerInfo, {
          layerType: 'Frame',
          styleConfig: style
        });
        layers.area = window.NGR.featureLayer(layerInfo, {
          layerType: 'Area',
          styleConfig: style
        });
        layers.annotation = NGR.featureLayer(layerInfo.Area, {
          layerType: 'LogoLabel',
          styleConfig: style
        });
        layers.facility = NGR.featureLayer(layerInfo.Facility, {
          layerType: 'Facility',
          styleConfig: style
        });

        layers.collision = NGR.layerGroup.collision({
          margin: 3
        });
        layers.collision.addLayer(layers.annotation);
        layers.collision.addLayer(layers.facility);

        map.addLayer(layers.frame);
        map.addLayer(layers.area);
        map.addLayer(layers.collision);

        map.render();

        //click event
        window.layers.area.eachLayer(function (layer: any) {
          layer.on('click', (selectedArea: any) => {
            window.currentFeatureName = selectedArea.target.feature.properties.name || 'unknow area (' + selectedArea.target.feature.id + ')';
            window.currentFeatureId = selectedArea.target.feature.id;
            if (window.lastLayer) {
              window.lastLayer.setStyle({ fillColor: window.lastColor });
            }
            window.lastLayer = selectedArea.target;
            window.lastColor = selectedArea.target.options.fillColor;
            selectedArea.target.setStyle({ fillColor: '#ff0000' });
          })
        })

      })
    })
  }
}
