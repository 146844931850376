export class DepartmentResponse {
  errcode: number;
  errmsg: string;
  departments: Department[]
}
export class Department{
  id: number;
  name: string;
  parentid: number
}
