import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { AuthService } from './auth-service.service';
import { Observable, throwError } from 'rxjs';
import { Router} from '@angular/router'
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router:Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token = this.authService.currentUserValue == null ? '' : "Bearer "+ this.authService.currentUserValue.originalWechatCode + " " + this.authService.currentUserValue.token;
    request = request.clone({
      setHeaders: {
        Authorization: token
      }
    });

    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error.status === 401) {
          this.router.navigateByUrl('unauthorized')
        }
        return throwError(error);
      })
    );
  }
}
