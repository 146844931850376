import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PickTaskService = /** @class */ (function () {
    function PickTaskService(http) {
        this.http = http;
    }
    PickTaskService.prototype.Save = function (pickTaskForm) {
        var url = "/api/ProblemLog/pickTask";
        return this.http.post(url, pickTaskForm);
    };
    PickTaskService.ngInjectableDef = i0.defineInjectable({ factory: function PickTaskService_Factory() { return new PickTaskService(i0.inject(i1.HttpClient)); }, token: PickTaskService, providedIn: "root" });
    return PickTaskService;
}());
export { PickTaskService };
