import { Component, OnInit, AfterViewInit, Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { LyTheme2, ThemeVariables } from '@alyle/ui';

const STYLES = (theme: ThemeVariables) => ({
  '@global': {
    body: {
      backgroundColor: theme.background.default,
      color: theme.text.default,
      fontFamily: theme.typography.fontFamily,
      margin: 0,
      direction: theme.direction
    }
  }
});

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']

})

export class AppComponent implements OnInit,  AfterViewInit {
  readonly classes = this.theme.addStyleSheet(STYLES);
  ngAfterViewInit(): void {
      this.preLoader.stop();
    }

  constructor(private theme: LyTheme2,
              private preLoader: MDBSpinningPreloader){
    //this.preLoader.stop();
  }
  ngOnInit(): void {

  }

}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
