import { ProblemLogListItem } from 'src/app/models/problemLogListItem';

export class CancelTaskForm {
  reason: string;
  createdBy: string;
  problemLogId: number;
}
export class CancelTaskFormResponse {
  errcode: number;
  errmsg: string;
  problemLogListItem: ProblemLogListItem;

}
