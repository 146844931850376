import { DashboardResponse } from '../models/dashboard';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DashboardService = /** @class */ (function () {
    function DashboardService(http) {
        this.http = http;
    }
    DashboardService.prototype.getDashboard = function (departmentId) {
        var url = "/api/Dashboard/GetDashboard?departmentId=" + departmentId;
        return this.http.get(url);
    };
    DashboardService.ngInjectableDef = i0.defineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.inject(i1.HttpClient)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };
