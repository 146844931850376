import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CompleteTaskService = /** @class */ (function () {
    function CompleteTaskService(http) {
        this.http = http;
    }
    CompleteTaskService.prototype.Save = function (completeTaskForm) {
        var url = "/api/ProblemLog/CompleteTask";
        return this.http.post(url, completeTaskForm);
    };
    CompleteTaskService.ngInjectableDef = i0.defineInjectable({ factory: function CompleteTaskService_Factory() { return new CompleteTaskService(i0.inject(i1.HttpClient)); }, token: CompleteTaskService, providedIn: "root" });
    return CompleteTaskService;
}());
export { CompleteTaskService };
