import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProblemLogListItem } from '../models/problemLogListItem';
import { ProblemLogDetail } from 'src/app/models/ProblemLogDetail';
import { ProblemLogSearchForm, ProblemLogSearchResponse } from 'src/app/models/problemLogSearchForm';
@Injectable({
  providedIn: 'root'
})
export class ProblemLogService {

  baseUrl: string

  constructor(private http: HttpClient) {
    
  }
  SetSearchForm(searchForm: ProblemLogSearchForm) {
    localStorage.setItem('searchForm', JSON.stringify(searchForm));
  }
  GetSearchForm(): ProblemLogSearchForm {
    var searchForm = <ProblemLogSearchForm>Object.assign(new ProblemLogSearchForm, JSON.parse(localStorage.getItem('searchForm')));
    return searchForm;
  }
  Search(): Observable<ProblemLogSearchResponse> {
    let url = "api/ProblemLog/Query";

    var searchForm = this.GetSearchForm();
    return this.http.post<ProblemLogSearchResponse>(url, searchForm);
  }
  GetDetailById(id: number): Observable<ProblemLogDetail> {
    let url = `api/ProblemLog/ProblemLogDetail/${id}`;

    return this.http.get<ProblemLogDetail>(url);
  }
  getAll(): Observable<ProblemLogListItem[]>{
    let url = "api/ProblemLog";
    return this.http.get<ProblemLogListItem[]>(url);
  }

  getMore(last: ProblemLogListItem, num: number): Observable<ProblemLogListItem[]> {
    let url = "api/ProblemLog/GetMore";
    var data = {
      lastId: last.problemLogId,
      num: num
    }
    return this.http.post<ProblemLogListItem[]>(url, data);
  }
}
