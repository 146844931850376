var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import '../../interfaces/window';
import '../../interfaces/floor';
import { CorpMapService } from 'src/app/services/corp-map.service';
import { AuthService } from 'src/app/services/auth-service.service';
var WorkOrderMapComponent = /** @class */ (function () {
    function WorkOrderMapComponent(router, corpMapService, authService) {
        this.router = router;
        this.corpMapService = corpMapService;
        this.authService = authService;
        this.lastLayer = null;
        this.lastColor = null;
    }
    WorkOrderMapComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        window.currentFeatureId = undefined;
                        window.currentFeatureName = undefined;
                        window.currentFloorId = undefined;
                        window.currentFloorName = undefined;
                        return [4 /*yield*/, this.loadMap()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.buildMap()];
                    case 2:
                        _a.sent();
                        setTimeout(function () {
                            window.map.setZoom(19);
                        }, (2000));
                        return [2 /*return*/];
                }
            });
        });
    };
    WorkOrderMapComponent.prototype.loadMap = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.currentUser = _this.authService.currentUserValue;
            _this.corpMapService.getMap(_this.currentUser.wechatCorpId).subscribe(function (response) {
                _this.appKey = response.corpMap.appKey;
                _this.mapId = response.corpMap.mapId;
                resolve();
            });
        });
    };
    WorkOrderMapComponent.prototype.gotoCreateForm = function () {
        var url = 'createProblemLog';
        //var param = {
        //  floorId: window.currentFloorId,
        //  floorName: this.selectedFloorName,
        //  featureId: window.currentFeatureId,
        //  featureName: this.selectedShopName
        //}
        window.currentFloorName = this.selectedFloorName;
        window.currentFeatureName = this.selectedShopName;
        this.router.navigate([url]);
    };
    Object.defineProperty(WorkOrderMapComponent.prototype, "selectedShopName", {
        get: function () {
            return window.currentFeatureName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderMapComponent.prototype, "selectedFloorName", {
        get: function () {
            var floors = window.floors;
            var selectedFloorId = window.currentFloorId;
            var selectedFloor = floors.filter(function (floor) {
                return floor.id == selectedFloorId;
            });
            if (selectedFloor.length == 0)
                return "unknow floor (" + selectedFloorId + ")";
            return selectedFloor[0].name;
        },
        enumerable: true,
        configurable: true
    });
    WorkOrderMapComponent.prototype.buildMap = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        var control = _this.control;
                        var loadPlan = _this.loadPlanarGraph;
                        window.map = new window.NGR.View('map', {
                            appKey: _this.appKey,
                        });
                        window.res = new window.NGR.DataSource({
                            appKey: _this.appKey
                        });
                        window.layers = {};
                        window.lastLayer = null;
                        control = window.NGR.control.floor();
                        window.res.requestMap(_this.mapId).then(function (mapInfo) {
                            window.res.requestPOIChildren(mapInfo.poi).then(function (floors) {
                                window.map.addControl(control);
                                control.on('change', function (e) {
                                    control.setCurrentFloor(e.to, loadPlan);
                                    window.currentFloorId = e.to;
                                    window.currentFeatureName = undefined;
                                    window.currentFeatureId = undefined;
                                    setTimeout(function () {
                                        window.map.setZoom(19);
                                    }, (2000));
                                });
                                floors = floors.reverse();
                                window.floors = floors;
                                window.currentFloorId = floors.find(function (x) { return x.english_name == "F1"; }).id;
                                control.setFloorList(floors);
                                control.setCurrentFloor(window.currentFloorId, loadPlan);
                                window.map.setZoom(18);
                            }).fail(onerror);
                        }).fail(onerror);
                        resolve();
                    })];
            });
        });
    };
    WorkOrderMapComponent.prototype.loadPlanarGraph = function (planarGraphId) {
        var res = window.res;
        var NGR = window.NGR;
        var layers = window.layers;
        var map = window.map;
        res.requestPlanarGraph(planarGraphId).then(function (layerInfo) {
            NGR.IO.fetch({
                url: './assets/map.style.json',
                onsuccess: JSON.parse
            }).then(function (style) {
                window.map.clear();
                window.layers.frame = NGR.featureLayer(layerInfo, {
                    layerType: 'Frame',
                    styleConfig: style
                });
                layers.area = window.NGR.featureLayer(layerInfo, {
                    layerType: 'Area',
                    styleConfig: style
                });
                layers.annotation = NGR.featureLayer(layerInfo.Area, {
                    layerType: 'LogoLabel',
                    styleConfig: style
                });
                layers.facility = NGR.featureLayer(layerInfo.Facility, {
                    layerType: 'Facility',
                    styleConfig: style
                });
                layers.collision = NGR.layerGroup.collision({
                    margin: 3
                });
                layers.collision.addLayer(layers.annotation);
                layers.collision.addLayer(layers.facility);
                map.addLayer(layers.frame);
                map.addLayer(layers.area);
                map.addLayer(layers.collision);
                map.render();
                //click event
                window.layers.area.eachLayer(function (layer) {
                    layer.on('click', function (selectedArea) {
                        window.currentFeatureName = selectedArea.target.feature.properties.name || 'unknow area (' + selectedArea.target.feature.id + ')';
                        window.currentFeatureId = selectedArea.target.feature.id;
                        if (window.lastLayer) {
                            window.lastLayer.setStyle({ fillColor: window.lastColor });
                        }
                        window.lastLayer = selectedArea.target;
                        window.lastColor = selectedArea.target.options.fillColor;
                        selectedArea.target.setStyle({ fillColor: '#ff0000' });
                    });
                });
            });
        });
    };
    return WorkOrderMapComponent;
}());
export { WorkOrderMapComponent };
