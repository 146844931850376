var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service.service';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ProblemLogSearchForm } from 'src/app/models/problemLogSearchForm';
import { ProblemLogService } from 'src/app/services/problem-log.service';
var DashboardsComponent = /** @class */ (function () {
    function DashboardsComponent(problemLogService, route, authService, router, preLoader, dashBoardService) {
        this.problemLogService = problemLogService;
        this.route = route;
        this.authService = authService;
        this.router = router;
        this.preLoader = preLoader;
        this.dashBoardService = dashBoardService;
        this.pendingTaskQtys = [];
        this.pendingTaskLabels = [];
        this.pendingTaskOptions = {
            responsive: true,
            legend: {
                labels: {
                    boxWidth: 20
                }
            }
        };
        this.todayTaskLabels = ["已建立", "指派中", "接收中", "工作中", "已完成"];
        this.todayTaskQtys = [{ data: [0, 0, 0, 0, 0], label: '' }];
        this.todayTasksOptions = {
            responsive: true,
            legend: {
                labels: {
                    boxWidth: 20
                }
            },
            scales: {
                yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1
                        }
                    }]
            }
        };
        this.todayTaskColors = [
            {
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                ],
                borderColor: [
                    'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                ],
                borderWidth: 2,
            }
        ];
        this.workingDatasets = [
            { data: [0, 0, 0, 0, 0], label: '' }
        ];
        this.workingTaskLabels = ["清洁", "维修", "投诉", "服务", "市场活动"];
        this.workingColors = [
            {
                backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'],
                hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5', '#616774'],
                borderWidth: 2,
            }
        ];
        this.workingTasksOptions = {
            responsive: true,
            legend: {
                labels: {
                    boxWidth: 20
                }
            }
        };
        this.overdueQtys = [0, 0, 0, 0];
        this.overDueLabels = ["2d", "3-7d", "8-14d", ">14d"];
        this.overDueTasksOptions = {
            responsive: true,
            legend: {
                labels: {
                    boxWidth: 20
                }
            }
        };
        this.departments = [];
    }
    DashboardsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var code, hasCode;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.preLoader.stop();
                        window.scrollTo(0, 0);
                        code = this.route.snapshot.queryParams['code'];
                        hasCode = code != null && code != undefined;
                        if (!hasCode) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.authService.authenticate(code)];
                    case 1:
                        _a.sent();
                        console.log('222222');
                        this.authService.currentUser.subscribe(function (user) {
                            _this.currentUser = user;
                            console.log('user', user);
                            if ((user == null || !user.hasCorpMap || !user.isAllowedDepartment) && !hasCode) {
                                _this.preLoader.stop();
                                _this.router.navigate(["unauthorized"]);
                            }
                            _this.queryDashboard(user);
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        this.authService.currentUser.subscribe(function (user) {
                            _this.currentUser = user;
                            if ((user == null || !user.hasCorpMap || !user.isAllowedDepartment) && !hasCode) {
                                _this.preLoader.stop();
                                _this.router.navigate(["unauthorized"]);
                            }
                            _this.queryDashboard(user);
                        });
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DashboardsComponent.prototype.pullToRefresh = function () {
        this.preLoader.start();
        this.queryDashboard(this.currentUser);
    };
    DashboardsComponent.prototype.queryDashboard = function (user) {
        var _this = this;
        this.dashBoardService.getDashboard(user.departments[0]).subscribe(function (response) {
            _this.dashBoard = response.dashboard;
            _this.pendingTaskQtys = _this.dashBoard.pendingTaskQtys;
            _this.pendingTaskLabels = _this.dashBoard.pendingTaskLabels;
            _this.todayTaskQtys = [{ data: _this.dashBoard.todayTaskQtys, label: '' }];
            _this.workingDatasets = [{ data: _this.dashBoard.workingTaskQtys, label: '' }];
            _this.overdueQtys = [{ data: _this.dashBoard.overdueTaskQtys, label: '' }];
        });
    };
    DashboardsComponent.prototype.onPendingTaskChartClick = function (event) {
        var searchForm = new ProblemLogSearchForm();
        window.scrollTo(0, 0);
        searchForm.departmentId = this.currentUser.departmentId;
        searchForm.wfStatus = ["PendingAssign", "PendingPick"];
        searchForm.lastItemId = 0;
        searchForm.take = 5;
        searchForm.problemTypeId = event.active[0]._index + 1;
        searchForm.fromDashboards = true;
        this.problemLogService.SetSearchForm(searchForm);
        this.router.navigateByUrl('inbox');
    };
    DashboardsComponent.prototype.onTodayChartClick = function (event) {
        var searchForm = new ProblemLogSearchForm();
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth();
        var day = today.getDate();
        window.scrollTo(0, 0);
        searchForm.departmentId = this.currentUser.departmentId;
        searchForm.lastItemId = 0;
        searchForm.take = 5;
        searchForm.byDate = new Date(year, month, day);
        searchForm.fromDashboards = true;
        this.problemLogService.SetSearchForm(searchForm);
        window.scrollTo(0, 0);
        this.router.navigateByUrl('inbox');
    };
    DashboardsComponent.prototype.onWorkingChartClick = function (event) {
        var searchForm = new ProblemLogSearchForm();
        window.scrollTo(0, 0);
        searchForm.departmentId = this.currentUser.departmentId;
        searchForm.lastItemId = 0;
        searchForm.take = 5;
        searchForm.fromDashboards = true;
        searchForm.problemTypeId = event.active[0]._index + 1;
        searchForm.wfStatus = ['Working'];
        this.problemLogService.SetSearchForm(searchForm);
        this.router.navigateByUrl('inbox');
    };
    DashboardsComponent.prototype.onOverdueChartClick = function (event) {
        var index = event.active[0]._index;
        var startDay = 0;
        var endDay = 0;
        var searchForm = new ProblemLogSearchForm();
        switch (index) {
            case 0:
                startDay = 0;
                endDay = 2;
                break;
            case 1:
                startDay = 3;
                endDay = 7;
                break;
            case 2:
                startDay = 8;
                endDay = 14;
                break;
            case 3:
                startDay = 15;
                endDay = 999;
                break;
            default:
                break;
        }
        window.scroll(0, 0);
        searchForm.departmentId = this.currentUser.departmentId;
        searchForm.lastItemId = 0;
        searchForm.take = 5;
        searchForm.fromDashboards = true;
        searchForm.overDueStartDay = startDay;
        searchForm.overDueEndDay = endDay;
        searchForm.wfStatus = ['PendingAssign', 'PendingPick', 'Working'];
        this.problemLogService.SetSearchForm(searchForm);
        this.router.navigateByUrl('inbox');
    };
    Object.defineProperty(DashboardsComponent.prototype, "departmentName", {
        get: function () {
            if (this.currentUser == null || this.currentUser.departmentName == null)
                return '';
            return this.currentUser.departmentName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardsComponent.prototype, "showRefresherHeader", {
        get: function () {
            var showRefresher = this.touchStarting && this.touchStartY < 500 && (this.touchMoveY - this.touchStartY > 100);
            return showRefresher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardsComponent.prototype, "refresherState", {
        get: function () {
            if (this.showRefresherHeader && !this.touchEnding)
                return '松开立即刷新...';
            if (this.showRefresherHeader && this.touchEnding)
                return '刷新中...';
        },
        enumerable: true,
        configurable: true
    });
    DashboardsComponent.prototype.touchStart = function (e) {
        this.touchStarting = true;
        this.touchStartY = e.touches[0].screenY;
    };
    DashboardsComponent.prototype.touchMove = function (e) {
        this.touchMoveY = e.touches[0].screenY;
    };
    DashboardsComponent.prototype.touchEnd = function (e) {
        var _this = this;
        this.touchEnding = true;
        if (this.showRefresherHeader) {
            this.queryDashboard(this.currentUser);
            setTimeout(function () {
                _this.touchStarting = false;
                _this.touchEnding = false;
                _this.touchStartY = 0;
                _this.touchMoveY = 0;
            }, 2000);
        }
        else {
            this.touchStartY = 0;
            this.touchMoveY = 0;
        }
    };
    return DashboardsComponent;
}());
export { DashboardsComponent };
