import { ProblemPhoto } from './problemPhoto';

export class NewProblemLog {
  createdBy: string;
  featureId: number;
  featureName: string;
  floorId: number;
  floorName: string;
  departmentId: number;
  problemId: number;
  problemAreaId: number;
  problemTypeId: number;
  description: string;
  problemPhotos: ProblemPhoto[];
}

export class NewProblemLogResponse {
  errcode: number;
  errmsg: string;
}
