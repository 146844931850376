import { ProblemLogListItem } from 'src/app/models/problemLogListItem';
export class ProblemLogSearchForm {

  freeText: string;
  departmentId: number;
  problemTypeId: number;
  problemId: number;
  wfStatus: string[];
  overDueStartDay: number;
  overDueEndDay: number;
  lastItemId: number;
  take: number;
  workerId: string;
  fromDashboards: boolean;
  byDate: Date;
}

export class ProblemLogSearchResponse {
  problemLogListItems: ProblemLogListItem[];
  errcode: number;
  errmsg: string;
}
