import {  LyHammerGestureConfig } from '@alyle/ui';
declare var Hammer: any;

export class CustomHammerConfig extends LyHammerGestureConfig {

  overrides = <any>{
    'pan': { direction: Hammer.DIRECTION_All },
    'swipe': { direction: Hammer.DIRECTION_VERTICAL },
  };


 
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'auto',
      inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
      recognizers: [
        [Hammer.Swipe, {
          direction: Hammer.DIRECTION_HORIZONTAL
        }]
      ]
    });
    var pan = new Hammer.Pan();
    var swipe = new Hammer.Swipe();
    var slide = this.createRecognizer(pan, { event: 'slide', threshold: 0 });
    pan.recognizeWith(swipe);
    mc.add([swipe, pan, slide]);
    return mc;
  }


 createRecognizer(base, options) {
     var inheritances = [];
     for (var _i = 2; _i < arguments.length; _i++) {
         inheritances[_i - 2] = arguments[_i];
     }
     var recognizer = new (base.constructor)(options);
     inheritances.push(base);
     inheritances.forEach(function (item) { return recognizer.recognizeWith(item); });
     return recognizer;
 };
} 