/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cropimage.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@alyle/ui/button/alyle-ui-button.ngfactory";
import * as i3 from "@alyle/ui/button";
import * as i4 from "@alyle/ui";
import * as i5 from "@alyle/ui/icon";
import * as i6 from "@angular/common";
import * as i7 from "../../../../node_modules/ngx-image-cropper/ngx-image-cropper.ngfactory";
import * as i8 from "ngx-image-cropper";
import * as i9 from "@angular/platform-browser";
import * as i10 from "../draw-canvas/draw-canvas.component.ngfactory";
import * as i11 from "../draw-canvas/draw-canvas.component";
import * as i12 from "./cropimage.component";
var styles_CropimageComponent = [i0.styles];
var RenderType_CropimageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CropimageComponent, data: {} });
export { RenderType_CropimageComponent as RenderType_CropimageComponent };
function View_CropimageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["ly-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rotateLeft() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LyButton_0, i2.RenderType_LyButton)), i1.ɵdid(1, 4964352, null, 0, i3.LyButton, [i1.ElementRef, i1.Renderer2, i4.LyTheme2, i1.NgZone, i4.LyRippleService, i4.LyFocusState], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "ly-icon", [], null, null, null, null, null)), i1.ɵdid(3, 737280, null, 0, i5.LyIcon, [i5.LyIconService, i1.ElementRef, i1.Renderer2, i4.LyTheme2], null, null), (_l()(), i1.ɵted(-1, null, ["rotate_left"])), (_l()(), i1.ɵted(-1, 0, ["\u5DE6\u8F6C "]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, null); }
function View_CropimageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["ly-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rotateRight() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LyButton_0, i2.RenderType_LyButton)), i1.ɵdid(1, 4964352, null, 0, i3.LyButton, [i1.ElementRef, i1.Renderer2, i4.LyTheme2, i1.NgZone, i4.LyRippleService, i4.LyFocusState], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "ly-icon", [], null, null, null, null, null)), i1.ɵdid(3, 737280, null, 0, i5.LyIcon, [i5.LyIconService, i1.ElementRef, i1.Renderer2, i4.LyTheme2], null, null), (_l()(), i1.ɵted(-1, null, ["rotate_right"])), (_l()(), i1.ɵted(-1, 0, ["\u53F3\u8F6C "]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, null); }
function View_CropimageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["color", "accent"], ["ly-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.crop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LyButton_0, i2.RenderType_LyButton)), i1.ɵdid(1, 4964352, null, 0, i3.LyButton, [i1.ElementRef, i1.Renderer2, i4.LyTheme2, i1.NgZone, i4.LyRippleService, i4.LyFocusState], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "ly-icon", [], null, null, null, null, null)), i1.ɵdid(3, 737280, null, 0, i5.LyIcon, [i5.LyIconService, i1.ElementRef, i1.Renderer2, i4.LyTheme2], null, null), (_l()(), i1.ɵted(-1, null, ["crop"])), (_l()(), i1.ɵted(-1, 0, ["\u526A\u88C1 "]))], function (_ck, _v) { var currVal_0 = "accent"; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
function View_CropimageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CropimageComponent_2)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CropimageComponent_3)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CropimageComponent_4)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cropping.isLoaded; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.cropping.isLoaded; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.cropping.isLoaded; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_CropimageComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { canvas: 0 }), i1.ɵqud(402653184, 2, { drawCanvas: 0 }), i1.ɵqud(402653184, 3, { imageCropper: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "button", [["color", "primary"], ["ly-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LyButton_0, i2.RenderType_LyButton)), i1.ɵdid(5, 4964352, null, 0, i3.LyButton, [i1.ElementRef, i1.Renderer2, i4.LyTheme2, i1.NgZone, i4.LyRippleService, i4.LyFocusState], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "ly-icon", [], null, null, null, null, null)), i1.ɵdid(7, 737280, null, 0, i5.LyIcon, [i5.LyIconService, i1.ElementRef, i1.Renderer2, i4.LyTheme2], null, null), (_l()(), i1.ɵted(-1, null, ["image"])), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u7167\u7247"])), (_l()(), i1.ɵeld(11, 0, [[1, 0], ["_fileInput", 1]], null, 0, "input", [["accept", "image/*"], ["hidden", ""], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.fileChangeEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CropimageComponent_1)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "image-cropper", [["format", "png"], ["outputType", "base64"]], [[4, "text-align", null]], [[null, "imageCropped"], [null, "loadImageFailed"], ["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 16).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 16).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 16).moveStop() !== false);
        ad = (pd_4 && ad);
    } if (("imageCropped" === en)) {
        var pd_5 = (_co.imageCropped($event) !== false);
        ad = (pd_5 && ad);
    } if (("loadImageFailed" === en)) {
        var pd_6 = (_co.loadImageFailed() !== false);
        ad = (pd_6 && ad);
    } return ad; }, i7.View_ImageCropperComponent_0, i7.RenderType_ImageCropperComponent)), i1.ɵdid(16, 573440, [[3, 4]], 0, i8.ImageCropperComponent, [i9.DomSanitizer, i1.ChangeDetectorRef, i1.NgZone], { imageChangedEvent: [0, "imageChangedEvent"], format: [1, "format"], outputType: [2, "outputType"], maintainAspectRatio: [3, "maintainAspectRatio"], aspectRatio: [4, "aspectRatio"], resizeToWidth: [5, "resizeToWidth"] }, { imageCropped: "imageCropped", loadImageFailed: "loadImageFailed" }), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-draw-canvas", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).cx($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_DrawCanvasComponent_0, i10.RenderType_DrawCanvasComponent)), i1.ɵdid(19, 4243456, [[2, 4]], 0, i11.DrawCanvasComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "primary"; _ck(_v, 5, 0, currVal_1); _ck(_v, 7, 0); var currVal_3 = _co.cropping.isLoaded; _ck(_v, 14, 0, currVal_3); var currVal_5 = _co.imageChangedEvent; var currVal_6 = "png"; var currVal_7 = "base64"; var currVal_8 = true; var currVal_9 = (16 / 9); var currVal_10 = 500; _ck(_v, 16, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.cropping.isCropped || _co.cropping.isLoaded); _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.cropping.isCropped; _ck(_v, 12, 0, currVal_2); var currVal_4 = i1.ɵnov(_v, 16).alignImage; _ck(_v, 15, 0, currVal_4); var currVal_11 = !_co.cropping.isCropped; _ck(_v, 17, 0, currVal_11); }); }
export function View_CropimageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cropimage", [], null, null, null, View_CropimageComponent_0, RenderType_CropimageComponent)), i1.ɵdid(1, 4243456, null, 0, i12.CropimageComponent, [], null, null)], null, null); }
var CropimageComponentNgFactory = i1.ɵccf("app-cropimage", i12.CropimageComponent, View_CropimageComponent_Host_0, {}, { addImage: "addImage", isCropped: "isCropped" }, []);
export { CropimageComponentNgFactory as CropimageComponentNgFactory };
