export class User {
  userId: string;
  openId: string;
  deviceId: string;
  avatar: string;
  name: string;
  departments: number[]=[];
  token: string;
  expireDate: string;
  isWorkingGroupUser: boolean;
  isAllowedDepartment: boolean;
  hasCorpMap: boolean;
  departmentId: number;
  departmentName: string;
  wechatCorpId: number;
  originalWechatCode: string;
}
export class UserResponse {
  errcode: number;
  errmsg: string;
  user: User;
}
