import { Injectable } from '@angular/core';
import { PickTaskForm, PickTaskFormResponse } from '../models/pickTaskForm';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PickTaskService {
  baseUrl: string

  constructor(private http: HttpClient) {
  }

  public Save(pickTaskForm: PickTaskForm): Observable<PickTaskFormResponse> {
    let url = "/api/ProblemLog/pickTask";

    return this.http.post<PickTaskFormResponse>(url, pickTaskForm);
  }
}
