var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter } from '@angular/core';
import { ProblemLogService } from 'src/app/services/problem-log.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MDBSpinningPreloader, ToastService, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { StaffService } from 'src/app/services/staff.service';
import { AssignTaskService } from 'src/app/services/assign-task.service';
import { AuthService } from 'src/app/services/auth-service.service';
import { CancelTaskService } from 'src/app/services/cancel-task.service';
import { PickTaskService } from 'src/app/services/pick-Task.service';
import { ProblemTypeService } from 'src/app/services/problem-type.service';
import { DepartmentService } from 'src/app/services/department.service';
import { ProblemAreaService } from 'src/app/services/problem-area.service';
import { CompleteTaskService } from 'src/app/services/complete-task.service';
import { LocalDateService } from 'src/app/services/local-date.service';
import { User } from 'src/app/models/User';
import { AssignTaskForm } from 'src/app/models/assignTaskForm';
import { CancelTaskForm } from 'src/app/models/cancelTaskForm';
import { ProblemLogListItem } from 'src/app/models/problemLogListItem';
import { PickTaskForm } from 'src/app/models/pickTaskForm';
import { ProblemPhoto } from 'src/app/models/problemPhoto';
import { CompleteTaskForm } from 'src/app/models/completeTaskForm';
import { ProblemLogSearchForm } from 'src/app/models/problemLogSearchForm';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
var InboxComponent = /** @class */ (function () {
    function InboxComponent(problemLogService, authService, fb, staffService, assignTaskService, pickTaskService, cancelTaskService, problemTypeService, departmentService, problemAreaService, completeTaskService, localDateSerivce, preLoader, toastService) {
        this.problemLogService = problemLogService;
        this.authService = authService;
        this.fb = fb;
        this.staffService = staffService;
        this.assignTaskService = assignTaskService;
        this.pickTaskService = pickTaskService;
        this.cancelTaskService = cancelTaskService;
        this.problemTypeService = problemTypeService;
        this.departmentService = departmentService;
        this.problemAreaService = problemAreaService;
        this.completeTaskService = completeTaskService;
        this.localDateSerivce = localDateSerivce;
        this.preLoader = preLoader;
        this.toastService = toastService;
        this.isLoadingMore = false;
        this.files = [];
        this.completePhotos = [];
        this.problemLogs = [];
        this.selectedProblemLogItem = new ProblemLogListItem();
        this.staffs = [];
        this.isSidNavFirstOpen = false;
        this.infiniteScrollDisable = true;
        this.toastOption = { positionClass: 'toast-center' };
        this.statuses = [
            { label: '分派中', value: 'PendingAssign' },
            { label: '接收中', value: 'PendingPick' },
            { label: '工作中', value: 'Working' },
            { label: '已完成', value: 'Completed' },
            { label: '已取消', value: 'Cancelled' }
        ];
        this.overudes = [
            { name: '>2d', start: 0, end: 2 },
            { name: '3-7d', start: 3, end: 7 },
            { name: '8-14d', start: 8, end: 14 },
            { name: '>14d', start: 15, end: 999 }
        ];
        this.mapIframeSrc = "blank";
        var today = new Date();
        var month = today.getMonth() + 1 < 9 ? "0" + (today.getMonth() + 1).toString() : today.getMonth().toString();
        var day = today.getDate() < 9 ? "0" + today.getDate().toString() : today.getDate().toString();
        var year = today.getFullYear();
        this.startDate = day + "-" + month + "-" + year;
        this.myDatePickerOptions = {
            dateFormat: "dd-mm-yyyy",
            startDate: day + "-" + month + "-" + year,
            disableUntil: { year: year, month: today.getMonth() + 1, day: today.getDate() - 1 },
            showClearDateBtn: false
        };
    }
    InboxComponent.prototype.hoursLater = function (h) {
        var d = new Date();
        d.setTime(d.getTime() + (h * 60 * 60 * 1000));
        var newDate = d.getHours() + ":" + d.getMinutes();
        return newDate;
    };
    InboxComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.preLoader.start();
                        this.infiniteScrollDisable = true;
                        this.sidenav.hide();
                        this.createFormGroups();
                        return [4 /*yield*/, this.loadFormData()];
                    case 1:
                        _a.sent();
                        this.firstQuerySubmit();
                        return [2 /*return*/];
                }
            });
        });
    };
    InboxComponent.prototype.createFormGroups = function () {
        this.completeTaskFormGroup = this.fb.group({
            remark: ['']
        });
        this.searchFormGroup = this.fb.group({
            department: [''],
            problemType: [''],
            problem: [''],
            freeText: [''],
            overdue: [''],
            status: [''],
            workerId: ['']
        });
        this.assignTaskFormGroup = this.fb.group({
            worker: ['', Validators.required],
            targetCompleteDate: ['', Validators.required],
            targetCompleteTime: [this.hoursLater(3), Validators.required],
        });
        this.cancelTaskFormGroup = this.fb.group({
            reason: ['']
        });
        this.pickTaskFormGroup = this.fb.group({});
    };
    InboxComponent.prototype.loadFormData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCurrentUser()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getProblemTypes()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getDepartments()];
                    case 3:
                        _a.sent();
                        this.getProblemAreas();
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(InboxComponent.prototype, "pullToRefreshStyle", {
        get: function () {
            return { height: window.innerHeight - 100 + 'px' };
        },
        enumerable: true,
        configurable: true
    });
    InboxComponent.prototype.getCurrentUser = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.currentUser = _this.authService.currentUserValue;
            resolve();
        });
    };
    InboxComponent.prototype.getDepartments = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.departmentService.getAll(_this.currentUser.userId).then(function (x) {
                _this.departments = x.departments;
                resolve();
            });
        });
    };
    InboxComponent.prototype.getProblemTypes = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.problemTypeService.getAll().then(function (response) {
                _this.problemTypes = response.problemTypes;
                resolve();
            });
        });
    };
    InboxComponent.prototype.currentUserCanCancel = function () {
        return this.currentUser.isWorkingGroupUser;
    };
    InboxComponent.prototype.currentUserCanAssign = function () {
        return this.currentUser.isWorkingGroupUser;
    };
    InboxComponent.prototype.getLocalDate = function (date) {
        return this.localDateSerivce.ToLocalDateString(date);
    };
    InboxComponent.prototype.sideNavToggle = function () {
        this.sidenav.toggle();
    };
    InboxComponent.prototype.selectedProblemType = function (pt) {
        this.problems = pt.problems;
    };
    InboxComponent.prototype.getProblemAreas = function () {
        var _this = this;
        this.problemAreaService.getAll().then(function (response) { return _this.problemAreas = response.problemAreas; });
    };
    InboxComponent.prototype.showMapClicked = function (pl) {
        this.selectedProblemLogItem = pl;
        this.mapIframeSrc = pl.mapIframeSrc;
        this.showMapModal.show();
    };
    InboxComponent.prototype.onCloseMap = function () {
        this.showMapModal.hide();
        this.mapIframeSrc = "blank";
    };
    InboxComponent.prototype.assignClicked = function (pl) {
        return __awaiter(this, void 0, void 0, function () {
            var workerCtrl;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.filteredStaffs = new Observable();
                        this.assignTaskFormGroup.reset();
                        this.assignTaskFormGroup.patchValue({ targetCompleteDate: this.startDate });
                        this.assignTaskFormGroup.patchValue({ targetCompleteTime: this.hoursLater(3) });
                        this.selectedProblemLogItem = pl;
                        return [4 /*yield*/, this.getStaffs()];
                    case 1:
                        _a.sent();
                        workerCtrl = this.assignTaskFormGroup.get('worker');
                        this.filteredStaffs = workerCtrl.valueChanges
                            .pipe(startWith(''), map(function (value) { return typeof value === 'string' ? value : value == undefined ? '' : value.name; }), map(function (name) { return name ? _this._filter(name) : _this.staffs.slice(); }));
                        this.assignModal.show();
                        return [2 /*return*/];
                }
            });
        });
    };
    InboxComponent.prototype.displayFn = function (user) {
        return user ? user.name : undefined;
    };
    InboxComponent.prototype._filter = function (name) {
        var filterValue = name.toLowerCase();
        return this.staffs.filter(function (option) { return option.name.toLowerCase().indexOf(filterValue) === 0; });
    };
    InboxComponent.prototype.cancelClicked = function (pl) {
        this.cancelTaskFormGroup.reset();
        this.selectedProblemLogItem = pl;
        this.cancelModal.show();
    };
    InboxComponent.prototype.completeClicked = function (pl) {
        this.completeTaskFormGroup.reset();
        this.selectedProblemLogItem = pl;
        this.completeModal.show();
    };
    InboxComponent.prototype.pickClicked = function (pl) {
        this.selectedProblemLogItem = pl;
        this.pickModal.show();
    };
    InboxComponent.prototype.cancelSubmit = function () {
        var _this = this;
        var cancelTaskForm = new CancelTaskForm();
        cancelTaskForm.createdBy = this.currentUser.userId;
        cancelTaskForm.reason = this.cancelTaskFormGroup.value.reason;
        cancelTaskForm.problemLogId = this.selectedProblemLogItem.problemLogId;
        this.cancelTaskService.Save(cancelTaskForm).subscribe(function (response) {
            if (response.errcode == 0) {
                var index = _this.problemLogs.findIndex(function (x) { return x.problemLogId == cancelTaskForm.problemLogId; });
                _this.problemLogs[index] = response.problemLogListItem;
                _this.toastService.success("储存成功", '', _this.toastOption);
            }
            if (response.errcode > 0) {
                _this.toastService.error("储存失败 " + response.errmsg, '', _this.toastOption);
            }
        });
        this.cancelModal.hide();
    };
    InboxComponent.prototype.pickSubmit = function () {
        var _this = this;
        var pickTaskForm = new PickTaskForm();
        pickTaskForm.createdBy = this.currentUser.userId;
        pickTaskForm.workerId = this.currentUser.userId;
        pickTaskForm.problemLogId = this.selectedProblemLogItem.problemLogId;
        this.pickTaskService.Save(pickTaskForm).subscribe(function (response) {
            if (response.errcode == 0) {
                var index = _this.problemLogs.findIndex(function (x) { return x.problemLogId == pickTaskForm.problemLogId; });
                _this.problemLogs[index] = response.problemLogListItem;
                _this.toastService.success("储存成功", '', _this.toastOption);
            }
            if (response.errcode > 0) {
                _this.toastService.error("储存失败 " + response.errmsg, '', _this.toastOption);
            }
        });
        this.pickModal.hide();
    };
    InboxComponent.prototype.assignSubmit = function () {
        var _this = this;
        for (var i in this.assignTaskFormGroup.controls) {
            this.assignTaskFormGroup.controls[i].markAsDirty();
            this.assignTaskFormGroup.controls[i].updateValueAndValidity();
        }
        if (!this.assignTaskFormGroup.valid)
            return;
        var assignTaskForm = new AssignTaskForm();
        assignTaskForm.createdBy = this.currentUser.userId;
        assignTaskForm.targetCompleteDate = this.assignTaskFormGroup.value.targetCompleteDate;
        assignTaskForm.targetCompleteTime = this.assignTaskFormGroup.value.targetCompleteTime;
        assignTaskForm.workerId = this.assignTaskFormGroup.value.worker.userid;
        assignTaskForm.problemLogId = this.selectedProblemLogItem.problemLogId;
        this.assignTaskService.Save(assignTaskForm).subscribe(function (response) {
            if (response.errcode == 0) {
                var index = _this.problemLogs.findIndex(function (x) { return x.problemLogId == assignTaskForm.problemLogId; });
                _this.problemLogs[index] = response.problemLogListItem;
                _this.toastService.success("储存成功", '', _this.toastOption);
            }
            if (response.errcode > 0) {
                _this.toastService.error("储存失败 " + response.errmsg, '', _this.toastOption);
            }
        });
        this.assignModal.hide();
    };
    InboxComponent.prototype.completeSubmit = function () {
        var _this = this;
        var completeTaskForm = new CompleteTaskForm();
        completeTaskForm.createdBy = this.currentUser.userId;
        completeTaskForm.problemLogId = this.selectedProblemLogItem.problemLogId;
        completeTaskForm.remark = this.completeTaskFormGroup.value.remark;
        completeTaskForm.completePhotos = this.completePhotos;
        this.completeTaskService.Save(completeTaskForm).subscribe(function (response) {
            if (response.errcode == 0) {
                var index = _this.problemLogs.findIndex(function (x) { return x.problemLogId == completeTaskForm.problemLogId; });
                _this.problemLogs[index] = response.problemLogListItem;
                _this.toastService.success("储存成功", '', _this.toastOption);
            }
            if (response.errcode > 0) {
                _this.toastService.error("储存失败 " + response.errmsg, '', _this.toastOption);
            }
        });
        this.completeModal.hide();
    };
    InboxComponent.prototype.getStaffs = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.staffService.getByDepartment(_this.selectedProblemLogItem.departmentId).subscribe(function (response) {
                _this.staffs = response.departmentUsers;
                resolve();
            });
        });
    };
    InboxComponent.prototype.getProgressStyle = function (num) {
        return { width: num + '%' };
    };
    Object.defineProperty(InboxComponent.prototype, "ifarmHeight", {
        get: function () {
            var minHehgit = 230;
            var height = window.innerWidth / 3;
            return height < minHehgit ? minHehgit : height;
        },
        enumerable: true,
        configurable: true
    });
    InboxComponent.prototype.restoreSearchForm = function (searchForm) {
        if (searchForm.departmentId > 0) {
            var d = this.departments.find(function (x) { return x.id == searchForm.departmentId; });
            this.searchFormGroup.patchValue({ department: d });
        }
        if (searchForm.workerId != undefined && searchForm.workerId.length > 0) {
            this.searchFormGroup.patchValue({ workerId: searchForm.workerId });
        }
        if (searchForm.problemTypeId > 0) {
            var pt = this.problemTypes.find(function (x) { return x.problemTypeId == searchForm.problemTypeId; });
            this.searchFormGroup.patchValue({ problemType: pt });
        }
        if (searchForm.wfStatus != undefined && searchForm.wfStatus.length > 0) {
            this.searchFormGroup.patchValue({ status: searchForm.wfStatus });
        }
        if (searchForm.overDueEndDay == 2) {
            this.searchFormGroup.patchValue({ overdue: this.overudes[0] });
        }
        if (searchForm.overDueEndDay == 7) {
            this.searchFormGroup.patchValue({ overdue: this.overudes[1] });
        }
        if (searchForm.overDueStartDay == 14) {
            this.searchFormGroup.patchValue({ overdue: this.overudes[2] });
        }
        if (searchForm.overDueEndDay == 999) {
            this.searchFormGroup.patchValue({ overdue: this.overudes[3] });
        }
    };
    InboxComponent.prototype.firstQuerySubmit = function () {
        var searchForm = this.problemLogService.GetSearchForm();
        if (searchForm.fromDashboards) {
            this.restoreSearchForm(searchForm);
            searchForm.fromDashboards = false;
            this.sendQuery(searchForm);
        }
        else {
            if (this.currentUser.isWorkingGroupUser) {
                this.showUserOnly = false;
            }
            else {
                this.showUserOnly = true;
            }
            this.QueryByShowUserOnly();
        }
    };
    InboxComponent.prototype.createUserOnlySearchForm = function () {
        var searchForm = new ProblemLogSearchForm();
        searchForm.workerId = this.currentUser.userId;
        searchForm.wfStatus = ['PendingAssign', 'PendingPick', 'Working'];
        searchForm.lastItemId = 0;
        searchForm.take = 5;
        searchForm.departmentId = 0;
        return searchForm;
    };
    InboxComponent.prototype.createDepartmentOnlySearchForm = function () {
        var searchForm = new ProblemLogSearchForm();
        searchForm.departmentId = this.currentUser.departmentId;
        searchForm.workerId = "";
        searchForm.lastItemId = 0;
        searchForm.take = 5;
        return searchForm;
    };
    InboxComponent.prototype.showUserOnlyToggle = function (value) {
        this.showUserOnly = !this.showUserOnly;
        this.QueryByShowUserOnly();
    };
    InboxComponent.prototype.QueryByShowUserOnly = function () {
        this.searchFormGroup.reset();
        var searchForm = this.showUserOnly ? this.createUserOnlySearchForm() : this.createDepartmentOnlySearchForm();
        this.restoreSearchForm(searchForm);
        this.sendQuery(searchForm);
    };
    InboxComponent.prototype.sendQuery = function (searchForm) {
        var _this = this;
        this.preLoader.start();
        this.problemLogService.SetSearchForm(searchForm);
        this.problemLogService.Search().subscribe(function (response) {
            if (response.errcode == 0) {
                _this.queryMoreLastId = response.problemLogListItems.length == 0 ? _this.queryMoreLastId = 0
                    : _this.queryMoreLastId = response.problemLogListItems[response.problemLogListItems.length - 1].problemLogId;
                _this.problemLogs = response.problemLogListItems;
            }
            _this.infiniteScrollDisable = false;
            window.scroll(0, 0);
            _this.preLoader.stop();
        });
    };
    InboxComponent.prototype.resetSearch = function () {
        this.searchFormGroup.reset();
        var searchForm = new ProblemLogSearchForm();
        this.problemLogService.SetSearchForm(searchForm);
        this.querySubmit();
    };
    InboxComponent.prototype.querySubmit = function () {
        var searchForm = new ProblemLogSearchForm();
        searchForm.freeText = this.searchFormGroup.get('freeText').value == '' || this.searchFormGroup.get('freeText').value == undefined ? ''
            : this.searchFormGroup.get('freeText').value;
        searchForm.departmentId = this.searchFormGroup.get('department').value == '' || this.searchFormGroup.get('department').value == undefined ?
            0 : this.searchFormGroup.get('department').value.id;
        searchForm.overDueStartDay = this.searchFormGroup.get('overdue').value == '' || this.searchFormGroup.get('overdue').value == undefined ?
            0 : this.searchFormGroup.get('overdue').value.start;
        searchForm.overDueEndDay = this.searchFormGroup.get('overdue').value == '' || this.searchFormGroup.get('overdue').value == undefined ?
            0 : this.searchFormGroup.get('overdue').value.end;
        searchForm.problemId = this.searchFormGroup.get('problem').value == '' || this.searchFormGroup.get('problem').value == undefined ?
            0 : this.searchFormGroup.get('problem').value.problemId;
        searchForm.problemTypeId = this.searchFormGroup.get('problemType').value == '' || this.searchFormGroup.get('problemType').value == undefined ?
            0 : this.searchFormGroup.get('problemType').value.problemTypeId;
        searchForm.wfStatus = this.searchFormGroup.get('status').value == '' || this.searchFormGroup.get('status').value == undefined ?
            [] : this.searchFormGroup.get('status').value;
        searchForm.workerId = this.searchFormGroup.get('workerId').value == '' || this.searchFormGroup.get('workerId').value == undefined ?
            '' : this.searchFormGroup.get('workerId').value;
        searchForm.lastItemId = 0;
        searchForm.take = 5;
        this.sendQuery(searchForm);
        this.sidenav.hide();
    };
    InboxComponent.prototype.queryMore = function () {
        var _this = this;
        var searchForm = this.problemLogService.GetSearchForm();
        searchForm.lastItemId = this.queryMoreLastId;
        searchForm.take = 5;
        this.problemLogService.SetSearchForm(searchForm);
        this.problemLogService.Search().subscribe(function (response) {
            if (response.errcode == 0) {
                if (response.problemLogListItems.length > 0) {
                    _this.problemLogs = _this.problemLogs.concat(response.problemLogListItems);
                    _this.queryMoreLastId = response.problemLogListItems[response.problemLogListItems.length - 1].problemLogId;
                }
            }
        });
        this.sidenav.hide();
    };
    Object.defineProperty(InboxComponent.prototype, "showRefresherHeader", {
        get: function () {
            var showRefresher = this.touchStarting && this.touchStartY < 500 && (this.touchMoveY - this.touchStartY > 100);
            return showRefresher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InboxComponent.prototype, "refresherState", {
        get: function () {
            if (this.showRefresherHeader && !this.touchEnding)
                return '松开立即刷新...';
            if (this.showRefresherHeader && this.touchEnding)
                return '刷新中...';
        },
        enumerable: true,
        configurable: true
    });
    InboxComponent.prototype.touchStart = function (e) {
        this.touchStarting = true;
        this.touchStartY = e.touches[0].screenY;
    };
    InboxComponent.prototype.touchMove = function (e) {
        this.touchMoveY = e.touches[0].screenY;
    };
    InboxComponent.prototype.touchEnd = function (e) {
        var _this = this;
        this.touchEnding = true;
        if (this.showRefresherHeader) {
            this.querySubmit();
            setTimeout(function () {
                _this.touchStarting = false;
                _this.touchEnding = false;
                _this.touchStartY = 0;
                _this.touchMoveY = 0;
            }, 2000);
        }
        else {
            this.touchStartY = 0;
            this.touchMoveY = 0;
        }
    };
    InboxComponent.prototype.onUploadOutput = function (output) {
        if (output.type === 'allAddedToQueue') {
        }
        else if (output.type === 'addedToQueue') {
            this.files.push(output.file); // add file to array when added
            this.addImgUrl(output.file.nativeFile, output.file.name);
        }
        else if (output.type === 'uploading') {
            // update current data in files array for uploading file
            var index = this.files.findIndex(function (file) { return file.id === output.file.id; });
            this.files[index] = output.file;
        }
        else if (output.type === 'removed') {
            // remove file from array when removed
            this.files = this.files.filter(function (file) { return file !== output.file; });
        }
        else if (output.type === 'dragOver') {
            //this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
        }
        else if (output.type === 'drop') {
            // this.dragOver = false;
        }
    };
    InboxComponent.prototype.makeid = function () {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 5; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    };
    InboxComponent.prototype.onNewImage = function (newImg) {
        this.addImgUrl(newImg, this.makeid());
    };
    InboxComponent.prototype.addImgUrl = function (file, filename) {
        if (file == undefined)
            return;
        //var reader = new FileReader();
        //reader.readAsDataURL(file);
        //reader.onload = (_event) => {
        var completePhoto = new ProblemPhoto();
        //completePhoto.base64 = reader.result;
        completePhoto.base64 = file;
        completePhoto.name = filename;
        this.completePhotos.push(completePhoto);
        //}
    };
    InboxComponent.prototype.removeImagUrl = function (name) {
        this.completePhotos = this.completePhotos.filter(function (i) { return i.name !== name; });
    };
    InboxComponent.prototype.showFiles = function () {
        //return `總共 ${this.files.length} 檔案`;
        return "\u603B\u5171 " + this.completePhotos.length + " \u6863\u6848";
    };
    return InboxComponent;
}());
export { InboxComponent };
