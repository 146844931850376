<div  [hidden]="cropping.isCropped||cropping.isLoaded">
      <button ly-button color="primary" (click)="_fileInput.click()">
          <ly-icon>image</ly-icon>
          <span>照片</span>
        </button>
            <!-- Hidden input -->
        <input #_fileInput type="file" (change)="fileChangeEvent($event)" accept="image/*" hidden>
        <!--<button raised ly-button bg="accent" *ngIf="cropping.isCropped">Upload</button>-->

</div>
<div [hidden]="cropping.isCropped">
      <div *ngIf="cropping.isLoaded">
        <!--
        <button (click)="rotateRight()" ly-button appearance="Fab"><ly-icon>rotate_right</ly-icon></button>
        <button (click)="rotateLeft()" ly-button appearance="Fab"><ly-icon>rotate_left</ly-icon></button>--->
        <button *ngIf="cropping.isLoaded"  (click)="rotateLeft()" ly-button>
          <ly-icon>rotate_left</ly-icon>左转
        </button>
        <button *ngIf="cropping.isLoaded"  (click)="rotateRight()" ly-button>
          <ly-icon>rotate_right</ly-icon>右转
        </button>
        <button *ngIf="cropping.isLoaded" color="accent" (click)="crop()" ly-button>
          <ly-icon>crop</ly-icon>剪裁
        </button>
      </div>
      <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="16 / 9"
            [resizeToWidth]="500"
            outputType = "base64"
            format="png"
            (imageCropped)="imageCropped($event)"
            (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
      <!--<img [src]="croppedImage" hidden/>-->
  </div>  
  <div [hidden]="!cropping.isCropped"><app-draw-canvas></app-draw-canvas></div>
<!--
  <button color="accent" (click)="getImage()" ly-button>
      <ly-icon>download</ly-icon>download
    </button>
-->
