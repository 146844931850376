import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AssignTaskService = /** @class */ (function () {
    function AssignTaskService(http) {
        this.http = http;
    }
    AssignTaskService.prototype.Save = function (assignTaskForm) {
        var url = "/api/ProblemLog/AssignTask";
        return this.http.post(url, assignTaskForm);
    };
    AssignTaskService.ngInjectableDef = i0.defineInjectable({ factory: function AssignTaskService_Factory() { return new AssignTaskService(i0.inject(i1.HttpClient)); }, token: AssignTaskService, providedIn: "root" });
    return AssignTaskService;
}());
export { AssignTaskService };
