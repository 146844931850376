import { ProblemPhoto } from './problemPhoto';
import { ProblemLogListItem } from 'src/app/models/problemLogListItem';

export class CompleteTaskForm {
  createdBy: string;
  problemLogId: number;
  remark: string;
  completePhotos: ProblemPhoto[];
}

export class CompleteTaskFormResponse {
  errcode: number;
  errmsg: string;
  problemLogListItem: ProblemLogListItem;

}
