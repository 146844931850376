import * as i0 from "@angular/core";
var LocalDateService = /** @class */ (function () {
    function LocalDateService() {
    }
    LocalDateService.prototype.ToLocalDateString = function (jsonDate) {
        if (jsonDate == null)
            return "未有時間";
        var localDate = null;
        if (jsonDate.indexOf('Z') < 0 && jsonDate.indexOf('z') < 0)
            localDate = new Date(jsonDate + "Z");
        else
            localDate = new Date(jsonDate);
        var year = localDate.getFullYear();
        var month = localDate.getMonth() + 1;
        var day = localDate.getDate();
        var hour = localDate.getHours();
        var min = localDate.getMinutes();
        var monthStr = month <= 9 ? "0" + month.toString() : month;
        var dayStr = day <= 9 ? "0" + day.toString() : day;
        var hourStr = hour <= 9 ? "0" + hour.toString() : hour;
        var minStr = min <= 9 ? "0" + min.toString() : min;
        return year + "-" + monthStr + "-" + dayStr + " " + hourStr + ":" + minStr;
    };
    LocalDateService.ngInjectableDef = i0.defineInjectable({ factory: function LocalDateService_Factory() { return new LocalDateService(); }, token: LocalDateService, providedIn: "root" });
    return LocalDateService;
}());
export { LocalDateService };
