import { CorpMapResponse } from '../models/corpMap';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CorpMapService = /** @class */ (function () {
    function CorpMapService(http) {
        this.http = http;
    }
    CorpMapService.prototype.getMap = function (wechatCropId) {
        var url = "/api/CorpMap/GetMap?wechatCropId=" + wechatCropId;
        return this.http.get(url);
    };
    CorpMapService.ngInjectableDef = i0.defineInjectable({ factory: function CorpMapService_Factory() { return new CorpMapService(i0.inject(i1.HttpClient)); }, token: CorpMapService, providedIn: "root" });
    return CorpMapService;
}());
export { CorpMapService };
