<div class="iphonex-height">


  <div style="margin-top:60px" class="row" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [immediateCheck]="false" [infiniteScrollDisabled]="infiniteScrollDisable" (scrolled)="queryMore()">

    <div *ngIf="showRefresherHeader" class="col-12">
      <div class="row justify-content-center">
        <div style="text-align:center">{{refresherState}}</div>
      </div>
    </div>
    <div *ngIf="problemLogs.length==0" style="text-align:center;">
      <h1>没有记录...</h1>
    </div>
    <div *ngFor="let p of problemLogs" class="col-lg-6 col-xl-6 col-12">
      <div class="card card-cascade cascading-admin-card" style="margin-bottom:30px;">
        <div class="admin-up">
          <div class="row">
            <div class="col-3">
              <mdb-icon fas icon="{{p.problemTypeIconCss}}" class="primary-color" style="margin-bottom:10px"></mdb-icon>
            </div>
            <div class="col" style="padding-top:25px;">
              <span>{{p.displayHeader}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mdb-carousel [animation]="'slide'" #carousel [interval]="'0'">
                <mdb-carousel-item *ngFor="let i of p.problemLogPhotos">
                  <img class="d-block w-100" [src]="i.imageSrc">
                </mdb-carousel-item>
              </mdb-carousel>
            </div>
          </div>

          <div class="row justify-content-start mx-1">
            <div *ngIf="p.status == 'PendingPick' && currentUser.userId == p.workerId" class="col-3 p-1">
              <button (click)="pickClicked(p)" type="button" class="btn btn-success btn-block">
                <i placement="top" mdbWavesEffect class="far fa-check-circle fa-1x"></i>接收
              </button>
            </div>

            <div *ngIf="p.status == 'Working' && currentUser.userId == p.workerId" class="col-3 p-1">
              <button (click)="completeClicked(p)" type="button" class="btn btn-success btn-block">
                <i placement="top" mdbWavesEffect class="far fa-check-circle fa-1x"></i>完成
              </button>
            </div>

            <div *ngIf="currentUserCanCancel() && p.status !='Completed' && p.status !='Cancelled'" class="col-3 p-1">
              <button type="button" (click)="cancelClicked(p)" class="btn btn-danger btn-block">
                <i placement="top" mdbWavesEffect class="fas fa-ban fa-1x"></i> 取消
              </button>
            </div>
            <div *ngIf="currentUserCanAssign() && p.status != 'Completed' && p.status !='Cancelled'" class="col-3 p-1">
              <button type="button" (click)="assignClicked(p)" class="btn btn-primary btn-block">
                <i placement="top" mdbWavesEffect class="fas fa-child fa-1x"></i> 指派
              </button>
            </div>
            <div class="col-3 p-1">
              <button type="button" (click)="showMapClicked(p)" class="btn btn-info btn-block">
                <i placement="top" mdbWavesEffect class="fas fa-map-marked-alt fa-1x"></i> 地图
              </button>
            </div>
            <div class="col-3 p-1">
              <button mdbBtn type="button" color="brown" routerLink="/problemLogDetail/{{p.problemLogId}}" class="btn btn-block">
                <i placement="top" mdbWavesEffect class="fas fa-info fa-1x"></i> 详情
              </button>
            </div>
          </div>

          <div class="data">
            <p *ngIf="!p.targetCompleteDate || p.targetCompleteDate == null"> 创建日期: {{getLocalDate(p.createdDate)}}</p>
            <p *ngIf="p.targetCompleteDate && p.status != 'Completed'"> 预计完成日期: {{getLocalDate(p.targetCompleteDate)}}</p>
            <P *ngIf="p.status== 'Completed'">完成日期: {{getLocalDate(p.completeDate)}}</P>
            <h5><strong>{{p.displayProgressText}}</strong></h5> 
          </div>
        </div>
        <div class="card-body">
          <div class="progress">
            <div [ngClass]="p.progressStatusCss" role="progressbar" [ngStyle]="getProgressStyle(p.progress)"
                 aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div class="row">
            <div class="col"><p class="card-text">{{p.displayTotalDaysText}}</p></div>
          </div>

          <p *ngIf="p.workerName" style="text-align:right" class="card-text">{{p.departmentName}}/{{p.workerName}}</p>
          <p *ngIf="!p.workerName" style="text-align:right" class="card-text">{{p.departmentName}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!--top menu-->
<div style="color:#fff" class="row justify-content-end fixed-top  deep-purple darken-2">
  <div class="col-6" style="margin-right:0px!important;padding-right:0px!important;margin-top:15px!important; text-align:right;">

    部门<mat-slide-toggle [color]="'accent'" (change)="showUserOnlyToggle($event.value)" [checked]="showUserOnly">个人</mat-slide-toggle>

  </div>
  <div class="col-2" style="margin-right:10px;padding-left:5px;">
    <a mdbBtn floating="true" size="sm" gradient="purple" (click)="sideNavToggle()" mdbWavesEffect>
      <i class="fas fa-search"></i>
    </a>
  </div>
</div>
<!--top menu-->

<app-navigation></app-navigation>
<!--side menu-->
<mdb-side-nav class="side-nav-light fixed" #sidenav [fixed]="false">
  <form [formGroup]="searchFormGroup">
    <ul>
      <li>
        <div class="form-group md-form waves-light" mdbWavesEffect>
          <input type="text" class="form-control" formControlName="freeText" placeholder="关键字搜索">
        </div>
      </li>
    </ul>

    <div style="padding-left:2rem;">
      <mat-form-field>
        <mat-label>部门</mat-label>
        <mat-select placehorder="部门" formControlName="department" #departmentSelect>
          <mat-option>None</mat-option>
          <mat-option *ngFor="let d of departments" [value]="d">
            {{d.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>类型</mat-label>
        <mat-select #problemTypeSelect placehorder="类型" formControlName="problemType">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let pt of problemTypes" [value]="pt" (click)="selectedProblemType(pt)">
            {{pt.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>常见问题</mat-label>
        <mat-select #problemSelect matNativeControl placehorder="常见问题" formControlName="problem">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let p of problems" [value]="p">
            {{p.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>逾期任务</mat-label>
        <mat-select #problemSelect matNativeControl placehorder="逾期任务" formControlName="overdue">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let o of overudes" [value]="o">
            {{o.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="状态" formControlName="status" multiple>
          <mat-option *ngFor="let s of statuses" [value]="s.value">{{s.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <button style="padding:0.5rem" mdbBtn color="grey" data-dismiss="mdbModal" (click)="resetSearch()" class="waves-light"
                mdbWavesEffect>
          重設
          <i class="fas fa-times ml-1"></i>
        </button>
        <button style="padding:0.5rem" mdbBtn color="green" class="waves-light" (click)="querySubmit()" mdbWavesEffect>
          搜索
          <i class="far fa-paper-plane ml-1"></i>
        </button>
      </div>
    </div>
  </form>


</mdb-side-nav>
<!--side menu-->
<!--show map-->
<div mdbModal #showMapModal="mdbModal" [config]="{ignoreBackdropClick:true}" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal" role="document">
    <div class="modal-content">
      <div class="modal-header light-blue darken-3 white-text">
        <h4 class="title">
          <i class="fas fa-users"></i>任务位置
        </h4>
        <span class="title">{{selectedProblemLogItem.displayHeader}}</span>
      </div>
      <div class="modal-body">
        <div *ngIf="selectedProblemLogItem" class="md-form" style="margin-bottom:0px;">
          <iframe [src]="mapIframeSrc | safe" [attr.height]="ifarmHeight" frameborder="0"
                  style="width:100%;overflow:hidden"></iframe>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="primary" class="waves-light" (click)="onCloseMap()" mdbWavesEffect>
          离开
          <i class="far fa-check-circle"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<!--show map-->
<!--assign task-->
<div mdbModal #assignModal="mdbModal" [config]="{ignoreBackdropClick:true}" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal" role="document">
    <div class="modal-content">
      <div class="modal-header light-blue darken-3 white-text">
        <h4 class="title">
          <i class="fas fa-users"></i>指派任务
        </h4>
        <span class="title">{{selectedProblemLogItem.displayHeader}}</span>
      </div>
      <form [formGroup]="assignTaskFormGroup">
        <div class="modal-body mx-3">
          <div class="md-form md-5" style="margin-bottom:0px;">
            <i class="fas fa-pencil-alt prefix grey-text"></i>
            <mat-form-field style="margin-left:40px;width:80%;">
              <mat-label>负责员工</mat-label>
              <input type="text" placeholder="负责员工" aria-label="负责员工" matInput [matAutocomplete]="auto" formControlName="worker" required>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option *ngFor="let s of filteredStaffs | async" [value]="s">
                    {{s.name}}
                  </mat-option>
                </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="md-form md-5" style="width:80%">
            <i class="far fa-calendar-check prefix grey-text"></i>
            <mdb-date-picker #assignDatePicker mdbValidate style="margin-left:40px" name="mydate" [options]="myDatePickerOptions"
                             formControlName="targetCompleteDate" [placeholder]="'预计完成日期'" required>
            </mdb-date-picker>
            <mdb-error *ngIf="assignDatePicker.invalid && (assignDatePicker.dirty || assignDatePicker.touched)">Input invalid</mdb-error>
            <mdb-success *ngIf="assignDatePicker.valid && (assignDatePicker.dirty || assignDatePicker.touched)">Input valid</mdb-success>
          </div>
          <div class="md-form md-5" style="width:80%">
            <i class="far fa-clock prefix grey-text"></i>
            <mdb-time-picker #assignTimePicker mdbValidate [buttonLabel]="'Done'" [twelvehour]="false" [darktheme]="false" [placeholder]="'完成时间'"
                             formControlName="targetCompleteTime" required></mdb-time-picker>
            <mdb-error *ngIf="assignTimePicker.invalid && (assignTimePicker.dirty || assignTimePicker.touched)">Input invalid</mdb-error>
            <mdb-success *ngIf="assignTimePicker.valid && (assignTimePicker.dirty || assignTimePicker.touched)">Input valid</mdb-success>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button mdbBtn color="grey" data-dismiss="mdbModal" (click)="assignModal.hide()" class="waves-light"
                  mdbWavesEffect>
            取消
            <i class="fas fa-times ml-1"></i>
          </button>
          <button type="submit" mdbBtn color="green" class="waves-light" (click)="assignSubmit()" mdbWavesEffect>
            完成
            <i class="far fa-paper-plane ml-1"></i>
          </button>
        </div>
      </form>

    </div>
  </div>
</div>
<!--assign task-->
<!--Cancel Task-->
<div mdbModal #cancelModal="mdbModal" [config]="{ignoreBackdropClick:true}" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content text-center">
      <!--Header-->
      <div class="modal-header d-flex justify-content-center">
        <p class="heading">确定取消?</p>
      </div>

      <!--Body-->
      <form [formGroup]="cancelTaskFormGroup">
        <div class="modal-body">
          <i class="fas fa-times fa-4x animated rotateIn"></i>
          <div class="md-form mb-5">
            <mat-form-field>
              <textarea matInput rows="3" formControlName="reason" placeholder="原因"></textarea>
            </mat-form-field>
          </div>
        </div>
      </form>


      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a style="width:70px" type="button" mdbBtn color="danger" class="waves-effect" mdbWavesEffect
           data-dismiss="modal" (click)="cancelModal.hide()">否</a>
        <a style="width:70px" type="button" mdbBtn color="danger" outline="true" class="waves-effect" mdbWavesEffect
           (click)="cancelSubmit()">是</a>

      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Cancel Task-->
<!--Pick Task-->
<div mdbModal #pickModal="mdbModal" [config]="{ignoreBackdropClick:true}" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-notify modal-success" role="document">
    <!--Content-->
    <div class="modal-content text-center">
      <!--Header-->
      <div class="modal-header d-flex justify-content-center">
        <p class="heading">确定接收任务?</p>
      </div>

      <!--Body-->
      <form>
        <div class="modal-body">
          <i class="fas fa-check fa-4x"></i>
          <p>{{selectedProblemLogItem.displayHeader}}</p>
          <p>预计完成日期: {{getLocalDate(selectedProblemLogItem.targetCompleteDate)}}</p>
        </div>
      </form>


      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a style="width:70px" type="button" mdbBtn color="success" class="waves-effect" mdbWavesEffect
           data-dismiss="modal" (click)="pickModal.hide()">否</a>
        <a style="width:70px" type="submit" mdbBtn color="success" outline="true" class="waves-effect"
           (click)="pickSubmit()" mdbWavesEffect>是</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Pick Task-->
<!--Complete Task-->
<div mdbModal #completeModal="mdbModal" style="overflow:scroll" [config]="{ignoreBackdropClick:true}" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal" role="document">
    <div class="modal-content">
      <div class="modal-header light-blue darken-3 white-text">
        <h4 class="title">
          <i class="fas fa-users"></i>完成任务
        </h4>
        <span class="title">{{selectedProblemLogItem.displayHeader}}</span>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="completeModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="completeTaskFormGroup">
        <div class="modal-body mx-3">
          <div class="md-form mb-5">
            <i style="margin-left:15px;margin-top:24px;" class="fas fa-pencil-alt prefix grey-text"></i>
            <mat-form-field style="margin-left:75px;">
              <textarea matInput rows="3" formControlName="remark" placeholder="备注"></textarea>
            </mat-form-field>
          </div>
          <div class="md-form mb-5">
            <div class="file-field md-form">
              <add-image-dialog (addImage)="onNewImage($event)"></add-image-dialog>
              <div class="file-path-wrapper mt-3">
                <input class="file-path" type="text" placeholder="Upload your file" [value]="showFiles()">
              </div>
            </div>
          </div>
          <div class="md-form mb-5">
            <figure *ngFor="let photo of completePhotos" style="vertical-align:top;text-align:center;display: inline-block;">
              <div class="imageContainer">
                <button class="x" (click)="removeImagUrl(photo.name)">
                  X
                </button>
                <img [src]="photo.base64" style="margin: 0px;height:120px" caption>
              </div>
            </figure>
          </div>

        </div>
      </form>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="grey" data-dismiss="mdbModal" (click)="completeModal.hide()" class="waves-light"
                mdbWavesEffect>
          取消
          <i class="far fa-paper-plane ml-1"></i>
        </button>
        <button mdbBtn color="green" class="waves-light" (click)="completeSubmit()" mdbWavesEffect>
          完成
          <i class="far fa-paper-plane ml-1"></i>
        </button>

      </div>
    </div>
  </div>
</div>
<!--Complete Task-->
<!--Loading bar-->
<div *ngIf="isLoadingMore" class="spinning-preloader-container overlay" style="opacity:0.8!important">
  <div>
    <div class="preloader-wrapper big active">
      <div class="spinner-layer spinner-blue">
        <div class="circle-clipper left">
          <div class="circle"> </div>
        </div>
        <div class="gap-patch">
          <div class="circle"> </div>
        </div>
      </div>

      <div class="spinner-layer spinner-red">
        <div class="circle-clipper left">
          <div class="circle"> </div>
        </div>
        <div class="gap-patch">
          <div class="circle"> </div>
        </div>
      </div>

      <div class="spinner-layer spinner-yellow">
        <div class="circle-clipper left">
          <div class="circle"> </div>
        </div>
        <div class="gap-patch">
          <div class="circle"> </div>
        </div>
      </div>

      <div class="spinner-layer spinner-green">
        <div class="circle-clipper left">
          <div class="circle"> </div>
        </div>
        <div class="gap-patch">
          <div class="circle"> </div>
        </div>
      </div>
    </div>
  </div>

</div>
<!--Loading bar-->
