import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { IMyOptions, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { UploadFile, UploadInput, UploadOutput, ToastService } from 'ng-uikit-pro-standard';
import { humanizeBytes } from 'ng-uikit-pro-standard';

import { ProblemType } from 'src/app/models/problemType';
import { Department } from 'src/app/models/department';
import { Problem } from 'src/app/models/problem';
import { ProblemArea } from 'src/app/models/problemArea';
import { NewProblemLog } from 'src/app/models/newProblemLog';
import { ProblemPhoto } from 'src/app/models/problemPhoto';

import { ProblemTypeService } from 'src/app/services/problem-type.service';
import { DepartmentService } from 'src/app/services/department.service';
import { ProblemAreaService } from 'src/app/services/problem-area.service';
import { NewProblemLogService } from 'src/app/services/new-problem-log.service';
import { AuthService } from 'src/app/services/auth-service.service';

import '../../interfaces/window';
import '../../interfaces/floor';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-create-work-order',
  templateUrl: './create-work-order.component.html',
  styleUrls: ['./create-work-order.component.scss']
})



export class CreateWorkOrderComponent implements OnInit {
  @ViewChild('problemSelect') problemSelect: any;
  @ViewChild('problemTypeSelect') problemTypeSelect: any;
  @ViewChild('problemDescriptionTextArea') problemDescriptionTextArea: any;
  @ViewChild('departmentSelect') departmentSelect: any;
  @ViewChild('uploadFileInput') uploadFileInput: any;




  floorId: any;
  floorName: any;
  featureId: any;
  featureName: any;
  problemTypes: ProblemType[];
  departments: Department[];
  problems: Problem[];
  problemAreas: ProblemArea[];
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  problemPhotos: ProblemPhoto[] = [];
  myDatePickerOptions: IMyOptions = {};
  isLinear = true;
  stepOneForm: FormGroup;
  stepTwoForm: FormGroup;
  stepThreeForm: FormGroup;
  currentUser: User;
  toastOption: any = { positionClass: 'toast-center' };

  constructor(public fb: FormBuilder,
    private problemTypeService: ProblemTypeService, private departmentService: DepartmentService,
    private route: ActivatedRoute, private problemAreaService: ProblemAreaService, private newProblemLogService: NewProblemLogService,
    private authService: AuthService, private preLoader: MDBSpinningPreloader, private toastService: ToastService,
    private router: Router,
  ) {
    this.preLoader.stop();

    if (window.currentFloorId == undefined || window.currentFeatureId == undefined) {
      this.router.navigateByUrl('workOrderMap');
    }
    this.featureId = window.currentFeatureId;
    this.featureName = window.currentFeatureName;
    this.floorId = window.currentFloorId;
    this.floorName =window.currentFloorName
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
    this.stepOneForm = fb.group({
      floorId: ['', Validators.required],
      floorName: ['', Validators.required],
      featureId: ['', Validators.required],
      featureName: ['', Validators.required],
    })
    this.stepTwoForm = fb.group({
      problemArea: [''],
      problemType: ['', Validators.required],
      problem: ['', Validators.required],
      problemDescription: [''],
      department: ['', Validators.required]
    })
    this.stepThreeForm = fb.group({
    });
  }


  

  ngOnInit() {
    this.authService.currentUser.subscribe(u => this.currentUser = u);
    this.getProblemTypes();
    this.getDepartments();
    this.getProblemAreas();
    this.fillStepOneForm();
  }
  onNext(step:any) {
    if (step.selectedIndex == 3) {
      this.submit();
    }
  }
  get problemArea(): string {
    if (this.stepTwoForm.get('problemArea').value == null)
      return ''
    return this.stepTwoForm.value.problemArea.name
  }
  fillStepOneForm() {
    this.stepOneForm.patchValue({
      floorId: this.floorId,
      floorName: this.floorName,
      featureId: this.featureId,
      featureName: this.featureName
    });
  }
  getProblemAreas(): void {
    this.problemAreaService.getAll().then(response => this.problemAreas = response.problemAreas)
  }
  getProblemTypes(): void {
    this.problemTypeService.getAll().then(response => this.problemTypes = response.problemTypes);
  }
  getDepartments(): void {
    this.departmentService.getAll(this.currentUser.userId).then(response => this.departments = response.departments);
  }

  focusToProblemDescription() {
    setTimeout(() => {
      this.problemDescriptionTextArea.nativeElement.focus();
    }, 500);

  }
  
  openFileUpload() {
    /*setTimeout(() => {
      this.uploadFileInput.nativeElement.click();
    }, 500);*/
  }
  openDepartment() {
    setTimeout(() => {
      this.departmentSelect.open();
    }, 500);

  }
  openProblemType() {
    setTimeout(() => {
      this.problemTypeSelect.open()
    }, 500);
  }
  selectedProblemType(pt: ProblemType) {
    this.problems = pt.problems;
    this.openProblemSelect();
  }
  openProblemSelect() {
    setTimeout(() => {
      this.problemSelect.open();
    }, 500);
  }
  public submit() {
    var newProblemLog = new NewProblemLog();

    newProblemLog.createdBy = this.currentUser.userId;
    newProblemLog.floorId = this.stepOneForm.value.floorId;
    newProblemLog.floorName = this.stepOneForm.value.floorName;
    newProblemLog.featureId = this.stepOneForm.value.featureId;
    newProblemLog.featureName = this.stepOneForm.value.featureName;
    newProblemLog.departmentId = this.stepTwoForm.value.department.id;
    newProblemLog.problemId = this.stepTwoForm.value.problem.problemId;
    newProblemLog.problemTypeId = this.stepTwoForm.value.problemType.problemTypeId;
    newProblemLog.problemAreaId = this.stepTwoForm.value.problemArea.problemAreaId;
    newProblemLog.description = this.stepTwoForm.value.problemDescription;
    newProblemLog.problemPhotos = this.problemPhotos;

    this.newProblemLogService.Save(newProblemLog).subscribe(response => {
      if (response.errcode == 0) {
        this.toastService.success("任务保存成功！", "", this.toastOption);
        window.currentFeatureId = undefined;
        window.currentFeatureName = undefined;
        window.currentFloorId = undefined;
        window.currentFloorName = undefined;
        this.stepOneForm.reset();
        this.stepTwoForm.reset();
      }
      if (response.errcode > 0) {
        this.toastService.error("任务保存失敗: " + response.errmsg, "", this.toastOption);
      }
    });
  }

  showFiles() {
    //return `總共 ${this.files.length} 檔案`;
    return `总共 ${this.problemPhotos.length} 档案`;
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: 'your-path-to-backend-endpoint',
      method: 'POST',
      data: { foo: 'bar' },
    };
    this.files = [];
    this.problemPhotos = []
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  onUploadOutput(output: UploadOutput | any): void {

    if (output.type === 'allAddedToQueue') {
    } else if (output.type === 'addedToQueue') {
      this.files.push(output.file); // add file to array when added
      this.addImgUrl(output.file.nativeFile, output.file.name);
    } else if (output.type === 'uploading') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      //this.dragOver = true;
    } else if (output.type === 'dragOut') {
    } else if (output.type === 'drop') {
      // this.dragOver = false;
    }

  }

  makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
  }

  onNewImage(newImg: Blob) {
    this.addImgUrl(newImg, this.makeid());
  }

  public addImgUrl(file: Blob, filename: string) {

    if (file == undefined)
      return;

    /*
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      var problemPhoto = new ProblemPhoto();
      problemPhoto.base64 = reader.result;
      problemPhoto.name = filename;
      this.problemPhotos.push(problemPhoto);
    }
    */
   var problemPhoto = new ProblemPhoto();
   problemPhoto.base64 = file;
   problemPhoto.name = filename;
   this.problemPhotos.push(problemPhoto);
  }

  public removeImagUrl(name: string) {
    this.problemPhotos = this.problemPhotos.filter((i: any) => i.name !== name);
  }

}
