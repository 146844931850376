import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardsComponent } from './views/dashboards/dashboards.component';
import { WorkOrderMapComponent } from './views/work-order-map/work-order-map.component';
import { InboxComponent } from './views/inbox/inbox.component';
import { CreateWorkOrderComponent } from './views/create-work-order/create-work-order.component';
import { InnerMapComponent } from './views/inner-map/inner-map.component';
import { BlankViewComponent } from './views/blank-view/blank-view.component';
import { ProblemLogDetailComponent } from './views/problem-log-detail/problem-log-detail.component';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboards' },
  { path: 'dashboards', component: DashboardsComponent },
  { path: 'workOrderMap', component: WorkOrderMapComponent },
  { path: 'inbox', component: InboxComponent },
  { path: 'createProblemLog', component: CreateWorkOrderComponent },
  { path: 'innerMap/:floorId/:featureId', component: InnerMapComponent },
  { path: 'blank', component: BlankViewComponent },
  { path: 'problemLogDetail/:id', component: ProblemLogDetailComponent },
  { path: 'unauthorized', component: UnauthorizedComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
