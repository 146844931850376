import { Injectable } from '@angular/core';
import { localDataFactory } from 'ng-uikit-pro-standard';

@Injectable({
  providedIn: 'root'
})
export class LocalDateService {

  constructor() { }
  public ToLocalDateString(jsonDate: string): string {
    if (jsonDate == null)
      return "未有時間";
    var localDate = null;

    if (jsonDate.indexOf('Z') < 0 && jsonDate.indexOf('z') < 0)
      localDate = new Date(jsonDate + "Z");
    else
      localDate = new Date(jsonDate);

    var year = localDate.getFullYear();
    var month = localDate.getMonth() + 1;
    var day = localDate.getDate();
    var hour = localDate.getHours();
    var min = localDate.getMinutes();

    var monthStr = month <= 9 ? "0" + month.toString():month;
    var dayStr = day <= 9 ? "0" + day.toString() : day;
    var hourStr = hour <= 9 ? "0" + hour.toString() : hour;
    var minStr = min <= 9 ? "0" + min.toString() : min;

     return year + "-" + monthStr + "-" + dayStr + " " + hourStr + ":" + minStr;
  }
}
