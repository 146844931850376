import { Injectable} from '@angular/core';
import { CancelTaskForm, CancelTaskFormResponse } from '../models/cancelTaskForm';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CancelTaskService {
  baseUrl: string

  constructor(private http: HttpClient) {
  }

  public Save(cancelTaskForm: CancelTaskForm): Observable<CancelTaskFormResponse> {
    let url = "/api/ProblemLog/CancelTask";

    return this.http.post<CancelTaskFormResponse>(url, cancelTaskForm);
  }
}
