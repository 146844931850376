<nav class="navbar navbar-expand deep-purple darken-2 fixed-bottom">
    <a class="navbar-brand" href="#" routerLink="/dashboards">任务状态</a>
    <a class="navbar-brand" href="#" routerLink="/workOrderMap">商场地图</a>
    <a class="navbar-brand" href="#" routerLink="/inbox">任务清单</a>
    <ul class="navbar-nav ml-auto nav-flex-icons">
      <li class="nav-item avatar dropdown">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
           aria-expanded="false">
          <img *ngIf="currentUser" [src]="currentUser.avatar" class="rounded-circle z-depth-0">
        </a>
      </li>
    </ul>
</nav>

