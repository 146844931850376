/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inner-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./inner-map.component";
import * as i3 from "@angular/router";
import * as i4 from "ng-uikit-pro-standard";
var styles_InnerMapComponent = [i0.styles];
var RenderType_InnerMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InnerMapComponent, data: {} });
export { RenderType_InnerMapComponent as RenderType_InnerMapComponent };
export function View_InnerMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["id", "map"], ["style", "width:100%;height:120%!important;margin-top:-100px;margin-left:-20px;padding: 0; position: absolute;"]], null, null, null, null, null))], null, null); }
export function View_InnerMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inner-map", [], null, null, null, View_InnerMapComponent_0, RenderType_InnerMapComponent)), i1.ɵdid(1, 114688, null, 0, i2.InnerMapComponent, [i3.ActivatedRoute, i4.MDBSpinningPreloader], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InnerMapComponentNgFactory = i1.ɵccf("app-inner-map", i2.InnerMapComponent, View_InnerMapComponent_Host_0, { location: "location" }, {}, []);
export { InnerMapComponentNgFactory as InnerMapComponentNgFactory };
