export class ProblemArea{
    problemAreaId: number;
    name: string;
    iconCss:string;
}

export class ProblemAreaResponse {
  errcode: number;
  errmsg: string;
  problemAreas:ProblemArea[]
}
