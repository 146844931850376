/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unauthorized.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./unauthorized.component";
import * as i4 from "../../services/auth-service.service";
import * as i5 from "ng-uikit-pro-standard";
var styles_UnauthorizedComponent = [i0.styles];
var RenderType_UnauthorizedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnauthorizedComponent, data: {} });
export { RenderType_UnauthorizedComponent as RenderType_UnauthorizedComponent };
function View_UnauthorizedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u4F60\u7684\u90E8\u95E8\u6CA1\u6709\u4F7F\u7528\u6743\u9650"]))], null, null); }
function View_UnauthorizedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u516C\u53F8\u672A\u8BBE\u7F6E\u5730\u56FE"]))], null, null); }
export function View_UnauthorizedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u4F60\u7684\u8D26\u53F7\u6C92\u6709\u4F7F\u7528\u6743\u9650"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnauthorizedComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnauthorizedComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.currentUser.isAllowedDepartment; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.currentUser.hasCorpMap; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_UnauthorizedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unauthorized", [], null, null, null, View_UnauthorizedComponent_0, RenderType_UnauthorizedComponent)), i1.ɵdid(1, 114688, null, 0, i3.UnauthorizedComponent, [i4.AuthService, i5.MDBSpinningPreloader], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnauthorizedComponentNgFactory = i1.ɵccf("app-unauthorized", i3.UnauthorizedComponent, View_UnauthorizedComponent_Host_0, {}, {}, []);
export { UnauthorizedComponentNgFactory as UnauthorizedComponentNgFactory };
