import { Injectable } from '@angular/core';
import { CompleteTaskForm, CompleteTaskFormResponse } from '../models/completeTaskForm';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CompleteTaskService {


  constructor(private http: HttpClient) {
  }

  public Save(completeTaskForm: CompleteTaskForm): Observable<CompleteTaskFormResponse> {
    let url = "/api/ProblemLog/CompleteTask";

    return this.http.post<CompleteTaskFormResponse>(url, completeTaskForm);
  }
}
