

<div class="iphonex-height">
  <div id="map" style="width:calc(100% - 30px);height:85%;padding:100px!important;margin-right:15px!important; padding-right:15px!important; margin-left:0px!important ;position:fixed;left:15px;"></div>

  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 mx-auto mt-5">
        <h4 *ngIf="!selectedShopName" class="text-center">请选择楼层和店铺位置</h4>
        <h4 *ngIf="selectedShopName" class="text-center">已选地点</h4>
        <h4 *ngIf="selectedShopName" class="text-center">{{selectedShopName}}, {{selectedFloorName}}</h4>

        <div class="d-flex justify-content-center">
          <button type="button" mdbBtn color="primary" class="relative waves-light" *ngIf="selectedShopName!=undefined" (click)="gotoCreateForm()"
                  mdbWavesEffect>
            创建任务
          </button>
        </div>

      </div>
    </div>
  </div>
</div>

<app-navigation></app-navigation>
