import { ProblemLogListItem } from 'src/app/models/problemLogListItem';

export class PickTaskForm {
  createdBy: string;
  problemLogId: number;
  workerId: string;
}
export class PickTaskFormResponse {
  errcode: number;
  errmsg: string;
  problemLogListItem: ProblemLogListItem;


}
