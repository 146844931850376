import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NewProblemLogResponse, NewProblemLog } from 'src/app/models/newProblemLog';

@Injectable({
  providedIn: 'root'
})
export class NewProblemLogService {
  baseUrl: string

  constructor(private http: HttpClient) {
  }

  public Save(newProblemLog: NewProblemLog): Observable<NewProblemLogResponse> {
    let url = "/api/ProblemLog/CreateProblemLog";
    return this.http.post<NewProblemLogResponse>(url, newProblemLog);
  }
}
