import { HttpClient } from '@angular/common/http';
import { ProblemLogSearchForm } from 'src/app/models/problemLogSearchForm';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProblemLogService = /** @class */ (function () {
    function ProblemLogService(http) {
        this.http = http;
    }
    ProblemLogService.prototype.SetSearchForm = function (searchForm) {
        localStorage.setItem('searchForm', JSON.stringify(searchForm));
    };
    ProblemLogService.prototype.GetSearchForm = function () {
        var searchForm = Object.assign(new ProblemLogSearchForm, JSON.parse(localStorage.getItem('searchForm')));
        return searchForm;
    };
    ProblemLogService.prototype.Search = function () {
        var url = "api/ProblemLog/Query";
        var searchForm = this.GetSearchForm();
        return this.http.post(url, searchForm);
    };
    ProblemLogService.prototype.GetDetailById = function (id) {
        var url = "api/ProblemLog/ProblemLogDetail/" + id;
        return this.http.get(url);
    };
    ProblemLogService.prototype.getAll = function () {
        var url = "api/ProblemLog";
        return this.http.get(url);
    };
    ProblemLogService.prototype.getMore = function (last, num) {
        var url = "api/ProblemLog/GetMore";
        var data = {
            lastId: last.problemLogId,
            num: num
        };
        return this.http.post(url, data);
    };
    ProblemLogService.ngInjectableDef = i0.defineInjectable({ factory: function ProblemLogService_Factory() { return new ProblemLogService(i0.inject(i1.HttpClient)); }, token: ProblemLogService, providedIn: "root" });
    return ProblemLogService;
}());
export { ProblemLogService };
