import { ProblemAreaResponse } from '../models/problemArea';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProblemAreaService = /** @class */ (function () {
    function ProblemAreaService(http) {
        this.http = http;
    }
    ProblemAreaService.prototype.getAll = function () {
        var _this = this;
        var url = "api/ProblemArea";
        return new Promise(function (resolve) {
            if (_this.hasCache()) {
                resolve(_this.getCache());
            }
            else {
                _this.http.get(url).subscribe(function (response) {
                    _this.setCache(response);
                    resolve(response);
                });
            }
        });
    };
    ProblemAreaService.prototype.hasCache = function () {
        var hasCache = localStorage.getItem('problemAreaResponse') != null || localStorage.getItem('problemAreaResponse') != undefined;
        return hasCache;
    };
    ProblemAreaService.prototype.getCache = function () {
        var problemAreaResponse = Object.assign(new ProblemAreaResponse, JSON.parse(localStorage.getItem('problemAreaResponse')));
        return problemAreaResponse;
    };
    ProblemAreaService.prototype.setCache = function (problemAreaResponse) {
        localStorage.setItem('problemAreaResponse', JSON.stringify(problemAreaResponse));
    };
    ProblemAreaService.ngInjectableDef = i0.defineInjectable({ factory: function ProblemAreaService_Factory() { return new ProblemAreaService(i0.inject(i1.HttpClient)); }, token: ProblemAreaService, providedIn: "root" });
    return ProblemAreaService;
}());
export { ProblemAreaService };
