var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
var InnerMapComponent = /** @class */ (function () {
    function InnerMapComponent(route, preLoader) {
        this.route = route;
        this.preLoader = preLoader;
        this.mapId = 3584;
        this.appKey = "5698ed6e51754a7f9e1c39ec90a1ce24";
        this.lastLayer = null;
        this.lastColor = null;
    }
    InnerMapComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.preLoader.stop();
                        this.featureId = this.route.snapshot.paramMap.get('featureId');
                        this.floorId = this.route.snapshot.paramMap.get('floorId');
                        window.map = new window.NGR.View('map', {
                            appKey: this.appKey,
                        });
                        window.res = new window.NGR.DataSource({
                            appKey: this.appKey,
                        });
                        window.layers = {};
                        window.lastLayer = null;
                        this.control = window.NGR.control.floor();
                        return [4 /*yield*/, this.buildMap()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    InnerMapComponent.prototype.buildMap = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var control = _this.control;
            var loadPlan = _this.loadPlanarGraph;
            var floorId = _this.floorId;
            var featureId = _this.featureId;
            window.map._core_map.dragging.disable();
            window.map._core_map.doubleClickZoom.disable();
            window.map._core_map.touchZoom.disable();
            window.map._core_map.scrollWheelZoom.disable();
            window.res.requestMap(_this.mapId).then(function (mapInfo) {
                window.res.requestPOIChildren(mapInfo.poi).then(function (floors) {
                    window.map.addControl(control);
                    window.floors = floors;
                    control.setFloorList(floors.reverse());
                    control.setCurrentFloor(floorId, loadPlan);
                    loadPlan(floorId, featureId);
                }).fail(onerror);
            }).fail(onerror);
            resolve();
        });
    };
    InnerMapComponent.prototype.loadPlanarGraph = function (planarGraphId, _featureId) {
        var res = window.res;
        var NGR = window.NGR;
        var layers = window.layers;
        var map = window.map;
        var featureId = _featureId;
        res.requestPlanarGraph(planarGraphId).then(function (layerInfo) {
            NGR.IO.fetch({
                url: './assets/map.style.json',
                onsuccess: JSON.parse
            }).then(function (style) {
                window.map.clear();
                window.layers.frame = NGR.featureLayer(layerInfo, {
                    layerType: 'Frame',
                    styleConfig: style
                });
                layers.area = window.NGR.featureLayer(layerInfo, {
                    layerType: 'Area',
                    styleConfig: style
                });
                layers.annotation = NGR.featureLayer(layerInfo.Area, {
                    layerType: 'LogoLabel',
                    styleConfig: style
                });
                layers.facility = NGR.featureLayer(layerInfo.Facility, {
                    layerType: 'Facility',
                    styleConfig: style
                });
                layers.collision = NGR.layerGroup.collision({
                    margin: 3
                });
                layers.collision.addLayer(layers.annotation);
                layers.collision.addLayer(layers.facility);
                map.addLayer(layers.frame);
                map.addLayer(layers.area);
                map.addLayer(layers.collision);
                map.render();
                setTimeout(function () {
                    var feature = window.layers.area._featureLayers[featureId];
                    feature.setStyle({ fillColor: '#ff0000' });
                    window.map.panTo(feature._latlngs[0]);
                    window.map.setZoom(19);
                }, (2000));
            });
        });
    };
    return InnerMapComponent;
}());
export { InnerMapComponent };
