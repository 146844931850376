/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./draw-canvas.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@alyle/ui/button/alyle-ui-button.ngfactory";
import * as i3 from "@alyle/ui/button";
import * as i4 from "@alyle/ui";
import * as i5 from "@alyle/ui/icon";
import * as i6 from "./draw-canvas.component";
var styles_DrawCanvasComponent = [i0.styles];
var RenderType_DrawCanvasComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DrawCanvasComponent, data: {} });
export { RenderType_DrawCanvasComponent as RenderType_DrawCanvasComponent };
export function View_DrawCanvasComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { canvas: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["id", "drawCanvasContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["style", "text-align: left;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "button", [["color", "accent"], ["ly-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearCanvas() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LyButton_0, i2.RenderType_LyButton)), i1.ɵdid(4, 4964352, null, 0, i3.LyButton, [i1.ElementRef, i1.Renderer2, i4.LyTheme2, i1.NgZone, i4.LyRippleService, i4.LyFocusState], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "ly-icon", [], null, null, null, null, null)), i1.ɵdid(6, 737280, null, 0, i5.LyIcon, [i5.LyIconService, i1.ElementRef, i1.Renderer2, i4.LyTheme2], null, null), (_l()(), i1.ɵted(-1, null, ["undo"])), (_l()(), i1.ɵted(-1, 0, ["\u590D\u539F "])), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["canvas", 1]], null, 0, "canvas", [], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "accent"; _ck(_v, 4, 0, currVal_0); _ck(_v, 6, 0); }, null); }
export function View_DrawCanvasComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-draw-canvas", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).cx($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DrawCanvasComponent_0, RenderType_DrawCanvasComponent)), i1.ɵdid(1, 4243456, null, 0, i6.DrawCanvasComponent, [], null, null)], null, null); }
var DrawCanvasComponentNgFactory = i1.ɵccf("app-draw-canvas", i6.DrawCanvasComponent, View_DrawCanvasComponent_Host_0, { width: "width", height: "height" }, {}, []);
export { DrawCanvasComponentNgFactory as DrawCanvasComponentNgFactory };
